import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import {Button} from "@progress/kendo-react-buttons";
import "./style.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";

interface DetailsCellProps<R> extends GridCellProps {
  callback: (p: R) => void
}

export const DetailsCell: <T>(p: DetailsCellProps<T>) => React.ReactElement<DetailsCellProps<T>> = (p) => {

  const {t} = useTranslation();

  return (<td colSpan={p.colSpan} style={p.style} className="kserv-details-cell">
    <Button title={t("common.showMore")}
            onClick={() => p.callback(p.dataItem)}
            themeColor="primary"
            className="k-button kserv-grid-cell-button">
      <FontAwesomeIcon icon={faInfo}/>
    </Button>
  </td>)

};
