import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { machineActions, machineSelectors } from "../../store/machineSlice";
import { AppDispatch } from "../../store";
import "./style.scss";
import {useHistory} from "react-router";
import { Machine } from "../../backend/model";
import { useTranslation } from "react-i18next";
import { MachineGrid } from "./machine-grid";
import {PageTitle} from "../../components";

export const MachinesPage = () => {
  const machines = useSelector(machineSelectors.selectAll());
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
      dispatch(machineActions.fetchAll());
  }, [dispatch, machines.length]);

  const doDetails = (dataItem: Machine) => {
    history.push('/machine/' +  dataItem.id)
  };

  const doDelete = (dataItem: Machine) => {
    dispatch(machineActions.deleteOne(dataItem.id))
      .then((_) => dispatch(machineActions.fetchAll()))
      .catch((_) => {})
  };

  const doEdit = (dataItem: Machine) => {
    dispatch(machineActions.updateOne(dataItem, dataItem.id))
      .finally(() => doRefresh())
  };

  const doRefresh = ()  => {
    dispatch(machineActions.fetchAll());
  };

  return (
    <div>
      <PageTitle title={t("machine.title")} />
      <div className="kserv-content">
        <MachineGrid machines={machines} onDelete={doDelete} onDetails={doDetails} onEdit={doEdit} onRefresh={doRefresh}/>
      </div>
    </div>
  );
};
