import React from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {KservAPI} from "../../backend/summary";
import {useTranslation} from "react-i18next";
import {AppDispatch} from "../../store";
import {useDispatch} from "react-redux";
import {applicationActions} from "../../store/applicationSlice";
import "./style.scss"

interface HelpPopUpProps {
  close: () => void
  APIVersion: KservAPI
}


export const HelpPopUp: React.FunctionComponent<HelpPopUpProps> = (props) => {
  const {t} = useTranslation()
  const dispatch: AppDispatch = useDispatch();

  const downloadUserManual = () => {
    dispatch(applicationActions.getDocUserManual())
  }

  return (
    <Dialog className="kserv-help-message-popup" title={t("help.title")} onClose={props.close}>
      <p style={{margin: "25px", textAlign: "center"}}>
        {t("help.version")} : {process.env.REACT_APP_VERSION}
        <br/>
        {t("help.kservapi")} : {props.APIVersion.kserv_version}
        <br/>
        <button className="kserv-link"
          onClick={downloadUserManual}>
          {t("help.userManual")}
        </button>
      </p>
    </Dialog>
  )
}
