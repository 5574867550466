import { configureStore, getDefaultMiddleware, ThunkAction, Action } from "@reduxjs/toolkit";
import { Backend } from "../backend";

import kRegColumnReducer from "./kRegColumnSlice";
import kDrainColumnReducer from "./kDrainColumnSlice";
import customerReducer from "./customerSlice";
import machineReducer from "./machineSlice";
import machineAdmissionReducer from "./machineAdmissionSlice";
import projectReducer from "./projectSlice";
import kRegProjectDetailsReducer from "./kRegProjectDetailsSlice";
import kDrainProjectDetailsReducer from "./kDrainProjectDetailsSlice";
import loginReducer from "./loginSlice";
import applicationReducer from "./applicationSlice";
import notificationReducer from "./notificationSlice";
import exportReducer from "./exportSlice";
import userReducer from "./userSlice";
import kRegSummaryReducer from "./kRegSummarySlice";
import kDrainSummaryReducer from "./kDrainSummarySlice";
import fabricTypeReducer from "./fabricTypeSlice"

export const createStore = (backend: Backend) => {
  const middleware = getDefaultMiddleware({
    thunk: {
      extraArgument: backend,
    },
    immutableCheck: false,
    serializableCheck: false
  });
  return configureStore({
    reducer: {
      application: applicationReducer,
      kreg_column: kRegColumnReducer,
      kdrain_column: kDrainColumnReducer,
      notification: notificationReducer,
      export: exportReducer,
      customer: customerReducer,
      machine: machineReducer,
      machine_admission: machineAdmissionReducer,
      project: projectReducer,
      kreg_project_details: kRegProjectDetailsReducer,
      kdrain_project_details: kDrainProjectDetailsReducer,
      login: loginReducer,
      kRegSummary: kRegSummaryReducer,
      kDrainSummary: kDrainSummaryReducer,
      user: userReducer,
      fabric_type: fabricTypeReducer
    },
    middleware,
  });
};

type StoreType = ReturnType<typeof createStore>;
export type RootState = ReturnType<StoreType["getState"]>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, Backend, Action<string>>;
export type AppDispatch = StoreType["dispatch"];
