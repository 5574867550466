import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {customerActions, customerSelectors} from "../../../store/customerSlice";
import {ProjectBase, ProjectId, ProjectRoles, ProjectRole, KDrainProject} from "../../../backend/model";
import {AppDispatch} from "../../../store";
import {useTranslation} from "react-i18next";
import {Button} from "@progress/kendo-react-buttons";
import "./style.scss"
import {KDrainProjectSettingsPanel} from "../../../components/project-settings";
import {selectIsUserAdmin, selectLoggedInUser} from "../../../store/loginSlice";
import {userActions, userSelectors} from "../../../store/userSlice";
import {updateInfoNotification} from "../../../store/notificationSlice";

interface ProjectSettingsPageProps {
    id: ProjectId;
}

export const ProjectSettingsPage: React.FunctionComponent<ProjectSettingsPageProps> = ({id}) => {
    const dispatch: AppDispatch = useDispatch();
    const project = useSelector(kDrainProjectDetailsSelectors.selectProject(id));
    const user = useSelector(selectLoggedInUser)
    const users = useSelector(userSelectors.selectAll())
    const customers = useSelector(customerSelectors.selectAll())
    const isAdmin = useSelector(selectIsUserAdmin)

    const {t} = useTranslation();

    const [itemInEdit, setItemInEdit] = React.useState<KDrainProject | undefined>(project);
    const [projectRoles, setProjectRoles] = React.useState<ProjectRoles>()
    const [projectRolesInEdit, setProjectRolesInEdit] = React.useState<ProjectRoles>()

    const doRefresh = React.useCallback(() => {
        dispatch(kDrainProjectDetailsActions.fetchProject(id))
            .then(p => {
                if (p) {
                    setItemInEdit(p)
                    return dispatch(customerActions.fetchById(p.customer_id));
                }
            })
            .catch(() => {
            })
        dispatch(kDrainProjectDetailsActions.getProjectRoles(id))
            .then(roles => {
                setProjectRoles(roles)
                setProjectRolesInEdit(roles)
            })
            .catch(() => {
            })
    }, [dispatch, setProjectRoles, setProjectRolesInEdit, id]);

    useEffect(() => {
        dispatch(customerActions.fetchAll())
        dispatch(userActions.fetchAll())
        doRefresh()
    }, [dispatch, doRefresh, id]);

    const save = async () => {
        if (itemInEdit) {
            try {
                if (projectRolesInEdit) {
                    await dispatch(kDrainProjectDetailsActions.setProjectRoles(itemInEdit.id, projectRolesInEdit))
                }
                dispatch(kDrainProjectDetailsActions.putProject({...itemInEdit}))
                  .then(() => {
                      dispatch(updateInfoNotification("common.saved"))
                  })
            }
            finally {
                doRefresh()
            }
        }
    };

    const doValidate = (itemInEdit ? (itemInEdit.name.trim() === "" || itemInEdit.keller_id.trim() === "" || itemInEdit.location.trim() === "") : false)

    const updateProject = (u: Partial<ProjectBase>) => setItemInEdit((p: any) => ({...p, ...u}));
    const updateProjectRoles = (upp: ProjectRoles) => setProjectRolesInEdit(pp => ({...pp, ...upp}))

    const canUpdateRoles = isAdmin
        || (user && project && (project.user_id === user.id || (projectRoles && projectRoles[user.id] === ProjectRole.admin)))
        || false;

    const content = project !== undefined ? (
        <div className="kserv-tab-content">
            <div>
                <KDrainProjectSettingsPanel project={itemInEdit} updateProject={updateProject} roles={projectRolesInEdit}
                                          updateProjectRoles={updateProjectRoles} canUpdateRoles={canUpdateRoles}
                                          users={users} customers={customers}
                />
                <div className="kserv-project-settings-actions">
                    <Button className="k-button k-primary kserv-button"
                            onClick={save}
                            disabled={doValidate}
                            value={"Save"}>
                        {t("common.save")}
                    </Button>
                </div>
            </div>
        </div>
    ) : (
        <p>{t("common.fetching")}</p>
    );

    return (
        <div className="kserv-content">
            {content}
        </div>
    );
};
