export class ApiError extends Error {
}

export enum ErrorCode {
  InternalError = "serverError",
  BadRequest = "badRequest",
  PermissionExceeded = "permissionLevelExceeded",
  CannotCreate = "cannotCreate",
  NotFound = "notFound",
  CannotModify = "cannotModify",
  CannotDelete = "cannotDelete",
  CannotExport = "cannotExport",
}

export enum ErrorCategory {
  None = "",
  User = "user",
  UserRole = "userRole",
  Customer = "customer",
  Machine = "machine",
  Project = "project",
  Column = "column",
  ColumnLog = "columnLog",
  ColumnReport = "columnReport",
  ProductionReport = "productionReport",
  Drawing = "drawing",
  DrawingHistory = "drawingHistory",
}


export interface ErrorData {
  category: ErrorCategory,
  id: string
}

export interface ErrorBody {
  errorCode: ErrorCode,
  errorData: ErrorData,
  errorDetails: string
}

const GenericLoginError = {
  errorCode: "authenticationError",
  errorData: {category: "", id: ""},
  errorDetails: ""
}

const GenericNotFound = {
  errorCode: "genericNotFound",
  errorData: {category: "", id: ""},
  errorDetails: ""
}

const GenericError = {
  errorCode: "genericError",
  errorData: {category: "", id: ""},
  errorDetails: ""
}

export const checkStatus = async (response: Response) => {
  if (!response.ok) {
    let error
    try {
      error = await response.json()
    } catch (e) {
      switch (response.status) {
        case 403: {
          error = GenericLoginError
          break;
        }
        case 404: {
          error = GenericNotFound
          break;
        }
        default: {
          error = GenericError
          break;
        }
      }
    }

    throw error
  }
  return response
};