import i18n from "i18next";
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import {initReactI18next} from "react-i18next";
import {ApplicationLanguage} from "./components/language/languages";


export const initi18n = (setLanguage: (lang: ApplicationLanguage) => void) => {
  const i18n_debug = (process.env.REACT_APP_I18N_DEBUG) as unknown as  boolean

// Configuration for internationalization.
  i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      backend: {
        loadPath: "./lang/{{lng}}/{{ns}}.json",
        addPath: './lang/{{lng}}/{{ns}}/missing',
      },
      fallbackLng: "en-GB",
      debug: i18n_debug,
      load: "currentOnly",
      interpolation: {
        escapeValue: false,
        skipOnVariables: false
      },
      react: {
        wait: true
      }
    }).then((_) => {
      const langExist = Object.values(ApplicationLanguage).find((lang) => lang === i18n.language)
      const lang = langExist === undefined ? 'en-GB' : i18n.language
      setLanguage(lang as ApplicationLanguage)
      return
    });
}