import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {projectActions, projectSelectors} from "../../store/projectSlice";
import {kRegProjectDetailsActions} from "../../store/kRegProjectDetailsSlice";
import {customerActions, customerSelectors} from "../../store/customerSlice";
import {Button} from "@progress/kendo-react-buttons";
import {AppDispatch} from "../../store";
import {
    KRegProject,
    MachineType,
    missingCustomerId,
    missingProjectId,
    missingUserId,
    ProjectRoles
} from "../../backend/model";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {KRegProjectSettingsPanel} from "../../components/project-settings";
import {userActions, userSelectors} from "../../store/userSlice";

const defaultProjectSettings = {
    "column_top_tolerance": 0.5,
    "lower_density_tolerance": 0.1,
    "upper_density_tolerance": 0.1,
    "transition_length": 1.0,
    "target_lift_coefficient": 25.0,
    "target_rpm": 175.0
}
const emptyFormState: KRegProject = {
    id: missingProjectId,
    customer_id: missingCustomerId,
    user_id: missingUserId,
    name: "",
    keller_id: "",
    location: "",
    active: true,
    machine_type: MachineType.KReg,
    glide: 10,
    dpi_preview: 100,
    dpi_export: 300,
    settings: defaultProjectSettings,
    preferences: {}
};

export const KregProjectNew = () => {
    const history = useHistory();
    const postPending = useSelector(projectSelectors.selectPostRequestPending());
    const users = useSelector(userSelectors.selectAll())
    const customers = useSelector(customerSelectors.selectAll())

    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(customerActions.fetchAll());
        dispatch(userActions.fetchAll());
    }, [dispatch]);

    const [newProject, setNewProject] = useState<KRegProject>(emptyFormState);
    const [projectRoles, setProjectRoles] = React.useState<ProjectRoles>({})
    const updateNewProject = (u: Partial<KRegProject>) => setNewProject(p => ({...p, ...u}));
    const updateProjectRoles = (upp: ProjectRoles) => setProjectRoles(pp => ({...pp, ...upp}))

    const doPost = () => {
        dispatch(projectActions.postKRegProject(newProject))
            .then(id => {
                dispatch(kRegProjectDetailsActions.setProjectRoles(id, projectRoles))
                history.push(`/project/kreg/details/${id}`)
            })
            .catch((_) => {
            });
    };

    const postDisabled = postPending ||
        newProject.customer_id === missingCustomerId ||
        newProject.user_id === missingUserId ||
        newProject.name === "" ||
        newProject.name.trim() === "" ||
        newProject.location === "" ||
        newProject.location.trim() === "" ||
        newProject.keller_id === "" ||
        newProject.keller_id.trim() === "";

    return (
        <div>
            <KRegProjectSettingsPanel project={newProject}
                                      updateProject={updateNewProject}
                                      roles={projectRoles}
                                      updateProjectRoles={updateProjectRoles} canUpdateRoles={true}
                                      users={users}
                                      customers={customers}
            />
            <div className="kserv-new-project-save">
                <Button onClick={() => history.goBack()}>
                    {t("common.cancel")}
                </Button>
                <Button className="k-button k-primary kserv-button"
                        onClick={doPost}
                        themeColor="primary"
                        disabled={postDisabled}
                        value={"Save"}>
                    {t("common.create")}
                </Button>
            </div>
        </div>
    )
};
