import {AppThunk, RootState} from ".";
import {createRequestStateAdapter} from "./util/requestStateAdapter";
import {EditDrainLog, KDrainProject, ProjectId, ProjectRole, ProjectRoles} from "../backend/model";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {createSelector} from "@reduxjs/toolkit";
import {DrawingHistory, DrawingUploadResponse, KDrainDrawing, LogUploadResponse, Obstacles} from "../backend/drawing";
import {
    DrainSpecWithStatus,
    KDrainProjectMapData,
    ProjectColumnStatusUpdate,
    ProjectStatusUpdate
} from "../backend/projectStatus";
import {
    ColumnUpdate,
    DrainHistory,
    DrainReportSummary
} from "../backend/column_log";
import {updateErrorNotification} from "./notificationSlice";
import {BatchJob} from "../backend/batch_job";

const requestStateAdapter = createRequestStateAdapter();
const RTree = require("rtree");

const mapDefaultScales = {
    scale: 90,
    overview: 10,
    workview: 90
}

type State = {
    projects: { [id: number]: KDrainProject | undefined };
    column_log: { [id: number]: DrainReportSummary[] };
    column_updates: ColumnUpdate[],
    status: ProjectColumnStatusUpdate | undefined,
    drawing: KDrainDrawing | undefined,
    obstacles: Obstacles | undefined,
    column_history: DrainHistory | undefined,
    scale: number,
    drainDiameter: number,
    x: number,
    y: number,
    reprocessLogsBatchStatus: { [id: number]: BatchJob[] },
    postResult?: ProjectId;
} & typeof requestStateAdapter.initialState;

const initialState: State = {
    ...requestStateAdapter.initialState,
    // ids: [],
    projects: {},
    column_log: {},
    column_updates: [],
    status: undefined,
    drawing: undefined,
    obstacles: undefined,
    column_history: undefined,
    scale: mapDefaultScales.workview,
    drainDiameter: 0,
    x: 0,
    y: 0,
    reprocessLogsBatchStatus: {},
};

const slice =
    createSlice({
        name: "kdrain_project_details",
        initialState,
        reducers: {
            setPending: requestStateAdapter.setPending,
            setError: requestStateAdapter.setError,
            setProject: (state, action: PayloadAction<KDrainProject>) => {
                state.projects[action.payload.id] = action.payload
            },
            addAggregate: (state, action: PayloadAction<{ id: ProjectId; col: DrainReportSummary[] }>) => {
                const id = action.payload.id;
                // if (!state.ids.includes(id)) state.ids.push(id);
                state.column_log[id] = action.payload.col as Draft<DrainReportSummary[]>;
            },
            setObstacles: (state, action: PayloadAction<Obstacles>) => {
                state.obstacles = action.payload
            },
            setDrawing: (state, action: PayloadAction<KDrainDrawing>) => {
                const drawing = action.payload;
                state.drawing = drawing
                if (drawing.columns.length > 0) {
                    const [x, y] = drawing.columns[0].location
                    state.x = x
                    state.y = y
                }
            },
            addUpdate: (state, action: PayloadAction<ProjectColumnStatusUpdate>) => {
                const update = action.payload
                if (state.status?.project_id !== update.project_id) {
                    state.status = update
                } else {
                    state.status.latest_column_state_change = update.latest_column_state_change
                    for (const column in update.updates) {
                        if (state.status.updates[column] === undefined) {
                            state.status.updates[column] = [...update.updates[column]]
                            continue;
                        }
                        update.updates[column].forEach(u => {
                            if (!state.status) return; //To silence TS compiler
                            if (!state.status.updates[column].find(cu => cu.hash === u.hash)) {
                                state.status.updates[column].push(u)
                            }
                        })
                        state.status.updates[column].sort(
                            (a, b) => (b.timestamp < a.timestamp) ? -1 : 1
                        )
                    }
                    for (const column in update.removals) {
                        if (state.status.updates[column] === undefined) continue;
                        update.removals[column].forEach(h => {
                            if (!state.status) return; //To silence TS compiler
                            state.status.updates[column] =
                                state.status.updates[column].filter(cu => cu.hash !== h)
                        })
                    }
                }
            },
            resetProjectUpdate: (state, action: PayloadAction<ProjectId>) => {
                const id = action.payload;
                if (state.status?.project_id !== id) {
                    state.status = undefined
                    state.x = 0
                    state.y = 0
                }
                if (state.drawing?.project_id !== id) {
                    state.drawing = undefined
                }
                if (state.obstacles?.project_id !== id) {
                    state.obstacles = undefined
                }
            },
            setColumnHistory: (state, action: PayloadAction<DrainHistory>) => {
                state.column_history = action.payload
            },
            updateMapPos: (state, action: PayloadAction<{ x: number, y: number }>) => {
                state.x = action.payload.x;
                state.y = action.payload.y;
            },

            setPostResult: (state, action: PayloadAction<ProjectId>) => {
                state.postResult = action.payload;
            },
            updateMapScale: (state, action: PayloadAction<number>) => {
                state.scale = action.payload;
            },
            setDrainDiameter: (state, action: PayloadAction<number>) => {
                state.drainDiameter = action.payload
            },
            updateColumnUpdate: (state, action: PayloadAction<ColumnUpdate[]>) => {
                state.column_updates = action.payload;
            },
            updateReprocessLogBatchStatus: (state, action: PayloadAction<{ id: ProjectId, status: BatchJob[] }>) => {
                const id = action.payload.id;
                // if (!state.ids.includes(id)) state.ids.push(id);
                state.reprocessLogsBatchStatus[id] = action.payload.status as Draft<BatchJob[]>;
            }
        },
    });

const {
    setProject,
    addUpdate,
    setDrawing,
    setObstacles,
    addAggregate,
    setPending,
    updateMapScale,
    setDrainDiameter,
    resetProjectUpdate,
    setColumnHistory,
    updateMapPos,
    updateColumnUpdate,
    updateReprocessLogBatchStatus
} = slice.actions;

const fetchProject = (id: ProjectId): AppThunk<Promise<KDrainProject>> => (dispatch, _, {api}) => {
    const getPromise = api.kdrain_project_details.getProject(id);
    getPromise
        .then(p => dispatch(setProject(p)))
        .catch(err => dispatch(updateErrorNotification(err)))
    return getPromise;
}

const putProject = (project: KDrainProject): AppThunk<Promise<ProjectId>> => (dispatch, _, {api}) => {
    const getPromise = api.kdrain_project_details.putProject(project);
    getPromise
        .then(_ => dispatch(setProject(project)))
        .catch(err => dispatch(updateErrorNotification(err)))
    return getPromise;
}

const postDrawing = (id: ProjectId, filename: string, file: any, ignoreDrilledColumns: boolean): AppThunk<Promise<DrawingUploadResponse>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    let formData = new FormData();
    formData.append('file', file);
    const query = `?ignore_drilled_columns=${ignoreDrilledColumns}`
    const postPromise = api.kdrain_project_details.postDrawing(id, filename, formData, query);
    postPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return postPromise;
};

const postDrawingObstacles = (id: ProjectId, file: any): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    let formData = new FormData();
    formData.append('file', file);

    const postPromise = api.kdrain_project_details.postObstacles(id, formData);
    postPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return postPromise;
};

const resetProjectStatus = (id: ProjectId): AppThunk => (dispatch, getState, _) => {
    dispatch(resetProjectUpdate(id))
}

const getUpdate = (id: ProjectId, timestamp ?: string): AppThunk<Promise<ProjectStatusUpdate>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const query = timestamp ? `?updateRef=${timestamp}` : "";
    const getPromise = api.kdrain_project_details.getUpdate(id, query);
    getPromise
        .then(async update => {
            const state = getState()
            if (update.latest_drawing_change !== state.kdrain_project_details.drawing?.latest_update) {
                const drawing = await api.kdrain_project_details.getDrawing(id)
                dispatch(setDrawing(drawing))
            }
            if (update.latest_obstacles_change !== state.kdrain_project_details.obstacles?.latest_update) {
                const obstacles = await api.kdrain_project_details.getObstacles(id)
                dispatch(setObstacles(obstacles))
            }
            dispatch(addUpdate(update.column_status_update))
        })
        .catch(err => console.error("getUpdated threw: ", err))
        // .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
};

const getAggregate = (id: ProjectId): AppThunk<Promise<DrainReportSummary[]>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const getPromise = api.kdrain_project_details.getAggregate(id);
    getPromise
        .then(entities => {
            return dispatch(addAggregate({id: id, col: entities}))
        })
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
};

const getColumnUpdates = (id: ProjectId): AppThunk<Promise<ColumnUpdate[]>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const getPromise = api.kdrain_project_details.getColumnUpdate(id);
    getPromise
        .then(entities => {
            return dispatch(updateColumnUpdate(entities))
        })
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
};

const getDrawingHistory = (id: ProjectId): AppThunk<Promise<DrawingHistory>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const getPromise = api.kdrain_project_details.getDrawingHistory(id);
    getPromise
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
}

const getDrainHistory = (id: ProjectId, column_name: string): AppThunk<Promise<DrainHistory>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const query = `?drain_spec_name=${column_name}`
    const getPromise = api.kdrain_project_details.getDrainHistory(id, query);
    getPromise
        .then((entry) => dispatch(setColumnHistory(entry)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
}

const setMapScale = (scale: number): AppThunk => (dispatch, getState, _) => {
    dispatch(updateMapScale(scale));
    return
}

const applyMapStandardScale = (): AppThunk => (dispatch, getState, _) => {
    dispatch(updateMapScale(mapDefaultScales.workview));
    return
}

const applyMapOverviewScale = (): AppThunk => (dispatch, getState, _) => {
    dispatch(updateMapScale(mapDefaultScales.overview));
    return
}

const setMapPos = (pos: { x: number, y: number }): AppThunk => (dispatch, getState, _) => {
    dispatch(updateMapPos(pos));
    return
}

const uploadLog = (id: ProjectId, filename: string, file: any): AppThunk<Promise<LogUploadResponse>> => (dispatch, getState, {api}) => {
    const hash = filename.split(".")[0]
    const postPromise = api.kdrain_project_details.postLog(id, hash, file);
    postPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return postPromise;
}

const editLog = (editLog: EditDrainLog): AppThunk<Promise<void>> => (dispatch, _, {api}) => {
    const postPromise = api.kdrain_project_details.editLog(editLog);
    postPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
    return postPromise;
}

const startReprocessLogs = (id: ProjectId): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    const startPromise = api.kdrain_project_details.startLogReprocess(id);
    startPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return startPromise;
}

const getReprocessLogs = (id: ProjectId): AppThunk<Promise<BatchJob[]>> => (dispatch, getState, {api}) => {
    const getPromise = api.kdrain_project_details.getLogReprocess(id);
    getPromise
        .then((status) => dispatch(updateReprocessLogBatchStatus({id: id, status: status})))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return getPromise;
}

const stopReprocessLogs = (id: ProjectId, pid: number): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    const stopPromise = api.kdrain_project_details.deleteLogReprocess(id, pid);
    stopPromise
        .then()
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return stopPromise;
}

const getProjectRoles = (id: ProjectId): AppThunk<Promise<ProjectRoles>> => (dispatch, _, {api}) => {
    const getPromise = api.kdrain_project_details.getRoles(id);
    getPromise
        .catch(err => dispatch(updateErrorNotification(err)))
    return getPromise;
}

const setProjectRoles = (id: ProjectId, roles: ProjectRoles): AppThunk<Promise<void>> => (dispatch, _, {api}) => {
    const setPromise = api.kdrain_project_details.postRoles(id, roles);
    setPromise
        .catch(err => dispatch(updateErrorNotification(err)))
    return setPromise;
}

const getMyProjectRole = (id: ProjectId): AppThunk<Promise<ProjectRole>> => (dispatch, _, {api}) => {
    const getPromise = api.kdrain_project_details.getMyRole(id);
    getPromise
        .catch(err => dispatch(updateErrorNotification(err)))
    return getPromise;
}

export const kDrainProjectDetailsActions = {
    fetchProject,
    putProject,
    postDrawing,
    postDrawingObstacles,
    getUpdate,
    getAggregate,
    getColumnUpdates,
    getDrawingHistory,
    getDrainHistory,
    setMapScale,
    setMapPos,
    applyMapOverviewScale,
    applyMapStandardScale,
    resetProjectStatus,
    uploadLog,
    editLog,
    startReprocessLogs,
    getReprocessLogs,
    stopReprocessLogs,
    getProjectRoles,
    setProjectRoles,
    getMyProjectRole,
    setDrainDiameter
}


const selectDrawing = (state: RootState) => state.kdrain_project_details.drawing;
const selectObstacles = (state: RootState) => state.kdrain_project_details.obstacles;
const selectColumnStatus = (state: RootState) => state.kdrain_project_details.status;
const selectMapData = createSelector(
    selectDrawing,
    selectObstacles,
    selectColumnStatus,
    (drawing, obstacles, status) => {
        const map: KDrainProjectMapData = {
            offset: {
                x: 0,
                y: 0
            },
            columns: [],
            obstacles: obstacles || {
                project_id: 0 as ProjectId,
                latest_update: "",
                lines: [],
                circles: []
            },
            rtree: RTree(10) //TODO: Use it?
        }
        if (drawing) {
            map.columns = drawing.columns.map(c => ({
                ...c,
                status: status?.updates[c.name] ?
                    status.updates[c.name].length > 0 ?
                        status.updates[c.name][0].state : "unprocessed" : "unprocessed",
            }) as DrainSpecWithStatus)
            if (map.columns.length > 0) {
                [map.offset.x, map.offset.y] = map.columns[0].location
            }
        }
        return map
    }
);

export const kDrainProjectDetailsSelectors = {
    selectProject: (id: ProjectId) => (state: RootState) => state.kdrain_project_details.projects[id],
    selectDrawing,
    selectColumnReportSummaryById: (id: ProjectId) => (state: RootState) => state.kdrain_project_details.column_log[id],
    selectColumnStatus,
    selectColumnHistory: () => (state: RootState) => state.kdrain_project_details.column_history,
    selectColumnUpdates: () => (state: RootState) => state.kdrain_project_details.column_updates,
    selectColumnStatusRef: () => (state: RootState) => state.kdrain_project_details.status?.latest_column_state_change,
    selectPostResult: () => (state: RootState) => state.kdrain_project_details.postResult,
    selectByIdRequestPending: (id: ProjectId) => (state: RootState) => state.kdrain_project_details.pending[id],
    selectByIdRequestError: (id: ProjectId) => (state: RootState) => state.kdrain_project_details.error[id],
    selectMapX: () => (state: RootState) => state.kdrain_project_details.x,
    selectMapY: () => (state: RootState) => state.kdrain_project_details.y,
    selectMapScale: () => (state: RootState) => state.kdrain_project_details.scale,
    selectReprocessLogBatchStatus: (id: ProjectId) => (state: RootState) => state.kdrain_project_details.reprocessLogsBatchStatus[id] ? state.kdrain_project_details.reprocessLogsBatchStatus[id] : [],
    selectMapData,
    selectDrainDiameter: () => (state: RootState) => state.kdrain_project_details.drainDiameter
}

export default slice.reducer;
