import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppThunk, RootState} from ".";
import {User, UserRoles} from "../backend/model";
import {updateErrorNotification} from "./notificationSlice";

interface LoginState {
    user: User | undefined;
    isLoggedIn: boolean;
    isPending: boolean;
}

const initialState: LoginState = {user: undefined, isLoggedIn: false, isPending: false};

const loginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        login: (state, action: PayloadAction<User>) => {
            state.isLoggedIn = true;
            state.user = action.payload;
        },
        logout: state => {
            state.user = undefined;
            state.isLoggedIn = false;
        },
        setPending: (state, action: PayloadAction<boolean>) => {
            state.isPending = action.payload;
        }
    },
});

const {login, logout, setPending} = loginSlice.actions;

export const tryLogin = (): AppThunk<Promise<void>> => async (dispatch, _, {authenticator}) => {
    dispatch(setPending(true));
    await authenticator.login()
        .catch(() => {})
        .finally(() => dispatch(setPending(false)));
};

export const doLogout = (): AppThunk => (dispatch, _, {authenticator}) => {
    authenticator.logout();
    dispatch(logout())
};

export const checkLogin = (): AppThunk<Promise<void>> =>
    (dispatch, _, {api, authenticator}) => {
    return authenticator.silentLogin().then(userName => {
        if (userName) {
            return api.login.getUser()
                .then(user => {dispatch(login(user))})
                .catch(err => dispatch(updateErrorNotification(err)))
        }
    })
};

export const selectIsLoggedIn = (state: RootState) => state.login.isLoggedIn;
export const selectIsUserAdmin = (state: RootState) => {
    if (state.login.isLoggedIn) {
        if (state.login.user && state.login.user.role === UserRoles.admin) {
            return true
        }
    }
    return false
}
export const selectIsUserGuest = (state: RootState) => {
    if (state.login.isLoggedIn) {
        if (state.login.user && state.login.user.role === UserRoles.guest) {
            return true
        }
    }
    return false
}
export const selectUserTotalDashboardAccess = (state: RootState) => {
    if (state.login.isLoggedIn) {
        if (state.login.user && state.login.user.total_dashboard) {
            return true
        }
    }
    return false
}
export const selectLoggedInUser = (state: RootState) => state.login.user;
export const selectIsLogInPending = (state: RootState) => state.login.isPending;

export default loginSlice.reducer;
