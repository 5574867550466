import React from "react";
import {GridFilterCellProps} from "@progress/kendo-react-grid";
import {DropDownList, DropDownListChangeEvent} from "@progress/kendo-react-dropdowns";
import {useTranslation} from "react-i18next";

interface DropDownFilterCellProps extends GridFilterCellProps {
  text?: string[]
  data: string[]
  defaultItem: string
  defaultItemText?: string
}

export const DropdownFilterCell: (p: DropDownFilterCellProps) => React.ReactElement<DropDownFilterCellProps> = (p) => {

  const {t} = useTranslation()

  const hasValue = (value: string): boolean => {
    return Boolean(value && value !== p.defaultItem)
  }

  const isDisabled = !(p.value || p.value === p.defaultItem)

  const onClearButtonClick = (event: any) => {
    event.preventDefault()
    p.onChange({
      value: '',
      operator: '',
      syntheticEvent: event
    })
  }

  const onChange = (event: DropDownListChangeEvent) => {
    const newValue = hasValue(event.target.value.data)
    p.onChange({
      value: newValue ? event.target.value.data : '',
      operator: newValue ? 'eq' : '',
      syntheticEvent: event.syntheticEvent
    })
  }

  const data = p.data.map((e, i)=> {return {data: e, text: p.text ? t(p.text[i]) : e}})
  const defaultItem = {data: p.defaultItem, text: p.defaultItemText ? t(p.defaultItemText) : p.defaultItem}

  const value = data.find(e => e.data === p.value) || defaultItem


  return (
    <div>
      <div className="k-filtercell">
        <DropDownList
          data={data}
          dataItemKey="data"
          textField="text"
          onChange={onChange}
          value={value}
          defaultItem={defaultItem}
        />
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title={t("common.clear")}
          disabled={isDisabled}
          onClick={onClearButtonClick}
        >
          <span className="k-icon k-i-filter-clear"/>
        </button>
      </div>
    </div>
  )
};
