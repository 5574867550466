import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSyncAlt} from "@fortawesome/free-solid-svg-icons";
import "./style.scss"

interface RefreshProps {
  callback: () => void
}

export const RefreshButton: React.FunctionComponent<RefreshProps> = (p) => {

  const {t} = useTranslation()

  return (
    <Button
      title={t("common.refresh")}
      className="k-button kserv-button"
      onClick={p.callback}>
      <FontAwesomeIcon icon={faSyncAlt}/>
    </Button>
  )
}

