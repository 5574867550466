import React, {PropsWithChildren} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {DeleteDialog} from "../delete-dialog";
import "./style.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrashAlt, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@progress/kendo-react-buttons";

interface EditDialogProps {
  save: () => void,
  delete: () => void,
  cancel: () => void
  validate: () => boolean
}

export const EditDialog = (props: PropsWithChildren<EditDialogProps>) => {

  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  const onDelete = () => {
    setShowDeleteDialog(false);
    props.delete()
  };

  const cancelDelete = () => {
    setShowDeleteDialog(false);
  };

  const showDeletePrompt = () => {
    setShowDeleteDialog(true);
  };

  return (
    <>
      <Dialog onClose={props.cancel}>
        <form onSubmit={handleSubmit}>
          <div className="kserv-dialog-children">
            {props.children}
          </div>
        </form>
        <DialogActionsBar>
          <Button onClick={props.cancel}>
            <FontAwesomeIcon icon={faWindowClose}/>
          </Button>
          <Button onClick={() => showDeletePrompt()}>
            <FontAwesomeIcon icon={faTrashAlt}/>
          </Button>
          <Button disabled={props.validate()}
                  onClick={props.save}>
            <FontAwesomeIcon icon={faSave}/>
          </Button>
        </DialogActionsBar>
      </Dialog>
      {showDeleteDialog && <DeleteDialog onDelete={onDelete} cancelDelete={cancelDelete}/>}
    </>
  );
};