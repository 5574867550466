import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./style.scss"
import {ProjectId} from "../../../backend/model";
import {AppDispatch} from "../../../store";

import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {ColumnLogGrid} from "./column-log-grid";
import {exportActions, ExportFileInterface} from "../../../store/exportSlice";
import {BatchJobGrid} from "./batch-job-grid";

interface ColumnLogProps {
    id: ProjectId;
    selectSingleColumnPage: (title: string) => void
}

export const ProjectColumnLogPage: React.FunctionComponent<ColumnLogProps> = ({id, selectSingleColumnPage}) => {
    const dispatch: AppDispatch = useDispatch();

    const project = useSelector(kRegProjectDetailsSelectors.selectProject(id))
    const columnReportSummary = useSelector(kRegProjectDetailsSelectors.selectColumnReportSummaryById(id));

    useEffect(() => {
        dispatch(kRegProjectDetailsActions.getAggregate(id))
            .catch(() => {
            })
        dispatch(exportActions.getColReportBatchStatus(id))
            .catch(() => {
            })
    }, [dispatch, id]);

    const doRefresh = () => {
        dispatch(kRegProjectDetailsActions.getAggregate(id))
            .catch(() => {
            })
    }

    const doColDownload = (id: ProjectId, options: ExportFileInterface) => {
        dispatch(exportActions.downloadColReport(id, options))
            .catch(() => {
            })
    }

    const doBatchColDownload = (id: ProjectId, options: ExportFileInterface) => {
        dispatch(exportActions.startColReportBatch(id, options))
            .then(() => {
                dispatch(exportActions.getColReportBatchStatus(id))
                    .catch(() => {
                    })
            })
            .catch(() => {
            })
    }

    const doProjDownload = (id: ProjectId, options: ExportFileInterface) => {
        dispatch(exportActions.downloadKRegProjReport(id, options))
            .catch(() => {
            })
    }

    return (
        <div>
            <div className="kserv-content">
                {project === undefined ? "Loading..." :
                    <div className="kserv-project-column-log-page">
                        <ColumnLogGrid project={project} data={columnReportSummary}
                                       onColBatchDownload={doBatchColDownload}
                                       onColDownload={doColDownload} onProjDownload={doProjDownload}
                                       onRefresh={doRefresh}
                                       selectSingleColumnPage={selectSingleColumnPage}/>
                        <BatchJobGrid id={id}/>
                    </div>}
            </div>
        </div>
    );
};
