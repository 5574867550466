import React from 'react';
import { AppRouter } from './router';

export const App = () => {
  return (
    <div className="kserv-app">
      <AppRouter />
    </div>
  );
};
