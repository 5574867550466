import {AppThunk, RootState} from ".";
import {createRequestStateAdapter, fetchAllRequestId} from "./util/requestStateAdapter";
import {missingProjectId, ProjectId} from "../backend/model";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {API, EndpointKRegExport} from "../backend/api";
import {FilterDescriptor} from "@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface";
import {CompositeFilterDescriptor, SortDescriptor} from "@progress/kendo-data-query";
import {saveAs} from 'file-saver';
import {ErrorCategory, ErrorCode} from "../backend/error";
import {ColumnFilter} from "../backend/column_log";
import {updateErrorNotification} from "./notificationSlice";
import {BatchJob} from "../backend/batch_job";


export enum ExportFileType {
  XLSX = 'xlsx',
  PDF = 'pdf'
}

export interface ExportFileInterface {
  col_id?: string,
  extension?: ExportFileType
  filter?: (FilterDescriptor | CompositeFilterDescriptor)[]
  sort?: (SortDescriptor)[]
  dateRange?: (FilterDescriptor | CompositeFilterDescriptor)[]
  categories?: string[]
  graphs?: string[]
  export?: boolean
  store?: boolean
  preview?: boolean
  dashboard?: boolean,
  comment?: string
}

const mimeExtTofileExt = [
  {mime: "application/pdf", ext: "pdf"},
  {mime: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", ext: "xlsx"},
   {mime: "application/vnd.ms-excel", ext: "xlsx"},
  {mime: "application/zip", ext: "zip"}
  ]

const requestStateAdapter = createRequestStateAdapter();

type State = {
  id: ProjectId;
  cache: {col_name: string, pdf: string}[];
  colBatchStatus: BatchJob[],
  postResult?: ProjectId;
} & typeof requestStateAdapter.initialState;


const initialState: State = {
  id: missingProjectId,
  cache: [],
  colBatchStatus: [],
  ...requestStateAdapter.initialState,
};

const slice =
  createSlice({
    name: "export",
    initialState,
    reducers: {
      setPdfCaches: (state, action: PayloadAction<{id: ProjectId; col_name: string, pdf: string}>) => {
        if(state.id !== action.payload.id) {
          state.id = action.payload.id
          state.cache = []
        }

        const tempCache = [...state.cache]

        const index = tempCache.findIndex((e) => e.col_name === action.payload.col_name)
        if (index >= 0) {
          tempCache[index] = {col_name: action.payload.col_name, pdf: action.payload.pdf}
          state.cache = [...tempCache]
        } else {
          state.cache = [...state.cache, {col_name: action.payload.col_name, pdf: action.payload.pdf}]
        }

        if(state.cache.length > 10) {
          state.cache = [...state.cache.slice(1)]
        }

      },
      updateBatchStatus: (state, action: PayloadAction<{id: ProjectId, status: BatchJob[] }>) => {
        if(state.id !== action.payload.id) {
          state.id = action.payload.id
          state.colBatchStatus = []
        }
        if (action.payload.status && action.payload.status.length > 0) {
          state.colBatchStatus = [...action.payload.status]
        } else {
          state.colBatchStatus = []
        }
      },
      clearBatchStatus: (state, action: PayloadAction) => {
        state.colBatchStatus = []
      },
      setPending: requestStateAdapter.setPending,
      setError: requestStateAdapter.setError,
      setPostResult: (state, action: PayloadAction<ProjectId>) => {
        state.postResult = action.payload;
      },
    },
  });


const getActions = (
  endpointSelector: (api: API) => EndpointKRegExport
) => {
  const { setPending, setPdfCaches, updateBatchStatus } = slice.actions;


  function createQuery(options: ExportFileInterface):string {

    if(!options) {
      return ""
    }

    const query = []

    if (options.col_id) {
      query.push("id=" + options.col_id)
    }

    if (options.preview) {
      query.push("preview=" + options.preview)
    }

    if (options.extension) {
      query.push("ext=" + options.extension)
    }

    if (options.dashboard) {
      query.push("dashboard=" + options.dashboard)
    }

    if (options.dateRange) {
      if (options.dateRange.length) {
        for (const index in options.dateRange) {
          const filter = options.dateRange[index] as FilterDescriptor;
          query.push(filter.value ? "filter_op=" + filter.operator + "&filter_cat=" + filter.field + "&filter_value=" + filter.value : "")
        }
      }
    }

    if (options.categories && options.categories.length > 0) {
      query.push("category=" + options.categories.reduce((tot, next) => (tot + "&category=" + next)))
    }

    if (options.graphs && options.graphs.length > 0) {
      query.push("graphs=" + options.graphs.reduce((tot, next) => (tot + "&graphs=" + next)))
    }

    if (options.filter && options.filter.length) {
      for (const index in options.filter) {
        const filter = options.filter[index] as FilterDescriptor;
        query.push(filter.value ? "filter_op=" + filter.operator + "&filter_cat=" + filter.field + "&filter_value=" + filter.value : "")
      }
    }

    if (options.sort && options.sort.length) {
      for (const index in options.sort) {
        const sort = options.sort[index] as SortDescriptor;
        query.push(sort ? "sort_cat=" + sort.field + "&sort_dir=" + sort.dir : "")
      }
    }

    if (options.comment && options.comment.trim() !== "") {
      query.push("&comment=" + options.comment)
    }

    // Check if we added any valid params
    if (!query.length) return ""
    return '?' + query.reduce((tot, next) => (tot + '&' + next))
  }

  function getColumnReportFileName(options: ExportFileInterface, mime_ext: string):string {
    const ext = mimeExtTofileExt.find((e) => e.mime === mime_ext)

    let error = {
      errorCode: ErrorCode.CannotExport,
      errorData: {category: ErrorCategory.ColumnReport, id: -1},
      errorDetails: ""
    }

    if (!ext) {
      error.errorDetails =`Unsupported filetype ${mime_ext}`
     throw  error
    }
    if (options.col_id) {
      return options.col_id.replace('\\', '_').replace('/', '_').replace('.', '_') + "_DSMReport." + ext.ext;
    } else {
      error.errorDetails = `Missing file extension`
      throw error
    }
  }

  const downloadKRegProjReport = (id: ProjectId, options:ExportFileInterface): AppThunk<Promise<Blob>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const query = createQuery(options)
    const fileName = "DSMProductionReport." + ExportFileType.XLSX
    const downloadPromise = endpointSelector(api).exportKRegProductionReport(id, query);
    downloadPromise
      .then((blob) => saveAs(blob, fileName))
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };

  const downloadKDrainProjReport = (id: ProjectId, options:ExportFileInterface): AppThunk<Promise<Blob>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const query = createQuery(options)
    const fileName = "DrainProductionReport." + ExportFileType.XLSX
    const downloadPromise = endpointSelector(api).exportKDrainProductionReport(id, query);
    downloadPromise
        .then((blob) => saveAs(blob, fileName))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };

  const downloadColReport = (id: ProjectId, options:ExportFileInterface, payload?: ColumnFilter): AppThunk<Promise<Blob>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));

    if (payload === undefined){
      payload = {} as ColumnFilter
    }

    const query = createQuery(options)
    const downloadPromise = endpointSelector(api).exportColReport(id, query, payload);
    downloadPromise
      .then((blob) => {
        if (options.store) {
          if(blob.type === "application/pdf") {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function() {
              const base64data = reader.result ? reader.result.toString() : "";
              dispatch(setPdfCaches({id: id, col_name: options.col_id ? options.col_id :  "", pdf: base64data}))
            }
          }
        }
        if (options.export) {
          saveAs(blob, getColumnReportFileName(options, blob.type))
        }
      })
      .catch(err => {
        dispatch(setPdfCaches({id: id, col_name: options.col_id ? options.col_id :  "", pdf: ""}))
        dispatch(updateErrorNotification(err))
      })
      .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };

  const startColReportBatch = (id: ProjectId, options:ExportFileInterface): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const query = createQuery(options)

    const payload = {} as ColumnFilter

    const downloadPromise = endpointSelector(api).startBatchReport(id, query, payload);
    downloadPromise
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };

  const getBatchReportUrl = (id: ProjectId, pid: number) : AppThunk<Promise<string>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));

    const stringPromise = endpointSelector(api).getBatchReportUrl(id, pid);
    stringPromise
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return stringPromise;
  };

  const stopColReportBatch = (id: ProjectId, pid: number) : AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));

    const downloadPromise = endpointSelector(api).stopBatchReport(id, pid);
    downloadPromise
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };

  const getColReportBatchStatus = (id: ProjectId) : AppThunk<Promise<BatchJob[]>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));

    const downloadPromise = endpointSelector(api).getBatchReportStatus(id);
    downloadPromise
      .then(status => dispatch(updateBatchStatus({id: id, status: status})))
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return downloadPromise;
  };


  return {
    downloadKRegProjReport,
    downloadKDrainProjReport,
    downloadColReport,
    startColReportBatch,
    getBatchReportUrl,
    getColReportBatchStatus,
    stopColReportBatch
  };
};

const getSelectors = () => {
  return {
    selectPostResult: () => (state: RootState) => state.export.postResult,
    selectByIdRequestPending: (id: ProjectId) => (state: RootState) => state.export.pending[id],
    selectByIdRequestError: (id: ProjectId) => (state: RootState) => state.export.error[id],
    selectAllRequestPending: () => (state: RootState)  => state.export.pending[fetchAllRequestId],
    selectAllRequestError: () => (state: RootState) => state.export.error[fetchAllRequestId],
    selectCachePDF: (name: string) => (state: RootState) => {
      const pdf = state.export.cache.find((e) => e.col_name === name)
      return pdf ? pdf : null
    },
    selectProjectColBatchJob: (id: ProjectId) => (state:RootState) => {
      if (state.export.id !== id){
        return []
      }
      return state.export.colBatchStatus
    }
  }
}

export const exportActions = getActions(api => api.export);
export const exportSelectors = getSelectors();
export default slice.reducer;
