import { PayloadAction } from "@reduxjs/toolkit";

export const fetchAllRequestId = -1;
export const postRequestId = -2;

export const createRequestStateAdapter = () => {

  interface RequestState {
    pending: { [k: number]: boolean | undefined };
    error: { [k: number]: string | undefined };
  }

  const initialState: RequestState = { pending: {}, error: {} };

  const setPending = {
    reducer<S extends RequestState>(state: S,  action: PayloadAction<{ id: number; pending: boolean }>) {
      const { id, pending } = action.payload;
      state.pending[id] = pending;
      if (pending) delete state.error[id];
    },
    prepare(id: number, pending: boolean) {
      return { payload: { id, pending } };
    },
  };

  const parseError = (err: any): string => {
    return err ? err.toString() : "Unknown Error";
  };

  const setError = {
    reducer<S extends RequestState>(state: S, action: PayloadAction<{ id: number; error: string }>) {
      const { id, error } = action.payload;
      state.error[id] = error;
    },
    prepare(id: number, err: any) {
      return { payload: { id, error: parseError(err) } };
    },
  };

  const getSelectors = <S>(stateSelector: (state: S) => RequestState) => ({
    selectIsPending: (id: number) => (state: S) => stateSelector(state).pending[id],
    selectError: (id: number) => (state: S) => stateSelector(state).error[id],
  });

  return { initialState, setPending, setError, getSelectors };
};
