import React from "react";
import "./style.scss"
import {missingUserId, ProjectId} from "../../../backend/model";
import {DropDownList} from '@progress/kendo-react-dropdowns';
import {Button} from "@progress/kendo-react-buttons";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridItemChangeEvent,
  GridPageChangeEvent,
  GridRowClickEvent,
  GridSortChangeEvent
} from "@progress/kendo-react-grid";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {Switch, TextArea, TextAreaChangeEvent} from "@progress/kendo-react-inputs";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {KservGraph} from "./graph";
import {useTranslation} from "react-i18next";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {kDrainColumnActions, kDrainColumnSelectors} from "../../../store/kDrainColumnSlice";
import {ColumnFilter, ColumnLogEntry, KDrainColumnLog} from "../../../backend/column_log";
import moment from "moment";
import {selectLoggedInUser} from "../../../store/loginSlice";
import {DropdownFilterCell, LoadingPanel} from "../../../components";
import {updateInfoNotification} from "../../../store/notificationSlice";

interface EditColumnGraphProps {
  id: ProjectId;
  column: string
}

export interface GraphDataFilterI {
  pos: number,
  time: number,
  signal: number,
  valueOriginal: number,
}

export interface GraphCategoryEntry {
  signal: number,
  value: string,
  name: string,
  selected: boolean
}

interface EditGraphHeaderProps {
  showOriginalGraph: boolean
  setShowOriginalGraph: (b: boolean) => void
  logEntries: { name: string, hash: string }[]
  selectedLog: string
  setSelectedLog: (s: string) => void
  graphCategories: GraphCategoryEntry[]
  setGraphCategories: (graphs: GraphCategoryEntry[]) => void
}

const Header: React.FunctionComponent<EditGraphHeaderProps> = (p) => {

  const {t} = useTranslation()

  const showOriginalGraphSwitch = (
    <div>
      <span>{t("project.editColumn.hideOriginal")}</span>
      <Switch className="kserv-edit-graph-switch"
              checked={!p.showOriginalGraph}
              onLabel="On"
              offLabel="Off"
              onChange={e => p.setShowOriginalGraph(!e.target.value)}
      />
    </div>
  )

  const selectLogList = (
    <DropDownList className="kserv-edit-graph-dropdown"
                  data={p.logEntries}
                  popupSettings={{width: "240px"}}
                  textField="name"
                  dataItemKey="hash"
                  value={p.logEntries.find((l) => l.hash === p.selectedLog)}
                  onChange={e => p.setSelectedLog(e.value.hash)}
    />
  )

  const handleGraphToggle = (graphName: string) => {
    p.setGraphCategories(p.graphCategories.map((g) => {
      return {...g, selected: graphName === g.name ? !g.selected : g.selected}
    }))
  }

  const selectGraphList = (
    <div className="kserv-column-edit-graph-buttons">
      {p.graphCategories.map((g) => {
        return (<Button selected={g.selected} key={g.name} togglable={true} onClick={() => handleGraphToggle(g.name)}>
          {t(g.name)}
        </Button>)
      })}
    </div>
  )

  return (
    <>
      <div className="kserv-edit-graph-header-row">
        {selectLogList}
        {showOriginalGraphSwitch}
      </div>
      <div>
        {selectGraphList}
      </div>
    </>
  )
}

interface EditGraphFooterProps {
  saveFilter: (comment: string) => void
  filters: ColumnFilter[],
  latestFilter: ColumnFilter
  activeFilter: ColumnFilter
  defaultFilter: ColumnFilter
  setLatestFilter: (filter: ColumnFilter) => void
  setActiveFilter: (filter: ColumnFilter) => void
}

const Footer: React.FunctionComponent<EditGraphFooterProps> = (p) => {

  const {t} = useTranslation()
  const [showSaveDialog, setShowSaveDialog] = React.useState(false)
  const [commentArea, setCommentArea] = React.useState("")

  const onTextAreaChange = (e: TextAreaChangeEvent) => {
    setCommentArea(e.value + "")
  }

  const filterList = React.useMemo(() => {
    return [{text: "Original", value: "original"}, ...p.filters.map((e) => {
      const comment = e.comment.length > 15 ? e.comment.substr(0, 16) + "..." : e.comment
      return {text: new Date(e.timestamp).toLocaleString() + " | " + comment, value: e.timestamp}
    })]
  }, [p.filters])

  const saveDialog = () => {
    return (<Dialog title={t('common.save')} onClose={() => setShowSaveDialog(false)}>
      <div>
        {t('project.editColumn.enterMessage')}
        <div>
          <TextArea value={commentArea}
                    onChange={onTextAreaChange}
                    rows={3}
          />
        </div>
      </div>
      <DialogActionsBar>
        <button className="k-button" onClick={() => setShowSaveDialog(false)}>{t('common.cancel')}</button>
        <button disabled={commentArea.trim() === ""} className="k-button" onClick={() => {
          setShowSaveDialog(false)
          p.saveFilter(commentArea)
          setCommentArea("")
        }}>{t('common.save')}
        </button>
      </DialogActionsBar>
    </Dialog>)
  }

  const getDropDownValue = () => {
    const value =  filterList.find((e) => e.value === p.latestFilter.timestamp)
    return value ? value : filterList[0]
  }

  return (
    <>
      <div className="kserv-column-edit-control">
        <DropDownList className="kserv-dropdown-filter-list"
                      data={filterList}
                      value={getDropDownValue()}
                      popupSettings={{width: "280px"}}
                      textField="text"
                      dataItemKey="value"
                      onChange={(e) => {
                        const v = e.target.value.value
                        const filt = p.filters.find((e) => e.timestamp === v)

                        if (v === "original"){
                          p.setLatestFilter(p.defaultFilter)
                          p.setActiveFilter(p.defaultFilter)
                        } else if (filt) {
                          p.setLatestFilter(filt)
                          p.setActiveFilter(filt)
                        }
                      }}
        />
          <Button className="kserv-dropdown-filter-button"
                  onClick={() => {
                    p.setActiveFilter({...p.latestFilter})
                  }}>
            {t('common.reset')}
          </Button>
          <Button  className="kserv-dropdown-filter-button"
                   themeColor="primary"
                   onClick={() => setShowSaveDialog(true)}>
            {t('common.save')}
          </Button>
      </div>
      {showSaveDialog && saveDialog()}
    </>
  )
}


const scrollIntoView = (index: number, gridRef: any) => {
  let step;
  if (index < gridRef.props.rowHeight) {
    step = Math.round(gridRef.vs.container.scrollHeight / gridRef.vs.total);
  } else {
    step =
      (gridRef.vs.container.scrollHeight - gridRef.props.rowHeight) /
      gridRef.vs.total;
    index -= 1;
  }
  gridRef.vs.container.scroll(0, index * step);
};

export const EditKDrainColumnGraph: React.FunctionComponent<EditColumnGraphProps> = ({id, column}) => {
  const {t} = useTranslation()


  const columnLogData = useSelector(kDrainColumnSelectors.selectColumnLogs(id))
  const columnFilters = useSelector(kDrainColumnSelectors.selectColumnFilters(id))
  const currentUser = useSelector(selectLoggedInUser)
  const dataLoading = useSelector(kDrainColumnSelectors.selectByIdRequestPending(id))

  const dispatch: AppDispatch = useDispatch();

  /* Default values */
  const defaultFilter: ColumnFilter = {
    timestamp: new Date(),
    comment: "",
    user_id: missingUserId,
    filter: {},
  }
  const graphList: GraphCategoryEntry[] = [
    {selected: true, value: "drill_depth", signal: 2, name: `project.editColumn.signal.drill_depth`},
    {
      selected: true,
      value: "hydraulic_pressure_winch",
      signal: 5,
      name: `project.editColumn.signal.hydraulic_pressure_winch`
    },
  ]

  const gridRef = React.useRef(null)
  const initialSort: SortDescriptor[] = [{field: 'time', dir: 'asc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};

  /* States */
  const [graphCategories, setGraphCategories] = React.useState(graphList)
  const [latestFilter, setLatestFilter] = React.useState<ColumnFilter>(defaultFilter)
  const [activeFilter, setActiveFilter] = React.useState<ColumnFilter>(defaultFilter)
  const [selectedLog, setSelectedLog] = React.useState<string>("")

  const [showOriginalGraph, setShowOriginalGraph] = React.useState(false)

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});

  const [itemInEdit, setItemInEdit] = React.useState<GraphDataFilterI | undefined>(undefined);

  const [logData, setLogData] = React.useState([] as GraphDataFilterI[])


  const saveFilter = (comment: string) => {
    if (currentUser !== undefined) {
      dispatch(kDrainColumnActions.addColumnFilters(id, column, {
        ...activeFilter,
        comment: comment,
        user_id: currentUser.id,
        timestamp: new Date()
      })).then(() => {
        dispatch(kDrainColumnActions.fetchColumnFilters(id, column))
          .then(filters => {
            if (filters && filters.length >= 0) {
              const filter = filters.sort((n1, n2) => moment(n2.timestamp).unix() - moment(n1.timestamp).unix())[0]
              setActiveFilter({...filter})
              setLatestFilter({...filter})
              dispatch(updateInfoNotification("common.saved"))
            }
          }).catch(() => { })
      })
    }
  }

  const selectedCategories = React.useMemo(() => {
    return graphCategories.filter((e) => e.selected)
  }, [graphCategories])

  const plotFilter = React.useMemo(() => {
    return activeFilter.filter[selectedLog] ? [...activeFilter.filter[selectedLog]] : []
  }, [activeFilter, selectedLog])

  React.useEffect(() => {

    dispatch(kDrainColumnActions.fetchColumnFilters(id, column))
      .then(filters => {
        if (filters && filters.length >= 0) {
          const filter = filters.sort((n1, n2) => moment(n2.timestamp).unix() - moment(n1.timestamp).unix())[0]
          setActiveFilter({...filter})
          setLatestFilter({...filter})
        }
      })

  }, [dispatch, id, column])

  const logEntries = React.useMemo(() => {
    return Object.keys(columnLogData).map((e) => {
      return {
        name: e.substr(0, 6) + " : " + new Date(columnLogData[e].t_zero).toLocaleString(),
        hash: e,
        time: columnLogData[e].t_zero
      }
    }).sort((n1, n2) => moment(n2.time).unix() - moment(n1.time).unix())
  }, [columnLogData])

  React.useEffect(() => {
    setSelectedLog(logEntries[0] ? logEntries[0].hash : "")
  }, [logEntries])

  /* Log Data Grid */
  React.useEffect(() => {
    let rawLogData: GraphDataFilterI[] = [];
    if (selectedLog === "") {
      return
    }

    const addEntry = (signalKey: string, columnLogDatum: KDrainColumnLog) => {
      const entries = columnLogDatum[signalKey as keyof KDrainColumnLog] as ColumnLogEntry
      let signal = graphCategories.find((e) => e.value === signalKey)
      if (!signal) {
        return
      }
      for (let i = 0; i < entries.Reltimes.length; i++) {
        rawLogData.push({
          pos: i,
          time: entries.Reltimes[i],
          signal: signal.signal,
          valueOriginal: entries.Values[i],
        })
      }
    }

    for (const k of Object.keys(columnLogData[selectedLog] ? columnLogData[selectedLog] : {})) {
      if (k !== "t_zero") {
        addEntry(k, columnLogData[selectedLog])
      }
    }
    setLogData(rawLogData)
  }, [selectedLog, columnLogData, graphCategories])


  const filteredDataWithFilters = React.useMemo(() => {
    const filteredData = logData.filter((e) => graphCategories.findIndex((g) => g.signal === e.signal && g.selected) >= 0)
    return filteredData.map((gdf) => {
      const filter = activeFilter ? activeFilter.filter[selectedLog] ? activeFilter.filter[selectedLog].find((fe) => fe.signal === gdf.signal && fe.position === gdf.pos) : undefined : undefined
      if (filter !== undefined) {
        return {...gdf, value: filter.value, masked: filter.masked}
      } else {
        return {...gdf, value: gdf.valueOriginal, masked: false}
      }
    })
  }, [activeFilter, selectedLog, logData, graphCategories])

  const gridData = React.useMemo(() => {
    return filterBy(orderBy(filteredDataWithFilters, sort), filter)
  }, [filteredDataWithFilters, sort, filter])

  const getData = (
    gridData.slice(pageState.skip, pageState.skip + pageState.take).map(e => {
      return itemInEdit !== undefined && itemInEdit.signal === e.signal && itemInEdit.pos === e.pos ? {
        ...e,
        inEdit: true
      } : {...e, inEdit: false}
    })
  )

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState({...pageState, skip: e.page.skip})
  }

  const onRowClick = (e: GridRowClickEvent) => {
    setItemInEdit(logData.find((l) => l.signal === e.dataItem.signal && l.pos === e.dataItem.pos))
  }

  const onSortChange = (e: GridSortChangeEvent) => {
    setSort(e.sort)
  }

  const signalCell: React.FunctionComponent<GridCellProps> = (p) => {
    const text = graphCategories.find(e => e.signal === p.dataItem.signal)
    return (
      <td colSpan={p.colSpan} style={p.style}>
        {t(text ? text.name : "error")}
      </td>
    )
  }

  const restoreCell: React.FunctionComponent<GridCellProps> = (p) => (
    <td colSpan={p.colSpan} style={p.style}>
      <Button disabled={p.dataItem.value === p.dataItem.valueOriginal && !p.dataItem.masked}
              onClick={() => {
                let tempFilter = activeFilter
                tempFilter.filter[selectedLog] = tempFilter.filter[selectedLog].filter((e) => {
                  return !(p.dataItem.signal === e.signal && p.dataItem.pos === e.position)
                })
                setActiveFilter({...tempFilter})
              }} className="k-button k-primary kserv-grid-cell-button">
        <FontAwesomeIcon icon={faTrashAlt}/>
      </Button>
    </td>
  );

  const onItemChange = (event: GridItemChangeEvent) => {
    if (event.field === undefined) {
      return
    }

    const editedItem = event.dataItem;

    let tempFilters = activeFilter

    if (tempFilters.filter[selectedLog] === undefined) {
      tempFilters.filter[selectedLog] = []
    }

    let filterPos = tempFilters.filter[selectedLog].findIndex((e) => e.signal === event.dataItem.signal && e.position === event.dataItem.pos)

    if (filterPos >= 0) {
      let filter = tempFilters.filter[selectedLog][filterPos]
      if (event.field === "masked") {
        filter[event.field] = event.value
      } else if (event.field === "value") {
        filter[event.field] = event.value
      }
      tempFilters.filter[selectedLog][filterPos] = {...filter}
    } else {
      let filter = {
        signal: editedItem.signal,
        position: editedItem.pos,
        masked: editedItem.masked,
        value: editedItem.value
      }
      if (event.field === "masked") {
        filter[event.field] = event.value
      } else if (event.field === "value") {
        filter[event.field] = event.value
      }
      tempFilters.filter[selectedLog].push({...filter})
    }
    setActiveFilter({...tempFilters})
  }

  const gridName = "kserv-column-graph-data-grid"

  const dataGrid = (
    <Grid className={gridName}
          ref={gridRef}
          data={getData}
          sortable
          sort={sort}
          onSortChange={onSortChange}
          filterable={true}
          filter={filter}
          resizable
          onFilterChange={(e) => setFilter(e.filter)}
          skip={pageState.skip}
          take={pageState.take}
          total={gridData.length}

          style={{height: '300px'}}
          rowHeight={20}
          pageSize={20}
          onPageChange={onPageChange}
          editField="inEdit"

          onRowClick={onRowClick}
          onItemChange={onItemChange}

          scrollable={'virtual'}
    >
      <Column field="time" title={t('project.editColumn.time')} width="120px" editable={false}/>
      <Column field="signal" title={t('project.editColumn.signalLabel')} sortable={false} editable={false} cell={signalCell}
              filterCell={(p) => {
                return <DropdownFilterCell {...p}
                                           text={[...Object.values(graphCategories.filter((e) => e.selected).map((e) => e.name + ""))]}
                                           data={[...Object.values(graphCategories.filter((e) => e.selected).map((e) => e.signal + ""))]}
                                           defaultItem={'All'}/>
              }}/>
      <Column field="valueOriginal" title={t('project.editColumn.orgValue')}  editable={false}/>
      <Column field="value" title={t('project.editColumn.value')}  editor="numeric"/>
      <Column field="masked" title={t('project.editColumn.masked')} width="120px" editor="boolean" filter={'boolean'}/>
      <Column field="restore" title={t('project.editColumn.restore')} filterable={false} editable={false} sortable={false} width="100px" cell={restoreCell}/>
    </Grid>
  )

  /* Graph SVG */

  const onGraphClick = (signal: number, clickPos: number) => {
    const signal_number = selectedCategories[signal % selectedCategories.length].signal
    const signal_pos = clickPos

    const newFilter: CompositeFilterDescriptor = {
      "logic": "and",
      "filters": [
        {
          "field": "signal",
          "operator": "eq",
          "value": signal_number + ""
        }
      ]
    }
    setFilter({...newFilter})
    setItemInEdit(filteredDataWithFilters.find(e => e.pos === signal_pos && e.signal === signal_number))
    const pageSkip = filterBy(orderBy(filteredDataWithFilters, sort), newFilter).findIndex(e => e.signal === signal_number && e.pos === signal_pos)
    if (gridRef.current !== null){
      scrollIntoView(pageSkip, gridRef.current)
    }
  }

  const onGraphMaskValues = (signals: GraphDataFilterI[]) => {
    if (signals && signals.length >= 0) {

      let newFilter = {...activeFilter}
      let logFilter = {...newFilter.filter}

      if (!logFilter[selectedLog]){
        logFilter[selectedLog] = []
      }

      if (logFilter && logFilter[selectedLog].length >= 0) {

        logFilter[selectedLog] = logFilter[selectedLog].map((fe) => {
          const filterExists = signals.find((e) => e.signal === fe.signal && e.pos === fe.position)
          if (!filterExists) {
            return {...fe}
          }
          return {...fe, masked: true}
        })
      }

      signals.forEach((e) => {
        if (!logFilter[selectedLog].find((fe) => e.signal === fe.signal && e.pos === fe.position)) {
          logFilter[selectedLog].push({...{signal: e.signal, position: e.pos, value: e.valueOriginal, masked: true}})
        }
      })
      newFilter.filter = logFilter
      setActiveFilter({...newFilter})
    }
  }

  const divName = "kserv-edit-graph-div"


  /* Edit tab */
  return (
    <div className={divName}>
      <div>
        <Header setShowOriginalGraph={setShowOriginalGraph}
                showOriginalGraph={showOriginalGraph}
                logEntries={logEntries}
                setSelectedLog={setSelectedLog}
                selectedLog={selectedLog}
                graphCategories={graphCategories}
                setGraphCategories={setGraphCategories}
        />
      </div>

      <div>
        <KservGraph column={column}
                    id={id}
                    data={logData}
                    filter={plotFilter}
                    categories={selectedCategories}
                    showDiff={showOriginalGraph}
                    onClick={onGraphClick}
                    onGraphMaskValues={onGraphMaskValues}
        />
      </div>

      <div>
        {dataGrid}
      </div>
      <div>
        <Footer saveFilter={saveFilter}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                latestFilter={latestFilter}
                setLatestFilter={setLatestFilter}
                filters={columnFilters}
                defaultFilter={defaultFilter}
        />
      </div>
      {dataLoading && <LoadingPanel name={divName}/>}
    </div>
  )
}