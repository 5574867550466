import {createEndpointAdapter} from "./util/endpointAdapter";
import {AppThunk, RootState} from ".";
import {KDrainProject, KRegProject, ProjectBase, ProjectId, UserId} from "../backend/model";
import {updateErrorNotification} from "./notificationSlice";
import {
    postRequestId
} from "./util/requestStateAdapter";
const adapter = createEndpointAdapter<ProjectBase, "Project">("project");
const slice = adapter.getSlice();

const {
    setPending,
    setPostResult
} = slice.actions

const postKRegProject = (project: KRegProject): AppThunk<Promise<ProjectId>> => (dispatch, _, {api}) => {
    dispatch(setPending(postRequestId, true));
    const postPromise = api.new_project.postKRegProject(project);
    postPromise
        .then(id => dispatch(setPostResult(id)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(postRequestId, false)));
    return postPromise;
}

const postKDrainProject = (project: KDrainProject): AppThunk<Promise<ProjectId>> => (dispatch, _, {api}) => {
    dispatch(setPending(postRequestId, true));
    const postPromise = api.new_project.postKDrainProject(project);
    postPromise
        .then(id => dispatch(setPostResult(id)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(postRequestId, false)));
    return postPromise;
}

//Explicitly list all actions we have available:
// - fetchById is handled by project_details instead
// - postOne is replaced by machine specific endpoints)
const {
    fetchAll,
    updateOne,
    deleteOne
} = adapter.getActions(slice, api => api.project)

export const projectActions = {
    fetchAll,
    updateOne,
    deleteOne,
    postKRegProject,
    postKDrainProject
};

export const projectSelectors = {
    ...adapter.getSelectors((state: RootState) => state.project),
    selectActiveProjects: () => (state: RootState) => {
        const activeProjects = state.project.ids.map((e) => state.project.entities[e] as ProjectBase).filter((e) => e.active)
        if (activeProjects) return activeProjects
    },
    selectProjectCustomer: (id: ProjectId) => (state: RootState) => {
        const p = state.project.entities[id];
        if (p) return state.customer.entities[p.customer_id];
    },
    selectProjectUser: (id: ProjectId) => (state: RootState) => {
        const p = state.project.entities[id];
        if (p) return state.user.entities[p.user_id];
    },
    selectUsersProjects: (id: UserId, is_active?: boolean) => (state: RootState) => {
        const filtered = state.project.ids
            .map((e) => state.project.entities[e] as ProjectBase)
            .filter((e) => (is_active ? is_active === e.active : true))
            .filter((e) => (id === e.user_id))
        if (filtered) return filtered;
    },
};
export {missingProjectId} from "../backend/model";
export default slice.reducer;
