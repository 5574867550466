import React  from "react";
import "../machine/style.scss";
import { Button } from "@progress/kendo-react-buttons";
import {Machine, MachineAdmission} from "../../backend/model";
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridPageChangeEvent,
  GridPagerSettings,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useTranslation } from "react-i18next";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {DateCell, LoadingPanel, machineTypeText, RefreshButton, ToggleButton} from "../../components";
import {faCheck, faFilter, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {machineAdmissionSelectors} from "../../store/machineAdmissionSlice";
import {useSelector} from "react-redux";

interface MachineAdmissionGridProps {
  data: MachineAdmission[],
  doApprove: (value: Machine) => void,
  doReject: (id: number) => void,
  doRefresh: () => void,
  postPendingMachine: boolean | undefined
}

export const MachineAdmissionsList: React.FunctionComponent<MachineAdmissionGridProps> = (props) => {

  const { t } = useTranslation();

  const initialSort: SortDescriptor[] = [{field: '', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const dataLoading = useSelector(machineAdmissionSelectors.selectAllRequestPending())

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const disableButton = props.postPendingMachine;

  const acceptCell: React.FunctionComponent<GridCellProps> = (p) => (
    <td colSpan={p.colSpan} style={p.style}>
      <Button onClick={() => props.doApprove({id: p.dataItem.id, name: p.dataItem.machine_name, pubkey: p.dataItem.pubkey, machine_type: p.dataItem.machine_type})} className="k-button kserv-grid-cell-button" disabled={disableButton} >
        <FontAwesomeIcon icon={faCheck}/>
      </Button>
    </td>
  );

  const rejectCell: React.FunctionComponent<GridCellProps> = (p) => (
    <td colSpan={p.colSpan} style={p.style}>
      <Button onClick={() => props.doReject(p.dataItem.id)} className="k-button k-primary kserv-grid-cell-button" disabled={disableButton}>
        <FontAwesomeIcon icon={faTrashAlt}/>
      </Button>
    </td>
  );

  const gridData = filterBy(orderBy(props.data.map(m => ({
    ...m,
    machineType: machineTypeText(m.machine_type)
  })), sort), filter)
  const gridName = "kserv-machine-admission-grid"
  const machineAdmissionGrid = (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        resizable
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        pageable={pageAble}
        onPageChange={onPageChange}
      >
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            <RefreshButton  callback={props.doRefresh}/>
          </div>
        </GridToolbar>
        <Column field="time" title={t("machine.timestamp")} filterable={false} cell={(p) => <DateCell {...p} time={p.dataItem.time}/>} width={"130px"}/>
        <Column field="machine_name" title={t("machine.name")} width={"120px"}/>
        <Column field="machineType" title={t("machine.type")} width={"120px"}/>
        <Column field="pubkey" title={t("machine.key")} />
        <Column field="Accept" title={" "} sortable={false} filterable={false} cell={acceptCell} width={"50px"}/>
        <Column field="Reject" title={" "} sortable={false} filterable={false} cell={rejectCell} width={"50px"}/>
      </Grid>
      {dataLoading && <LoadingPanel name={gridName} />}
    </>
  );

  return (
    <>
        {machineAdmissionGrid}
    </>
  );
};
