import React from 'react';
import "./style.scss";
import {ProjectId} from "../../backend/model";
import {
  Upload,
  UploadFileInfo,
  UploadFileStatus,
  UploadOnAddEvent,
  UploadOnProgressEvent,
  UploadOnRemoveEvent,
  UploadOnStatusChangeEvent
} from "@progress/kendo-react-upload";
import {AppDispatch, AppThunk} from "../../store";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import {ErrorCategory, ErrorCode} from "../../backend/error";
import {updateInfoNotification} from "../../store/notificationSlice";


interface UploadFileProps<R> {
  children?: React.ReactNode
  id: ProjectId
  extension: string
  endpoint: (id: ProjectId, filename: string, file: any) => AppThunk<Promise<R>>
  message: string | undefined
  setResponse: (diff: R) => void
}

export const UploadFile: <T>(p: UploadFileProps<T>) => React.ReactElement<UploadFileProps<T>> = (props) => {

  const dispatch: AppDispatch = useDispatch();
  const {t} = useTranslation();

  const [files, setFiles] = React.useState<UploadFileInfo[] | undefined>(undefined)

  const onSaveRequest = (
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent) => void): Promise<{ uid: string }> => {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    if (currentFile.getRawFile) {
      const rawData = currentFile.getRawFile()
      return dispatch(props.endpoint(props.id, currentFile.name, rawData))
        .then((diff) => {
          props.setResponse(diff)
          onProgress(uid, {loaded: 100, total: 100} as ProgressEvent<EventTarget>);
          dispatch(updateInfoNotification("common.uploaded"))

          return ({uid: uid});
        })
        .catch(error => {
          onProgress(uid, {loaded: 100, total: 100} as ProgressEvent<EventTarget>);
          files[0].status = UploadFileStatus.UploadFailed
          setFiles(files);
          throw error
        });
    }
    let error = {
      errorCode: ErrorCode.CannotCreate,
      errorData: {category: ErrorCategory.ColumnReport, id: -1},
      errorDetails: ""
    }
    error.errorDetails = t(`upload.invalidFiles`)
    throw error
  };

  const onAdd = (e: UploadOnAddEvent) => {
    setFiles(e.newState);
  }

  const onRemove = (e: UploadOnRemoveEvent) => {
    setFiles(e.newState);
  }

  const onProgress = (e: UploadOnProgressEvent) => {
    setFiles(e.newState);
  }

  const onStatusChange = (e: UploadOnStatusChangeEvent) => {
    setFiles(e.newState);
  }

  return (
    <>
      <Upload
        className="kserv-upload-file"
        batch={false}
        multiple={false}
        autoUpload={false}
        files={files}
        onAdd={onAdd}
        onRemove={onRemove}
        onProgress={onProgress}
        onStatusChange={onStatusChange}
        withCredentials={false}
        restrictions={{allowedExtensions: [props.extension]}}
        saveUrl={onSaveRequest}
      />
      {props.children}
      {props.message && <span className="kserv-upload-response-message">{props.message}</span>}
    </>
  );
};