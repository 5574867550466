import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {ProjectId} from "../../../backend/model";
import { AppDispatch } from "../../../store";

import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {ColumnSpecGrid} from "./column-spec-grid";

interface ColumnSpecProps {
  id: ProjectId;
}

export const ProjectColumnSpecPage: React.FunctionComponent<ColumnSpecProps> = ({id}) => {
  const dispatch: AppDispatch = useDispatch();

  const mapData = useSelector(kDrainProjectDetailsSelectors.selectMapData);
  const updateRef = useSelector(kDrainProjectDetailsSelectors.selectColumnStatusRef());

  const dataLoading = useSelector(kDrainProjectDetailsSelectors.selectByIdRequestPending(id))

  const doRefresh = () => {
    dispatch(kDrainProjectDetailsActions.getUpdate(id, updateRef ? updateRef : undefined))
      .catch(() => {})
  }

  return (
    <div className="kserv-content">
      <ColumnSpecGrid project_id={id} mapData={mapData} onRefresh={doRefresh} dataLoading={dataLoading || false}/>
    </div>
  );
};
