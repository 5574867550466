import React from "react";
import {DrainReportSummary} from "../../../backend/column_log";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, CardTitle} from "@progress/kendo-react-layout";
import {ColumnStateEnum} from "../../../backend/projectStatus";

export interface RangeStatisticsProps {
    data: DrainReportSummary[]
}

export const RangeStatistics: React.FunctionComponent<RangeStatisticsProps> = ({data}) => {
    const {t} = useTranslation()

    const stats = data.reduce((stats, col) => ({
        max_drill_depth: stats.max_drill_depth + col.max_drill_depth,
        fabric_used: stats.fabric_used + col.fabric_used,
        completed: stats.completed + (col.state === ColumnStateEnum.columnStateCompleted ? 1 : 0),
        failed: stats.failed + (col.state === ColumnStateEnum.columnStateFailed ? 1 : 0)
    }), {max_drill_depth: 0, fabric_used: 0,  completed: 0, failed: 0})


    return (
        <Card className="kserv-report-categories-card">
            <CardHeader>
                <CardTitle>{t("project.details.rangeStatistics")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="kserv-range-statistics">
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.drilledLength")}</span>
                        <span className="range-statistics-text">{stats.max_drill_depth.toFixed(1)} m</span>
                    </div>
                    <div className="range-statistics-entry w-s">
                        <span className="range-statistics-title">{t("columnState.installed")}</span>
                        <span className="range-statistics-text">{stats.completed}</span>
                    </div>
                    <div className="range-statistics-entry w-s">
                        <span className="range-statistics-title">{t("columnState.failed")}</span>
                        <span className="range-statistics-text">{stats.failed}</span>
                    </div>
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.fabricUsed")}</span>
                        <span className="range-statistics-text">{stats.fabric_used.toFixed(1)} m</span>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}