import React from "react";
import {DrainHistoryEntry} from "../../backend/column_log";
import {Dialog} from "@progress/kendo-react-dialogs";
import {DrainSpec} from "../../backend/drawing";
import {Card, CardActions, CardBody} from "@progress/kendo-react-layout";
import {ComboBox, DropDownList} from "@progress/kendo-react-dropdowns";
import {Switch, TextArea} from "@progress/kendo-react-inputs";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {kDrainProjectDetailsSelectors, kDrainProjectDetailsActions} from "../../store/kDrainProjectDetailsSlice";
import {EditDrainLog, ProjectId} from "../../backend/model";
import {ColumnStateEnum} from "../../backend/projectStatus";
import {selectLoggedInUser} from "../../store/loginSlice";
import "./style.scss"

interface EditDrainLogDialogProps {
    entry: DrainHistoryEntry
    onClose: (updated: boolean) => void
}

const distance = (a: DrainSpec, b: DrainSpec) => {
    return Math.sqrt(Math.pow(a.location[0] - b.location[0], 2) + Math.pow(a.location[1] - b.location[1], 2))
}

export const EditDrainLogDialog : React.FunctionComponent<EditDrainLogDialogProps> = ({entry, onClose}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector(selectLoggedInUser)
    const mapData = useSelector(kDrainProjectDetailsSelectors.selectMapData);
    const columns = mapData.columns
    const thisColumn = columns.find(c => c.name === entry.drain_name)
    const project = useSelector(kDrainProjectDetailsSelectors.selectProject(entry.project_id as ProjectId))
    const fabric_types = project ? project.settings.fabric_types : []
    const [fabric, setFabric] = React.useState(entry.fabric)
    const [waterUsed, setWaterUsed] = React.useState(entry.water_used)

    const availableColumns = thisColumn ? columns
        .map(c => ({name: c.name, dist: distance(c, thisColumn)}))
        .sort((a, b) => a.dist - b.dist) : []
    const data = availableColumns.map(n => ({
        name: n.name,
        text: n.dist === 0 ? n.name : `${n.name} (${n.dist.toFixed(2)}m)`
    }))

    const [selectedColumn, setSelectedColumn] = React.useState(thisColumn && {
        name: thisColumn.name,
        text: thisColumn.name
    });
    const [status, setStatus] = React.useState(entry.state)
    const [failReason, setFailReason] = React.useState(entry.fail_reason)
    const [note, setNote] = React.useState("")

    const canSend = thisColumn
        && (
            (thisColumn.name !== selectedColumn?.name) ||
            (entry.state !== status) ||
            (entry.fail_reason !== failReason) ||
            (entry.fabric !== fabric) ||
            (entry.water_used !== waterUsed)
        )
        && (status !== ColumnStateEnum.columnStateFailed || failReason.trim() !== '')
        && note !== ""

    const send = () => {
        if (selectedColumn && status) {
            const editDrainLog: EditDrainLog = {
                hash: entry.hash,
                project_id: entry.project_id,
                old_drain_name: entry.drain_name,
                new_drain_name: selectedColumn.name,
                new_state: status,
                new_fail_reason: failReason,
                new_water_used: waterUsed,
                new_fabric: fabric,
                annotation: {
                    depth: 0,
                    operator: user?.name || "",
                    note: note
                }
            };
            dispatch(kDrainProjectDetailsActions.editLog(editDrainLog))
            onClose(true)
        }
    }


    return (
        <Dialog title={t("project.details.editLog")} onClose={() => onClose(false)}>
            <Card>
                <CardBody className="kreg-column-management-edit-dialog">
                    <DropDownList data={data}
                                  value={selectedColumn}
                                  onChange={e => setSelectedColumn(e.value)}
                                  textField="text"
                                  dataItemKey="name"
                                  label={t("project.details.drainName")}
                    />
                    <DropDownList data={["completed", "aborted", "failed"]}
                                  value={status}
                                  onChange={e => setStatus(e.value)}
                                  label="Status"
                    />
                    {status !== ColumnStateEnum.columnStateFailed ? null :
                        <ComboBox allowCustom={true}
                                  value={failReason}
                                  data={[entry.fail_reason]}
                                  onChange={e => setFailReason(e.target.value)}
                                  label={t("project.details.failReason")}
                        />
                    }
                    <DropDownList value={fabric}
                                  data={fabric_types}
                                  onChange={e => setFabric(e.target.value)}
                                  label={t("project.details.fabric")}
                    />
                    <div className="kreg-column-management-edit-dialog-water">
                        <span>{t("project.columnDetails.waterUsed")}</span>
                        <Switch onLabel="ON"
                                offLabel="OFF"
                                checked={waterUsed}
                                onChange={e => setWaterUsed(e.target.value)}/>
                    </div>
                    <TextArea value={note}
                              onChange={e => setNote(e.value as string)}
                              rows={3}
                              placeholder={t("project.details.comment")}
                    />
                </CardBody>
                <CardActions className="kreg-column-management-edit-dialog-actions">
                    <Button onClick={() => onClose(false)}>{t("common.cancel")}</Button>
                    <Button onClick={send} disabled={!canSend}>{t("common.update")}</Button>
                </CardActions>
            </Card>
        </Dialog>
    )
}