import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../store";
import {useTranslation} from "react-i18next";
import { useLocation } from 'react-router-dom';

import "./style.scss"
import {Dialog} from "@progress/kendo-react-dialogs";
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import {
  clearErrorNotification,
  clearSuccessNotification,
  selectErrorNotification,
  selectInfoNotification
} from "../../store/notificationSlice";

export const KservNotification = () => {
  const dispatch: AppDispatch = useDispatch();
  const errorNotification = useSelector(selectErrorNotification);
  const infoNotification = useSelector(selectInfoNotification);

  const {t} = useTranslation();
  const location = useLocation();

  const [show, setShow] = React.useState(false)

  useEffect(() => {
      dispatch(clearErrorNotification())
      dispatch(clearSuccessNotification())
  }, [dispatch, location.pathname]);

  const message = errorNotification ? "error.message.".concat(`${errorNotification.errorCode}`) : ""
  const messageCategory = errorNotification ? errorNotification.errorData ? "error.category.".concat(`${errorNotification.errorData.category}`) : "": ""

  const ErrorPopUp = () => {
    return (
      <Dialog className="kserv-error-notification-details-popup" title={t("error.title")} onClose={() => setShow(false)}>
        <span>
          {errorNotification ? errorNotification.errorDetails : ""}
        </span>
      </Dialog>
    )
  }

  return (
    <>
      <NotificationGroup
        className="kserv-notification-group"
        style={{bottom: 5, right: 5}}
      >
        <Fade enter={true} exit={true}>
          {errorNotification && <Notification
              type={{ style: 'error', icon: false}}
              closable={true}
              onClose={() => dispatch(clearErrorNotification())}
          >
              <span className="kserv-error-notification">{t(message, {category: t(messageCategory), id: `${errorNotification.errorData.id}`}) }</span>
            { errorNotification.errorDetails &&
              <button
              type="button"
              className="kserv-notification-link"
              onClick={(e) => {
                e.preventDefault()
                setShow(true)
              }}>
                {t("common.details")}
              </button>
            }
          </Notification>}
          {infoNotification && <Notification
              type={{ style: 'success', icon: false}}
              closable={true}
              onClose={() => dispatch(clearSuccessNotification())}
          >
              <span className="kserv-info-notification">{t(`${infoNotification}`)}</span>
          </Notification>}
        </Fade>
      </NotificationGroup>
      {show && <ErrorPopUp />}
      </>
  )
}