import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {projectActions, projectSelectors} from "../../store/projectSlice";
import {AppDispatch} from "../../store";
import {useHistory} from "react-router-dom";
import {MachineType, ProjectBase, UserRoles} from "../../backend/model";
import {KRegProjectDashboardPage} from "../kreg-project-details";
import {KDrainProjectDashboardPage} from "../kdrain-project-details";
import "./style.scss"
import {Card, CardBody} from "@progress/kendo-react-layout";
import {useTranslation} from "react-i18next";
import {Button} from "@progress/kendo-react-buttons";
import {selectLoggedInUser} from "../../store/loginSlice";
import {PageTitle, machineTypeText} from "../../components";

export const UserProjectsPage = () => {
  const {t} = useTranslation()

  const projects = useSelector(projectSelectors.selectActiveProjects())
  const requestPending = useSelector(projectSelectors.selectAllRequestPending());
  const currentUser = useSelector(selectLoggedInUser);

  const history = useHistory()

  const dispatch: AppDispatch = useDispatch();

  React.useEffect(() => {
    dispatch(projectActions.fetchAll())
  }, [dispatch])

  const myProjects = currentUser?.role === UserRoles.guest ? projects : projects?.filter(p => p.user_id === currentUser?.id)

  const listOfProjects = (myProjects && myProjects.length !== 0) ? myProjects.sort((n1: ProjectBase, n2: ProjectBase) => {
      return n2.id - n1.id
    }).map((project: ProjectBase) => {
      return (
        <div key={project.id}>
          <Card>
            <CardBody>
              <div className="kserv-dashboard-layout-col">
                <div className="kserv-dashboard-select-button">
                  <Button className="k-primary" onClick={() => history.push(`/project/${project.machine_type}/details/${project.id}`)}>
                    <span>{project.keller_id} {project.name}</span>
                  </Button>
                  <span className={`${project.machine_type}-machine-type-label`}>{machineTypeText(project.machine_type)}</span>
                </div>
                {project.machine_type === MachineType.KReg ?
                    <KRegProjectDashboardPage id={project.id}/> :
                    <KDrainProjectDashboardPage id={project.id}/>}
              </div>
            </CardBody>
          </Card>
        </div>
      )
    })
    :
    <></>

  return (
    <div>
      <PageTitle>
        {(t("project.myProjects"))}
        {requestPending
          ? ": " + t("common.fetching")
          : (myProjects && myProjects.length !== 0)
            ? ""
            : ": " + t("project.noProjects")}
      </PageTitle>
      <div className="k-card-list">
        {listOfProjects}
      </div>
    </div>
  )
}