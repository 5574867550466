import React from 'react';
import ReactDOM from 'react-dom';

export const LoadingPanel = (name: any) => {

  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"/>
      <div className="k-loading-color"/>
    </div>
  );

  const gridContent = document && document.querySelector(`.name`);
  return gridContent ? ReactDOM.createPortal(loadingPanel, gridContent) : loadingPanel;
}
