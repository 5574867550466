import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

interface ToggleProps {
  value: boolean
  callback: (item: boolean) => void
  icon ?: IconProp
  text ?: string
}

export const ToggleButton: React.FunctionComponent<ToggleProps> = (p) => {

  const {t} = useTranslation();

  const icon = p.icon ? <FontAwesomeIcon icon={p.icon} /> : null
  const text = p.text ? t(`${p.text}`) : null
  const content = icon ? icon : (text ? text : "")  
  
  return (
    <Button
      className={`k-button kserv-button ${p.value ? "k-primary" : ""}`}
      togglable={true}
      onClick={() => p.callback(!p.value)}>
      {content}
    </Button>
  )
}
