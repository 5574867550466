import { createEndpointAdapter } from "./util/endpointAdapter";
import { RootState } from ".";
import { Customer } from "../backend/model";

const adapter = createEndpointAdapter<Customer, "Customer">("customer");
const slice = adapter.getSlice();

export const customerActions = adapter.getActions(slice, api => api.customer);
export const customerSelectors = adapter.getSelectors((state: RootState) => state.customer);
export { missingCustomerId } from "../backend/model";
export default slice.reducer;
