import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent, GridPagerSettings,
  GridToolbar
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { missingUserId, User, UserRoles} from "../../backend/model";
import { UserAddDialog } from "./admin-add-dialog";
import {
  CheckBoxCell,
  DropdownFilterCell,
  EditCell,
  ToggleButton,
  RefreshButton,
  AddButton,
  UserEditDialog,
  LoadingPanel
} from "../../components";
import {useSelector} from "react-redux";
import {userSelectors} from "../../store/userSlice";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

interface AdminGridProps {
  users: User[],
  onAdd: (value: User) => void,
  onDelete: (value: User) => void,
  onEdit: (value: User) => void
  onRefresh: () => void,
}

export const AdminGrid: React.FunctionComponent<AdminGridProps> = (props) => {

  const initialSort: SortDescriptor[] = [{field: 'id', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const { t } = useTranslation();
  const [itemInEdit, setItemInEdit] = React.useState<User | undefined>(undefined);
  const [newItemIn, setNewItemIn] = React.useState<User | undefined>(undefined);
  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const dataLoading = useSelector(userSelectors.selectAllRequestPending())

  const addItem = (dataItem: User) => {
    props.onAdd(dataItem);
    setNewItemIn(undefined);
  };

  const editItem = (dataItem: User) => {
    // TODO: Might need a selectAllRequestPending to check before allowing edit
    setItemInEdit({...dataItem});
  };

  const cancel = () => {
    setItemInEdit(undefined);
    setNewItemIn(undefined);
  };

  const fillEmptyNewItem = () => {
    setNewItemIn({id: missingUserId, name: "", email: "", role: UserRoles.user, is_active: false, total_dashboard: false})
  }

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridData = filterBy(orderBy(props.users, sort), filter)
  const gridName = "kserv-admin-grid"
  return (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        resizable
        pageable={pageAble}
        onPageChange={onPageChange}
      >
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            <AddButton  callback={fillEmptyNewItem} />
            <RefreshButton  callback={props.onRefresh}/>
          </div>
        </GridToolbar>
        <Column field="name" title={t("admin.name")} />
        <Column field="email" title={t("admin.email")} />
        <Column field="role" title={t("admin.role")} width={"150px"} filterCell={(p) => <DropdownFilterCell {...p} data={[...Object.values(UserRoles)]} defaultItem={'All'}  />} />
        <Column field="is_active" title={t("admin.isActive")} width={"120px"} filter={'boolean'} cell={(props) => <CheckBoxCell {...props} checked={(props.dataItem as User).is_active} />}/>
        <Column field="total_dashboard" title={t("admin.dashboardAccess")} width={"120px"} filter={'boolean'} cell={(props) => <CheckBoxCell {...props} checked={(props.dataItem as User).total_dashboard} />}/>
        <Column field="edit" title={" "} sortable={false} filterable={false} width={"50px"} cell={(p) => <EditCell<User> {...p} callback={editItem}/>}/>
      </Grid>
      {itemInEdit && <UserEditDialog itemInEdit={itemInEdit}
                                     setItemInEdit={setItemInEdit}
                                     onDelete={props.onDelete}
                                     onEdit={props.onEdit} /> }
      {newItemIn && <UserAddDialog itemInEdit={newItemIn}
                                   setItemInEdit={setNewItemIn}
                                   save={addItem}
                                   cancel={cancel}/>}
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  );
};