import React, { useEffect } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { customerActions, customerSelectors } from "../../store/customerSlice";
import { Customer, CustomerId } from "../../backend/model";
import { useTranslation } from "react-i18next";
import { AppDispatch } from "../../store";
import {CustomerEditDialog, EditButton, PageTitle} from "../../components";
import "./style.scss"
import {Input} from "@progress/kendo-react-inputs";

interface RouteParams {
  id: string;
}

export const CustomerPage = ({ match }: RouteComponentProps<RouteParams>) => {
  const id = parseInt(match.params.id) as CustomerId;
  const customer = useSelector(customerSelectors.selectById(id));
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const [itemInEdit, setItemInEdit] = React.useState<Customer | undefined>(undefined);

  useEffect(() => {
    dispatch(customerActions.fetchById(id));
  }, [dispatch, id]);

  const editItem = (customer: Customer) => {
    // TODO: Might need a selectAllRequestPending to check before allowing edit
    setItemInEdit({...customer});
  };

  const doDelete = () => {
    if (itemInEdit) {
      dispatch(customerActions.deleteOne(itemInEdit.id))
        .then((_) => setItemInEdit(undefined))
        .then(() => history.push('/customer'))
        .catch((_) => {})
    }
  };

  const doRefresh = () => {
    dispatch(customerActions.fetchById(id));
  };

  const doEdit = () => {
    if (itemInEdit) {
      dispatch(customerActions.updateOne(itemInEdit, itemInEdit.id))
        .finally(() => doRefresh())
    }
  };

  const content = customer ? (
      <div>
        <Input label={t("customer.name")}
               value={customer ? customer.name : ''}
               />
        {itemInEdit && <CustomerEditDialog itemInEdit={itemInEdit}
                                           setItemInEdit={setItemInEdit}
                                           onDelete={doDelete}
                                           onEdit={doEdit} /> }
      </div>
  ) : (
      <p>
        {t("common.unknown")}
      </p>
  );

  return (
    <div>
      <PageTitle title={t("customer.customerDetails")} >
        <EditButton<Customer> callback={editItem} dataItem={customer} />
      </PageTitle>
      <div className="kserv-content">
        {content}
      </div>
    </div>
  );
};
