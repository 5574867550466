import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {projectActions, projectSelectors} from "../../store/projectSlice";
import {kDrainProjectDetailsActions} from "../../store/kDrainProjectDetailsSlice";
import {customerActions, customerSelectors} from "../../store/customerSlice";
import {Button} from "@progress/kendo-react-buttons";
import {AppDispatch} from "../../store";
import {
    KDrainProject,
    KDrainProjectSettings,
    MachineType,
    missingCustomerId,
    missingProjectId,
    missingUserId,
    ProjectRoles
} from "../../backend/model";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {KDrainProjectSettingsPanel} from "../../components/project-settings";
import {userActions, userSelectors} from "../../store/userSlice";

const defaultProjectSettings : KDrainProjectSettings = {
    fabric_types: [],
    depth_tolerance: 0.1,
    drain_diameter: 0.5
}
const emptyFormState: KDrainProject = {
    id: missingProjectId,
    customer_id: missingCustomerId,
    user_id: missingUserId,
    name: "",
    keller_id: "",
    location: "",
    active: true,
    machine_type: MachineType.KReg,
    settings: defaultProjectSettings
};

export const KDrainProjectNew = () => {
    const history = useHistory();
    const postPending = useSelector(projectSelectors.selectPostRequestPending());
    const users = useSelector(userSelectors.selectAll())
    const customers = useSelector(customerSelectors.selectAll())

    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation();

    useEffect(() => {
        dispatch(customerActions.fetchAll());
        dispatch(userActions.fetchAll());
    }, [dispatch]);

    const [newProject, setNewProject] = useState<KDrainProject>(emptyFormState);
    const [projectRoles, setProjectRoles] = React.useState<ProjectRoles>({})
    const updateNewProject = (u: Partial<KDrainProject>) => setNewProject(p => ({...p, ...u}));
    const updateProjectRoles = (upp: ProjectRoles) => setProjectRoles(pp => ({...pp, ...upp}))

    const doPost = () => {
        dispatch(projectActions.postKDrainProject(newProject))
            .then(id => {
                dispatch(kDrainProjectDetailsActions.setProjectRoles(id, projectRoles))
                history.push(`/project/kdrain/details/${id}`)
            })
            .catch((_) => {
            });
    };

    const postDisabled = postPending ||
        newProject.customer_id === missingCustomerId ||
        newProject.user_id === missingUserId ||
        newProject.name === "" ||
        newProject.name.trim() === "" ||
        newProject.location === "" ||
        newProject.location.trim() === "" ||
        newProject.keller_id === "" ||
        newProject.keller_id.trim() === "" ||
        newProject.settings.fabric_types.length === 0;

    return (
        <div>
            <KDrainProjectSettingsPanel project={newProject}
                                        updateProject={updateNewProject}
                                        roles={projectRoles}
                                        updateProjectRoles={updateProjectRoles} canUpdateRoles={true}
                                        users={users}
                                        customers={customers}
            />
            <div className="kserv-new-project-save">
                <Button onClick={() => history.goBack()}>
                    {t("common.cancel")}
                </Button>
                <Button className="k-button k-primary kserv-button"
                        onClick={doPost}
                        themeColor="primary"
                        disabled={postDisabled}
                        value={"Save"}>
                    {t("common.create")}
                </Button>
            </div>
        </div>
    )
};
