import React from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip, ChartValueAxis, ChartValueAxisItem,
  SharedTooltipContext, TooltipContext
} from "@progress/kendo-react-charts";
import {DetailedKRegProjectSummary} from "../../../backend/summary";
import 'hammerjs';
import {useTranslation} from "react-i18next";
interface DrillDepthChartProps {
  data: DetailedKRegProjectSummary | undefined;
}

export const DrillLengthChart: React.FunctionComponent<DrillDepthChartProps> = ({data}) => {

  const { t } = useTranslation()

  const d = [
    [{ category: t("project.dashboard.drilled"), value: data ? +data.total_max_drill_depth.toFixed(2) : 0}],
    [{ category: t("project.dashboard.stabilized"), value: data ? +data.total_stabilized_length.toFixed(2) : 0}],
    [{ category: t("project.dashboard.empty"), value: data ? +data.total_dry_drilling.toFixed(2) : 0}]
  ]

  const defaultTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    const category = e.hasOwnProperty('point') ? (e as TooltipContext).point.dataItem.category : "";

    return (<span><b>{t("project.dashboard.drillLengthTooltip", {category: category, data: data})}</b></span>)
  }

  return (
    <Card className="kserv-dashboard-card kserv-total-depth-chart">
      <CardBody>
        <CardTitle className="kserv-card-title">{t("project.dashboard.drillLength")}</CardTitle>
        <Chart>
          <ChartValueAxis>
            <ChartValueAxisItem title={{text: t("project.dashboard.totalDepth")}}  min={0}/>
          </ChartValueAxis>
          <ChartTooltip render={defaultTooltipRender}/>
          <ChartSeries>
            <ChartSeriesItem type="column"
                             gap={1}
                             spacing={0.25}
                             data={d[0]}
                             field="value"
                             stack={{group: 'a'}}
            />
            <ChartSeriesItem type="column"
                             gap={1}
                             spacing={0.25}
                             data={d[1]}
                             field="value"
                             stack={{group: 'b'}}
            />
            <ChartSeriesItem type="column"
                             gap={1}
                             spacing={0.25}
                             data={d[2]}
                             field="value"
                             stack={{group: 'b'}}
            />
          </ChartSeries>
        </Chart>
      </CardBody>
    </Card>
  )
}