import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBan} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";

interface RemoveCellProps<R> extends GridCellProps {
  callback: (p: R) => void
  disabled: boolean
}

export const RemoveCell: <T>(p: RemoveCellProps<T>) => React.ReactElement<RemoveCellProps<T>> = (p) => {

  const {t} = useTranslation()

  return (<td colSpan={p.colSpan} style={p.style} className="kserv-export-cell">
    <Button title={t("project.details.export")}
            className="k-button"
            disabled={p.disabled}
            onClick={() => p.callback(p.dataItem.pid)}>
      <FontAwesomeIcon icon={faBan}/>
    </Button>
  </td>)
};
