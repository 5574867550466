import React from "react";
import {
  Grid,
  GridColumn as Column, GridPageChangeEvent,
  GridPagerSettings,
  GridRowClickEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import {Customer, ProjectBase, User} from "../../backend/model";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import { useHistory } from "react-router";
import "./style.scss"
import {
  AddButton,
  CheckBoxCell,
  DetailsCell,
  EditCell, LoadingPanel, machineTypeText,
  ProjectEditDialog,
  RefreshButton,
  ToggleButton
} from "../../components";
import {useSelector} from "react-redux";
import {projectSelectors} from "../../store/projectSlice";
import {selectIsUserAdmin} from "../../store/loginSlice";
import {faFilter} from "@fortawesome/free-solid-svg-icons";


interface ProjectGridProps {
  projects: ProjectBase[],
  customers: Customer[],
  users: User[],
  onDelete: (value: ProjectBase) => void,
  onDetails: (value: ProjectBase) => void
  onEdit: (value: ProjectBase) => void
  onRefresh: () => void,
}

export const ProjectGrid: React.FunctionComponent<ProjectGridProps> = (props) => {

  const initialSort: SortDescriptor[] = [{field: 'id', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const { t } = useTranslation();
  const history = useHistory();
  const [itemInEdit, setItemInEdit] = React.useState<ProjectBase | undefined>(undefined);
  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const dataLoading = useSelector(projectSelectors.selectAllRequestPending())
  const isAdmin = useSelector(selectIsUserAdmin);

  const onRowClick = (e: GridRowClickEvent) => {
    props.onDetails(e.dataItem);
  };

  const showItem = (itemToShow: ProjectBase) => {
    props.onDetails(itemToShow)
  };

  const gridData = (
    filterBy(orderBy(
      (
        props.projects.map((p) => {
          const customer = props.customers.find(c => c.id === p.customer_id);
          const user = props.users.find(u => u.id === p.user_id);
          const machineType = machineTypeText(p.machine_type)
          return {...p, customer, user, machineType }
        })
      ), sort), filter)
  )

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridName = "kserv-project-grid"

  return (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        pageable={pageAble}
        resizable
        onPageChange={onPageChange}
        onRowClick={onRowClick}>
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            {isAdmin && <AddButton callback={() => history.push('/project/new')}/>}
            <RefreshButton  callback={props.onRefresh}/>
          </div>
        </GridToolbar>
        <Column field="keller_id" title={t("project.id")} />
        <Column field="name" title={t("project.name")} />
        <Column field="machineType" title={t("project.projectType")} />
        <Column field="location" title={t("project.location")} />
        <Column field="customer.name" title={t("project.customer")}/>
        <Column field="user.name" title={t("project.owner")}/>
        <Column field="active" title={t("project.active")} width={"120px"} filter={'boolean'} cell={(props) => <CheckBoxCell {...props} checked={(props.dataItem as ProjectBase).active} />}/>
        <Column field="details" title={" "} width={"50px"} sortable={false} filterable={false} cell={(p) =>
          <DetailsCell<ProjectBase> {...p} callback={showItem}/>} />
        <Column field="edit" title={" "} width={"50px"} sortable={false} filterable={false} cell={(p) =>
          <EditCell<ProjectBase> {...p} callback={setItemInEdit}/>}/>
      </Grid>
      {itemInEdit && <ProjectEditDialog itemInEdit={itemInEdit}
                                        setItemInEdit={setItemInEdit}
                                        customers={props.customers}
                                        users={props.users}
                                        onDelete={props.onDelete}
                                        onEdit={props.onEdit} />}
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  );
};