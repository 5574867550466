import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"
import {EditButton} from "../edit-btn";

interface EditCellProps<R> extends GridCellProps {
  callback: (p: R) => void
}

export const EditCell: <T>(p: EditCellProps<T>) => React.ReactElement<EditCellProps<T>> = (p) => {

  type A = typeof p.dataItem

  return (<td colSpan={p.colSpan} style={p.style} className="kserv-edit-cell">
    <EditButton<A> callback={p.callback} dataItem={p.dataItem}/>
  </td>)

};
