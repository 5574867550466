import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";


interface AddProps {
  callback: () => void
}

export const AddButton: React.FunctionComponent<AddProps> = (p) => {

  const {t} = useTranslation()

  return(
    <Button
      title={t("common.add")}
      className="k-button kserv-button"
      onClick={p.callback}>
      <FontAwesomeIcon icon={faPlus}/>
    </Button>
  )
}

