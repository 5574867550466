import React, {PropsWithChildren} from "react";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";

interface AddDialogProps {
  save: () => void,
  cancel: () => void
  validate: () => boolean
}

export const AddDialog = (props: PropsWithChildren<AddDialogProps>) => {

  const {t} = useTranslation();

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
  };

  return (
    <Dialog onClose={props.cancel}>
      <form onSubmit={handleSubmit}>
        <div className="kserv-dialog-children">
          {props.children}
        </div>
      </form>
      <DialogActionsBar>
        <Button
          onClick={props.cancel}
          className="k-button"
        >
          {t("common.cancel")}
        </Button>
        <Button
          themeColor="primary"
          onClick={() => props.save()}
          disabled={props.validate()}
          className="k-button k-primary"
        >
          {t("common.add")}
        </Button>
      </DialogActionsBar>
    </Dialog>
  )
}
