import React, {useState} from "react";
import {applyPreferences, ExportCategoriesI} from "../../../components/chip-list/ExportCategories";
import {ExportColumnCategories, ExportGraphCategories, KservChipList} from "../../../components";
import {KRegProjectPreferences} from "../../../backend/preferences";
import {kRegProjectDetailsActions} from "../../../store/kRegProjectDetailsSlice";
import {Card, CardActions, CardBody, CardHeader, CardTitle, PanelBar, PanelBarItem} from "@progress/kendo-react-layout";
import {Checkbox} from "@progress/kendo-react-inputs";
import {useTranslation} from "react-i18next";
import {KRegProject} from "../../../backend/model";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../store";
import {PDFViewer} from "./pdf-viewer";
import {exportActions, ExportFileInterface, ExportFileType} from "../../../store/exportSlice";
import {Button} from "@progress/kendo-react-buttons";
import {ColumnFilter} from "../../../backend/column_log";

interface PDFPreviewProps {
    project: KRegProject
    previewColumn: string
    logFilter?: ColumnFilter
}

export const ColumnReportPreview: React.FunctionComponent<PDFPreviewProps> = ({project, previewColumn, logFilter}) => {
    const {t} = useTranslation()
    const dispatch: AppDispatch = useDispatch()

    const preferences = project.preferences
    const defaultPreviewColumnCategories = applyPreferences(ExportColumnCategories, preferences.defaultPreviewColumnCategories);
    const [columnCategories, setColumnCategories] = useState(defaultPreviewColumnCategories)
    const defaultPreviewGraphCategories = applyPreferences(ExportGraphCategories, preferences.defaultPreviewGraphCategories)
    const [graphCategories, setGraphCategories] = React.useState<ExportCategoriesI[]>(defaultPreviewGraphCategories);
    const defaultPreviewReplaceBRN = preferences.defaultPreviewReplaceBRN || false;
    const [replaceBRN, setReplaceBRN] = React.useState(defaultPreviewReplaceBRN)

    const updatePreferences = (preferences: KRegProjectPreferences) => {
        dispatch(kRegProjectDetailsActions.putProject({...project, preferences}))
    }

    const saveColumnDefault = () => {
        const defaultPreviewColumnCategories = columnCategories.filter(e => e.selected).map(e => e.value)
        updatePreferences({...preferences, defaultPreviewColumnCategories})
    }

    const saveGraphsDefault = () => {
        const defaultPreviewGraphCategories = graphCategories.filter(e => e.selected).map(e => e.value)
        updatePreferences({...preferences, defaultPreviewGraphCategories, defaultPreviewReplaceBRN: replaceBRN})
    }

    const graphs = () =>
        graphCategories
            .filter(e => e.selected)
            .map(e => e.value)
            .map(e => replaceBRN ? e.replace("graph_brn", "graph_rpm") : e)

    const previewPDF = () => {
        const options: ExportFileInterface = {
            col_id: previewColumn,
            extension: ExportFileType.PDF,
            categories: columnCategories.filter((e)=> e.selected).map((e) => e.value),
            graphs: graphs(),
            export: false,
            store: true,
            preview: true
        }
        dispatch(exportActions.downloadColReport(project.id, options, logFilter))
            .catch(() => {})
    }

    const downloadPDF = () => {
        const options: ExportFileInterface = {
            col_id: previewColumn,
            extension: ExportFileType.PDF,
            categories: columnCategories.filter((e)=> e.selected).map((e) => e.value),
            graphs: graphs(),
            export: true,
            store: false,
            preview: false
        }
        dispatch(exportActions.downloadColReport(project.id, options))
            .catch(() => {})
    }

    React.useEffect(() => {
           if (previewColumn !== "") {
               previewPDF()
           }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [previewColumn, logFilter])

    return (
        <div>
            <Card className="kserv-report-categories-card">
                <CardHeader>
                    <CardTitle>{t("export.exportColumnReport")}</CardTitle>
                </CardHeader>
                <CardBody>
                    <PanelBar className="kserv-panelbar-categories">
                        <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("project.categories")}>
                            <KservChipList text={"export.meta"} categories={columnCategories}
                                           setCategories={setColumnCategories}
                                           onDefault={() => {
                                               setColumnCategories(defaultPreviewColumnCategories)
                                           }}
                                           onSaveDefault={saveColumnDefault}
                            />
                        </PanelBarItem>
                        <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("project.graphs")}>
                            <KservChipList text={"export.graphText"}
                                           categories={graphCategories}
                                           setCategories={setGraphCategories}
                                           onDefault={() => {
                                               setGraphCategories(defaultPreviewGraphCategories)
                                               setReplaceBRN(defaultPreviewReplaceBRN)
                                           }}
                                           onSaveDefault={saveGraphsDefault}
                            >
                                <Checkbox label={t("export.graph.rpm")}
                                          value={replaceBRN}
                                          onChange={(e) => {
                                              setReplaceBRN(!replaceBRN)
                                          }}
                                />
                            </KservChipList>
                        </PanelBarItem>
                    </PanelBar>
                </CardBody>
                <CardActions layout='center'>
                    <div className="kserv-grid-toolbar">
                        <Button themeColor="primary"
                                onClick={() => previewPDF()}
                        >
                            {t("common.apply")}
                        </Button>
                        <Button onClick={(e) => {
                            downloadPDF()
                        }}>{t("common.download")}</Button>
                    </div>
                </CardActions>
            </Card>
            <PDFViewer id={project.id} column={previewColumn}/>
        </div>
    )
}
