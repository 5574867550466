import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"

interface UnitCellProps extends GridCellProps {
  unit: string
}

export const UnitCell: (p: UnitCellProps) => React.ReactElement<UnitCellProps> = (p) => {

  return (
    <td colSpan={p.colSpan} style={p.style}>
    {p.field ? p.dataItem[p.field] : ""}{p.unit}
  </td>)
};
