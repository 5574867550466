import React, {useState} from "react";
import "../style.scss"
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn as Column,
    GridPageChangeEvent,
    GridPagerSettings,
    GridRowClickEvent,
    GridToolbar
} from "@progress/kendo-react-grid";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {ProjectId} from "../../../backend/model";
import {
    DateCell,
    DropdownFilterCell,
    ExportCategoriesI,
    KDrainExportProductionCategories,
    KservChipList,
    KservDateRangePicker,
    LoadingPanel, rangeToStartTimeFilter,
    RefreshButton,
    ToggleButton
} from "../../../components";
import {ExportFileInterface, ExportFileType} from "../../../store/exportSlice";
import {addWeeks, getDate} from "@progress/kendo-date-math";
import {filterOperators} from "../../../components/grid-filter-operations/GridFilterOperators";
import {DrainReportSummary} from "../../../backend/column_log";
import {DateRange} from "../../../components/date-range-picker/kserv-date-range-picker";
import {ColumnStateEnum} from "../../../backend/projectStatus";
import {UnitCell} from "../../../components/custom-cell/unit-cell";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox} from "@progress/kendo-react-inputs";
import {Card, CardActions, CardBody, CardHeader, CardTitle, PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {faFileExcel, faFilter} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import moment from "moment";
import {RangeStatistics} from "./range-statistics";

interface KDrainColumnLogGridProps {
    project_id: ProjectId
    data: DrainReportSummary[],
    onProjDownload: (proj_id: ProjectId, options: ExportFileInterface) => void,
    onRefresh: () => void,
    selectSingleColumnPage: (title: string) => void
}

export const KdrainDrainLogGrid: React.FunctionComponent<KDrainColumnLogGridProps> = (props) => {
    const {t} = useTranslation();
    const projectRange = React.useMemo(() => {
        if (!props.data || props.data.length === 0) {
            return {start: addWeeks(getDate(new Date()), -1), end: getDate(new Date())}
        }
        const start_times = props.data.map(s => moment(s.start_time))
        return {
            start: getDate(start_times.reduce((a, b) => a.isBefore(b) ? a : b).toDate()),
            end: getDate(start_times.reduce((a, b) => a.isAfter(b) ? a : b).toDate())
        }
    }, [props.data])
    const [dateRange, setDateRange] = useState<DateRange>(projectRange)

    const [filter, setFilter] = useState<CompositeFilterDescriptor>({logic: "and", filters: []})

    const initialSort: SortDescriptor[] = [{field: 'start_time', dir: 'desc'}];
    const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
    const [pageState, setPageState] = React.useState({skip: 0, take: 10});
    const [showFilters, setShowFilters] = React.useState(false);
    const [productionDashboard, setProductionDashboard] = React.useState<boolean>(false);
    const [productionCategories, setProductionCategories] = React.useState<ExportCategoriesI[]>(KDrainExportProductionCategories);


    const dataLoading = useSelector(kDrainProjectDetailsSelectors.selectByIdRequestPending(props.project_id))

    const onPageChange = (e: GridPageChangeEvent) => {
        setPageState(e.page)
    }

    const gridName: string = "kserv-project-drain-log-grid"

    const exportProductionReport = (options: ExportFileInterface) => {
        props.onProjDownload(props.project_id, {
            ...options,
            sort: sort.length ? sort : undefined,
            filter: filter ? filter.filters : undefined,
            dateRange: rangeToStartTimeFilter(dateRange).filters,
            extension: ExportFileType.XLSX,
            dashboard: productionDashboard
        })
    }

    const onRowClick = (e: GridRowClickEvent) => {
        const data = e.dataItem.drain_name
        props.selectSingleColumnPage(data ? data : "");
    };

    const gridData = filterBy(filterBy(orderBy(props.data ? props.data.map((entry) => {
        return {
            ...entry,
            // water_used: entry.water_used ? t("common.yes") : t("common.no"),
            max_drill_depth: parseFloat(entry.max_drill_depth.toFixed(1)),
            avg_hydraulic_pressure: parseFloat(entry.avg_hydraulic_pressure.toFixed(1)),
            raw_entry: entry
        }
    }) : [], sort), filter), rangeToStartTimeFilter(dateRange))

    const exportProductionDisabled = !gridData.length

    const exportReportButton = (
        <>
            <Button className="kserv-category-export-button k-button"
                    themeColor="primary"
                    disabled={exportProductionDisabled ? exportProductionDisabled : false}
                    onClick={(e) => {
                        exportProductionReport({categories: productionCategories.filter((e) => e.selected).map((e) => e.value)})
                    }}>
                <FontAwesomeIcon icon={faFileExcel}/>
                {t("common.export")}
            </Button>
        </>
    )

    const productionReportCard = (
        <Card className="kserv-report-categories-card">
            <CardHeader>
                <CardTitle>{t("export.exportProductionReport")}</CardTitle>
            </CardHeader>
            <CardBody>
                <PanelBar className="kserv-panelbar-categories">
                    <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("common.options")}>
                        <KservChipList categories={productionCategories} setCategories={setProductionCategories}>
                            <Checkbox label={t("project.includeDashboard")}
                                      value={productionDashboard}
                                      onChange={(e) => {
                                          setProductionDashboard(!productionDashboard)
                                      }}
                            />
                        </KservChipList>
                    </PanelBarItem>
                </PanelBar>
            </CardBody>
            <CardActions layout='end'>
                {exportReportButton}
            </CardActions>
        </Card>
    )

    return (
        <>
            <Grid
                className={gridName}
                data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
                sortable={{
                    allowUnsort: true,
                    mode: 'multiple'
                }}
                sort={sort}
                onSortChange={(e) => setSort(e.sort)}
                filterable={showFilters}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                filterOperators={filterOperators}
                skip={pageState.skip}
                take={pageState.take}
                total={gridData.length}
                resizable
                pageable={pageAble}
                onRowClick={onRowClick}
                onPageChange={onPageChange}
            >
                <GridToolbar>
                    <div style={{display: "block", width: "100%"}}>
                        <div className="kserv-grid-toolbar-categories">
                            {productionReportCard}
                        </div>
                        <RangeStatistics data={gridData.map(e => e.raw_entry)} />
                        <div className="kserv-grid-toolbar">
                            <KservDateRangePicker onUpdate={setDateRange}
                                                  minDate={projectRange.start}
                                                  maxDate={projectRange.end}/>
                            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
                            <RefreshButton callback={props.onRefresh}/>
                        </div>
                    </div>
                </GridToolbar>
                <Column field="state" title={t("project.details.state")} width={"160px"}
                        filterCell={(p) => <DropdownFilterCell {...p} data={[...Object.values(ColumnStateEnum)]}
                                                               defaultItem={'All'}/>}/>
                <Column field="drain_name" title={t("project.details.name")}/>
                <Column field="num_records" title={t("project.details.records")} filter={"numeric"}/>
                <Column field="start_time" title={t("project.details.startTime")} filterable={false} format="{0:d}"
                        cell={(p) => <DateCell {...p} time={p.dataItem.start_time}/>}/>
                <Column field="max_drill_depth" title={t("project.details.maxDrillDepth")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" m"}/>}/>
                <Column field="avg_hydraulic_pressure" title={t("project.details.avgHydraulicPressure")}
                        filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" bar"}/>}/>
                <Column field="fabric" title={t("project.details.fabric")} filter={'text'}/>
                <Column field="water_used" title={t("project.details.waterUsed")} filter={'boolean'}/>
                <Column field="fail_reason" title={t("project.details.failReason")} filter={'text'}/>
                <Column field="machine_number" title={t("project.details.machineName")} filter={'text'}/>
                <Column field="machine_operator" title={t("project.details.operator")} filter={'text'}/>
            </Grid>
            {dataLoading && <LoadingPanel name={gridName}/>}
        </>
    )
}
