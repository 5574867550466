import React, {useEffect} from "react";
import "./style.scss"
import {RouteComponentProps, useHistory, useLocation} from "react-router-dom";
import {ProjectId, ProjectRole} from "../../backend/model";
import {ProjectColumnSpecPage} from "./project-column-spec-page";
import {ProjectSettingsPage} from "./project-settings-page";
import {KDrainProjectDashboardPage} from "./project-dashboard-page";
import {ProjectColumnLogPage} from "./project-column-log-page";
import {ProjectDrawingPage} from "./project-drawing-page";
import {TabStrip, TabStripSelectEventArguments, TabStripTab} from "@progress/kendo-react-layout";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../store";
import {ProjectMapPage} from "./project-map-page";
import {SingleColumnPage} from "./single-column-page";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../store/kDrainProjectDetailsSlice";
import {ProjectAdminPanel} from "./project-admin-page";
import {PageTitle} from "../../components";

interface RouteParams {
  id: string;
  page: string;
}

export const KDrainProjectPage = ({ match }: RouteComponentProps<RouteParams>) => {
  const id = parseInt(match.params.id) as ProjectId;
  const page = match.params.page as string
  const location = useLocation()

  const project = useSelector(kDrainProjectDetailsSelectors.selectProject(id))

  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const column = location.search ? location.search.startsWith("?c=") ? location.search.slice(3) : "" : ""

  const [isProjectGuest, setIsProjectGuest] = React.useState(true)
  const [singleColumnPageTitle, setSingleColumnPageTitle] = React.useState("");

  const selectSingleColumnPage = (title: string) => {
    setSingleColumnPageTitle(title)
    const pos = listOfTabs.findIndex((e) => e.id === "col-single")
    history.push(`/project/kdrain/details/${id}/${listOfTabs[pos].id}?c=${title}`);
    setSelectedTab(pos)
  }

  const listOfTabs = [
    {
      id: "overview",
      title: t("project.details.overview"),
      content: <KDrainProjectDashboardPage id={id} />,
      visible: true,
      guest: true
    },
    {
      id: "col-spec",
      title: t("project.details.specifications"),
      content: <ProjectColumnSpecPage id={id} />,
      visible: true,
      guest: true
    },
    {
      id: "col-log",
      title: t("project.details.logs"),
      content: <ProjectColumnLogPage id={id} selectSingleColumnPage={selectSingleColumnPage} />,
      visible: true,
      guest: true
    },
    {
      id: "map",
      title: t("project.details.map"),
      content: <ProjectMapPage id={id} selectedColumnName={column} selectSingleColumnPage={selectSingleColumnPage}/> ,
      visible: true,
      guest: true
    },
    {
      id: "drawing",
      title: t("project.details.drawing"),
      content: <ProjectDrawingPage id={id} />,
      visible: true,
      guest: false
    },
    {
      id: "settings",
      title: t("project.settings.settings"),
      content: <ProjectSettingsPage id={id} />,
      visible: true,
      guest: false
    },
    {
      id: "project-admin-page",
      title: t("project.details.admin"),
      content: <ProjectAdminPanel id={id} />,
      visible: true,
      guest: false
    },
    {
      id: "col-single",
      title: singleColumnPageTitle,
      content: <SingleColumnPage id={id} column={column} />,
      visible: false,
      guest: true
    },

  ].filter(tab => tab.guest ? true : !isProjectGuest)

  const getTabIndex = React.useCallback((page_id: string) => {
    const tab = listOfTabs.findIndex((tab) => tab.id === page_id)
    if (tab < 0) return 0
    return tab
  }, [listOfTabs])

  const [selectedTab, setSelectedTab] = React.useState(getTabIndex(page));

  useEffect(() => {
    dispatch(kDrainProjectDetailsActions.getMyProjectRole(id))
        .then(role => setIsProjectGuest(role === ProjectRole.guest))
        .catch(() => {})
    return (() => {})
  }, [dispatch, id, setIsProjectGuest]);

  useEffect(() => {
    dispatch(kDrainProjectDetailsActions.fetchProject(id))
      .then((_) => dispatch(kDrainProjectDetailsActions.resetProjectStatus(id)))
      .then((_) => dispatch(kDrainProjectDetailsActions.getUpdate(id)))
      .catch(() => {})
    return (() => {})
  }, [dispatch, id]);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    history.push(`/project/kdrain/details/${id}/${listOfTabs[e.selected].id}`);
    setSelectedTab(e.selected)
  }

  useEffect(() => {
    column ? setSingleColumnPageTitle(column) : setSingleColumnPageTitle(t("project.columnDetails.noColumnSelected"))
    setSelectedTab(getTabIndex(page))
  }, [t, selectedTab, page, column, getTabIndex]);

  return (
    <div className="kserv-project-page">
      <PageTitle>
        {project
          ? <>
            <span>{t("project.project")}</span>
            <span className="icon caret"/>
            <span>{project.keller_id}</span>
            <span className="icon caret"/>
            <span>{project.name}</span>
            <span className="icon caret"/>
            <span>{project.location}</span>
          </>
          : ""
        }
      </PageTitle>
      <div className="kserv-project-details">
        <TabStrip selected={selectedTab} onSelect={handleSelect}>
          {listOfTabs.map((item, index) => {
            return item.visible && <TabStripTab title={item.title} key={index}>
              {item.content}
            </TabStripTab>
          })}
          {listOfTabs.map((item, index) => {
            return !item.visible && selectedTab === index && <TabStripTab title={item.title} key={index}>
              {item.content}
            </TabStripTab>
          })}
        </TabStrip>
      </div>
    </div>
  );
};

