import { AppThunk, RootState } from ".";
import { fetchAllRequestId, createRequestStateAdapter } from "./util/requestStateAdapter";
import { MachineAdmission, MachineAdmissionId } from "../backend/model";
import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import { API, EndpointMachineAdmission } from "../backend/api";
import {updateErrorNotification} from "./notificationSlice";


const requestStateAdapter = createRequestStateAdapter();

type State = {
  ids: number[];
  entities: { [id: number]: MachineAdmission };
  postResult?: MachineAdmissionId;
} & typeof requestStateAdapter.initialState;


const initialState: State = {
  ...requestStateAdapter.initialState,
  ids: [],
  entities: {},
};

const slice =
  createSlice({
    name: "machine_admission",
    initialState,
    reducers: {
      setPending: requestStateAdapter.setPending,
      setError: requestStateAdapter.setError,
      setAll: (state, action: PayloadAction<MachineAdmission[]>) => {
        state.entities = {};
        state.ids = [];
        for (const e of action.payload) {
          state.entities[e.id] = e as Draft<MachineAdmission>;
          state.ids.push(e.id);
        }
      },
      setPostResult: (state, action: PayloadAction<MachineAdmissionId>) => {
        state.postResult = action.payload;
      },
    },
  });


const getActions = (
  endpointSelector: (api: API) => EndpointMachineAdmission
) => {
  const { setAll, setPending } = slice.actions;

  const fetchAll = (): AppThunk<Promise<MachineAdmission[]>> => (dispatch, getState, {api}) => {
    dispatch(setPending(fetchAllRequestId, true));
    const fetchPromise = endpointSelector(api).getAllAdmissions();
    fetchPromise
      .then(entities => dispatch(setAll(entities)))
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(fetchAllRequestId, false)));
    return fetchPromise;
  };

  const rejectAdmission = (id: MachineAdmissionId): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const postPromise = endpointSelector(api).rejectAdmission(id);
    postPromise
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return postPromise;
  };

  return {
    fetchAll,
    rejectAdmission,
  };
};


const getSelectors = () => {
  return {
    selectAll: () => (state: RootState) => state.machine_admission.ids.map(id => state.machine_admission.entities[id]),
    selectById: (id: MachineAdmissionId) => (state: RootState) => state.machine_admission.entities[id],
    selectPostResult: () => (state: RootState)=> state.machine_admission.postResult,
    selectByIdRequestPending: (id: MachineAdmissionId) => (state: RootState)=> state.machine_admission.pending[id],
    selectByIdRequestError: (id: MachineAdmissionId) => (state: RootState)=> state.machine_admission.error[id],
    selectAllRequestPending: () => (state: RootState)=> state.machine_admission.pending[fetchAllRequestId],
    selectAllRequestError: () => (state: RootState)=> state.machine_admission.error[fetchAllRequestId],
  };
};


export const machineAdmissionActions = getActions(api => api.machine_admission);
export const machineAdmissionSelectors = getSelectors();
export { missingMachineAdmissionId } from "../backend/model";
export default slice.reducer;
