import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {BatchJobStatus} from "../../backend/batch_job";

interface DownloadCellProps extends GridCellProps {
  callback: (p: number, c: string) => void
}

export const DownloadCell: (p: DownloadCellProps) => React.ReactElement<DownloadCellProps> = (p) => {

  const {t} = useTranslation()

  const active = (p.dataItem.nbr_of_columns === p.dataItem.processed_columns) && (p.dataItem.status === BatchJobStatus.BatchJobCompleted)

  return (<td colSpan={p.colSpan} style={p.style} className="kserv-export-cell">
    <Button title={t("project.details.export")}
            className="k-button"
            themeColor={active ? "primary" : "base"}
            disabled={!active}
            onClick={() => p.callback(p.dataItem.pid, p.dataItem.comment)}>
      <FontAwesomeIcon icon={faFileDownload}/>
    </Button>
  </td>)
};
