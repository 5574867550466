import React from "react";
import {useTranslation} from "react-i18next";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {Grid} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {LoadingPanel} from "../../../components";
import {DrawingUpdate} from "../../../backend/drawing";
import "./style.scss"

interface DrawingHistoryGridProps {
  history: DrawingUpdate[]
  dataLoading: boolean
}

export const DrawingHistoryGrid: React.FunctionComponent<DrawingHistoryGridProps> = ({history, dataLoading}) => {

  const {t} = useTranslation()

  const initialSort: SortDescriptor[] = [{field: 'timestamp', dir: 'desc'}];

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);


  const gridName: string = "kserv-project-drawing-history"

  const gridData = history ? history.map((e) => {
    return {...e, timestamp: (new Date(e.timestamp).toLocaleString())}
  }) : []

  return (
    <>
      <Grid
        className={gridName}
        data={orderBy(gridData ? gridData : [], sort)}
        scrollable={"scrollable"}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
      >
        <Column field="csv_filename" title={t("project.drawing.filename")}/>
        <Column field="timestamp" title={t("project.drawing.timestamp")} format="{0:d}" />
        <Column field="hash" title={t("project.drawing.hash")} />
      </Grid>
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  )
}