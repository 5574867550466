import React from 'react';
import "./style.scss";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
    doLogout,
    selectIsLoggedIn,
    selectIsUserAdmin, selectIsUserGuest,
    selectLoggedInUser,
    selectUserTotalDashboardAccess,
    tryLogin,
} from "../../store/loginSlice";
import {useTranslation} from "react-i18next";
import {KellerLogo} from "../keller-logo";
import {Menu, MenuSelectEvent} from "@progress/kendo-react-layout";
import {AppDispatch} from "../../store";
import {applicationActions, selectAPIVersion, selectLanguage, updateLanguage} from "../../store/applicationSlice";
import {ApplicationLanguage, supportedLanguages} from "../language/languages";
import {HelpPopUp} from "../help-pop-up";

export const NavigationBar = () => {
    const {t} = useTranslation();
    const history = useHistory();
    const dispatch: AppDispatch = useDispatch();

    const [show, setShow] = React.useState(false);

    const isLoggedIn = useSelector(selectIsLoggedIn);
    const isAdmin = useSelector(selectIsUserAdmin);
    const isGuest = useSelector(selectIsUserGuest)
    const userTotalDashboardAccess = useSelector(selectUserTotalDashboardAccess);
    const currentUser = useSelector(selectLoggedInUser);
    const language = useSelector(selectLanguage);
    const APIVersion = useSelector(selectAPIVersion);

    React.useEffect(() => {
        if (isLoggedIn) {
            dispatch(applicationActions.getAPIVersion())
        }
    }, [isLoggedIn, dispatch])

    let chosenLanguage = supportedLanguages.find(t => t.language === language);
    chosenLanguage = chosenLanguage ? chosenLanguage : supportedLanguages[0]

    const onNavBarMenuitemSelect = (e: MenuSelectEvent) => {
        history.push(e.item.data)
    }

    const navigationBarItems = () => {
        if (isGuest) {
            return [{
                text: t("project.guestMenu"),
                data: "/project/user",
                className: "kserv-navigation-bar-button"
            }]
        }
        const items = [
            {text: t("customer.title"), data: "/customer", className: "kserv-navigation-bar-button"},
            isAdmin ?
                {
                    text: t("machine.title"), data: "/machine", className: "kserv-navigation-bar-button",
                    items: [
                        {text: t("machine.title"), data: "/machine", className: "kserv-navigation-bar-button-child"},
                        {
                            text: t("machine.machineAdmissions"),
                            data: "/machine/admissions",
                            className: "kserv-navigation-bar-button-child"
                        }
                    ]
                }
                :
                {
                    text: t("machine.title"), data: "/machine", className: "kserv-navigation-bar-button"
                },
            {
                text: t("project.title"), data: "/project", className: "kserv-navigation-bar-button",
                items: [
                    {
                        text: t("project.viewAllProjects"),
                        data: "/project",
                        className: "kserv-navigation-bar-button-child"
                    },
                    {
                        text: t("project.viewMyProjects"),
                        data: "/project/user",
                        className: "kserv-navigation-bar-button-child"
                    },
                    isAdmin ?
                        {
                            text: t("project.newProject"),
                            data: "/project/new",
                            className: "kserv-navigation-bar-button-child"
                        } : {}
                ]
            },
        ];
        if (userTotalDashboardAccess) {
            items.push({
                text: t("totalDashboard.title"),
                data: "/dashboard",
                className: "kserv-navigation-bar-button"
            })
        }
        if (isAdmin) {
            items.push({
                text: t("admin.title"),
                data: "/admin",
                className: "kserv-navigation-bar-button"
            })
        }
        return items
    }


    const showUserName = isLoggedIn && currentUser ? currentUser.name : "";

    const NavBar = isLoggedIn ? (
        <div className="kserv-navigation-bar-left">
            <Menu items={navigationBarItems()} onSelect={onNavBarMenuitemSelect}/>
        </div>
    ) : null;

    const onStandardBarMenuitemSelect = (e: MenuSelectEvent) => {
        e.item.data()
    }

    const setLanguage = (language: ApplicationLanguage) => {
        dispatch(updateLanguage(language))
    }


    const loggedInUserBarItems = isGuest ? [] : [
        {
            text: showUserName,
            data: () => history.push("/project/user"),
            className: "kserv-navigation-bar-button",
        },
        {
            text: t("help.title"), data: () => setShow(true),
            className: "kserv-navigation-bar-button"
        }
        ,
        {
            text: chosenLanguage.text, data: () => {
            },
            className: "kserv-navigation-bar-button",
            items: supportedLanguages.map((lang) => {
                return {
                    text: lang.text,
                    data: () => setLanguage(lang.language),
                    className: "kserv-navigation-bar-button-child"
                }
            })
        }
    ];

    let logoutItem = {
        text: t("login.logout"),
        data: () => dispatch(doLogout()),
        className: "kserv-navigation-bar-button"
    }

    let loginItem = {
        text: t("login.login"),
        data: () => dispatch(tryLogin()),
        className: "kserv-navigation-bar-button"
    };

    const standardBarItems = isLoggedIn ? [...loggedInUserBarItems, logoutItem] : [loginItem]

    const StandardBarNew = (
        <div className="kserv-navigation-bar-right">
            <Menu items={standardBarItems} onSelect={onStandardBarMenuitemSelect}/>
        </div>
    );

    return (
        <>
            <nav className="kserv-navigation-bar">
                <KellerLogo/>
                {NavBar}
                {StandardBarNew}
            </nav>
            {show && <HelpPopUp close={() => setShow(false)} APIVersion={APIVersion}/>}
        </>
    );
};