import React from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {
  Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip, ChartValueAxis, ChartValueAxisItem,
  SharedTooltipContext, TooltipContext
} from "@progress/kendo-react-charts";
import {DetailedKDrainProjectSummary} from "../../../backend/summary";
import 'hammerjs';
import {useTranslation} from "react-i18next";
import {addWeeks} from "@progress/kendo-date-math";
interface DrillLengthChartProps {
  data: DetailedKDrainProjectSummary | undefined;
}

export const KDrainDrillWeekChart: React.FunctionComponent<DrillLengthChartProps> = ({data}) => {

  const { t } = useTranslation()

  // const categories = [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51 ];

  /**
   * Rewritten from
   * https://weeknumber.net/how-to/javascript
   */
  const getWeek = (e: Date) => {
    let d = new Date(e.getTime())
    d.setHours(0, 0, 0, 0);
    // Thursday in current week decides the year.
    d.setDate(d.getDate() + 3 - (d.getDay() + 6) % 7);
    // January 4 is always in week 1.
    const week1 = new Date(d.getFullYear(), 0, 4);
    // Adjust to Thursday in week 1 and count number of weeks from date to week1.
    return 1 + Math.round(((d.getTime() - week1.getTime()) / 86400000
      - 3 + (week1.getDay() + 6) % 7) / 7);
  }

  const columnTitle = t("totalDashboard.totalDrilledLength")

  const weekTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    return (<span><b>{t("project.dashboard.drillWeekTooltip", {data: data})}</b></span>)
  }

  const week_drilled_length = data ? data.week_chart_data ? data.week_chart_data.map((e) => e.total_max_drill_depth.toFixed(2)) : [] : []
  const weeks = data ? data.week_chart_data ? data.week_chart_data.map((e) => new Date(e.week)) : [] : []

  const maxDate = () => {
    if (weeks && weeks.length > 0) {
      return new Date(weeks[weeks.length - 1])
    }
    return new Date()
  }

  const minDate = () => {
    if (weeks && weeks.length > 0) {
      const min = 7 < weeks.length ? 7 : weeks.length - 1
      return addWeeks(maxDate(), -min)
    }
    return new Date()
  }

  const labelContent = (e: any) => {
    return "v." + getWeek(e.value) + " " + e.value.getFullYear()
  };

  return (
    <Card className="kserv-dashboard-card kserv-drill-per-week-chart">
      <CardBody>
        <CardTitle className="kserv-card-title">{t("project.dashboard.drillPerWeek")}</CardTitle>
        <Chart pannable={{ lock: 'y' }}
               zoomable={{ mousewheel: { lock: 'y' }, selection: { lock: 'y' } }}>
          <ChartLegend visible={false} position="bottom" orientation="horizontal" />
          <ChartValueAxis>
            <ChartValueAxisItem title={{text: `${columnTitle} (m)`}}/>
          </ChartValueAxis>
          <ChartTooltip render={weekTooltipRender}/>
          <ChartSeries>
            <ChartSeriesItem type="column"
                             gap={4}
                             name={columnTitle}
                             data={week_drilled_length ? week_drilled_length : []}
            />
          </ChartSeries>
          <ChartCategoryAxis>
            <ChartCategoryAxisItem
              categories={weeks ? weeks : []}
                                   baseUnit={"weeks"}
                                   weekStartDay={1}
                                   max={maxDate()}
                                   min={minDate()}
                                   maxDivisions={7}
                                   startAngle={45}
              labels={{ rotation: "auto", content: labelContent }}
            />
          </ChartCategoryAxis>
        </Chart>
      </CardBody>
    </Card>
  )
}