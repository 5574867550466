import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from ".";
import i18n from "i18next";
import { ApplicationLanguage, supportedLanguages } from "../components/language/languages";
import {createRequestStateAdapter} from "./util/requestStateAdapter";
import {API, EndpointApplication} from "../backend/api";
import {KservAPI} from "../backend/summary";
import {updateErrorNotification} from "./notificationSlice";
import {saveAs} from "file-saver";

const requestStateAdapter = createRequestStateAdapter();


type ApplicationInterface = {
  language: ApplicationLanguage;
  showMapObstacles: boolean;
  kservAPI: KservAPI;
} & typeof requestStateAdapter.initialState

const initialState: ApplicationInterface = {
  ...requestStateAdapter.initialState,
  language: supportedLanguages[0].language,
  showMapObstacles: true,
  kservAPI: {kserv_version: "", kserv_commit: ""},
};

const slice =
  createSlice({
    name: "application",
    initialState,
    reducers: {
      setPending: requestStateAdapter.setPending,
      setError: requestStateAdapter.setError,
      setLanguage: (state, action: PayloadAction<ApplicationLanguage>) => {
        state.language = action.payload;
      },
      setShowMapObstacles: (state, action: PayloadAction<boolean>) => {
        state.showMapObstacles = action.payload;
      },
      setAPIVersion: (state, action: PayloadAction<KservAPI>) => {
        state.kservAPI = action.payload
      },
    },
  });

export const { setLanguage, setShowMapObstacles, setAPIVersion, setPending, setError } = slice.actions;

const getActions = (
  endpointSelector: (api: API) => EndpointApplication
) => {
  const getAPIVersion = (): AppThunk => (dispatch, getState, {api, authenticator}) => {
    dispatch(setPending(0, true))
    const fetchPromise = endpointSelector(api).getAPIVersion();
    fetchPromise
      .then((version) => dispatch(setAPIVersion(version)))
      .catch((err) => dispatch(updateErrorNotification(err)))
      .finally(() =>   dispatch(setPending(0, false)))
  };

  const getDocUserManual = (): AppThunk => (dispatch, getState, {api, authenticator}) => {
    dispatch(setPending(0, true))
    const downloadPromise = endpointSelector(api).getDocUserManual();
    downloadPromise
      .then((blob) => {
          saveAs(blob, "user-manual.pdf")
      })
      .catch((err) => dispatch(updateErrorNotification(err)))
      .finally(() =>   dispatch(setPending(0, false)))
  }

  return {
    getAPIVersion,
    getDocUserManual
  }
}

export const updateLanguage = (state: ApplicationLanguage): AppThunk => (dispatch, getState, _) => {
  dispatch(setLanguage(state));
  i18n.changeLanguage(state)
    .finally();
  return ;
};

export const updateShowMapObstacles = (show: boolean): AppThunk => (dispatch, getState, _) => {
  dispatch(setShowMapObstacles(show));
};


export const applicationActions = getActions(api => api.application)

export const selectAPIVersion = (state: RootState) => state.application.kservAPI;
export const selectLanguage = (state: RootState) => state.application.language;
export const selectShowMapObstacles = (state: RootState) => state.application.showMapObstacles;

export default slice.reducer;
