import {DrainSpec, ColumnSpec, Obstacles} from "./drawing";

export enum ColumnStateEnum {
    columnStateUnprocessed = "unprocessed",
    columnStateFailed = "failed",
    columnStateCompleted = "completed",
    columnStateAborted = "aborted",
}


export const ColumnStateTranslation =
    {
        [ColumnStateEnum.columnStateUnprocessed]: "columnState.unprocessed",
        [ColumnStateEnum.columnStateFailed]: "columnState.failed",
        [ColumnStateEnum.columnStateCompleted]: "columnState.completed",
        [ColumnStateEnum.columnStateAborted]: "columnState.aborted",
    }

export interface ColumnStateUpdate {
    timestamp: string;
    hash: string;
    state: ColumnStateEnum;
    machine_name: string;
}

export interface ProjectColumnStatusUpdate {
  project_id: number
  latest_column_state_change: string
  update_ref: string
  updates: Record<string, ColumnStateUpdate[]>
  removals: Record<string, string[]>
}

export interface ProjectStatusUpdate {
  project_id: number
  latest_header_change: string
  latest_drawing_change: string
  latest_obstacles_change: string
  column_status_update: ProjectColumnStatusUpdate
}

// The following two pairs of interfaces could be unified by one generic type.
// Keeping it this way for simplicity.

export interface ColumnSpecWithStatus extends ColumnSpec {
    status: ColumnStateEnum,
}

export interface KRegProjectMapData {
    offset: {
        x: number,
        y: number
    },
    columns: ColumnSpecWithStatus[],
    obstacles: Obstacles,
    rtree: any
}

export interface DrainSpecWithStatus extends DrainSpec {
    status: ColumnStateEnum
}

export interface KDrainProjectMapData {
    offset: {
        x: number,
        y: number
    },
    columns: DrainSpecWithStatus[],
    obstacles: Obstacles,
    rtree: any
}