import React from "react";
import './style.scss'
import {Grid, GridCellProps, GridColumn} from "@progress/kendo-react-grid";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {useTranslation} from "react-i18next";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {Button} from "@progress/kendo-react-buttons";
import {useDispatch, useSelector} from "react-redux";
import {selectIsUserAdmin} from "../../store/loginSlice";
import {fabricTypeActions, fabricTypeSelectors, missingFabricTypeId} from "../../store/fabricTypeSlice";
import {AppDispatch} from "../../store";
import {FabricTypeId} from "../../backend/model";

export interface FabricSelectorProps {
    chosenFabrics: string[]
    setChosenFabrics: (fabrics: string[]) => void
}

export const FabricSelector: React.FunctionComponent<FabricSelectorProps> = (props) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();
    const userIsAdmin = useSelector(selectIsUserAdmin)

    const [newFabric, setNewFabric] = React.useState("")
    const pendingPost = useSelector(fabricTypeSelectors.selectPostRequestPending())

    React.useEffect(() => {
        dispatch(fabricTypeActions.fetchAll())
    }, [dispatch])


    const fabrics = useSelector(fabricTypeSelectors.selectAll())
    const fabricList = fabrics.map(f => ({...f, selected: false}))
    props.chosenFabrics.forEach(f => {
        const entry = fabricList.find(e => e.name === f)
        if (entry) {
            entry.selected = true
        } else {
            fabricList.push({name: f, selected: true, id: missingFabricTypeId})
        }
    })

    // const sort: SortDescriptor[] = [{field: "selected", dir: "desc"}, {field: "name", dir: "asc"}]
    const sort: SortDescriptor[] = [{field: "name", dir: "asc"}]
    const gridData = orderBy(fabricList, sort)

    const setSelected = (fabric: string, selected: boolean) => {
        const newChosen = props.chosenFabrics.filter(f => f !== fabric)
        if (selected) {
            newChosen.push(fabric)
        }
        props.setChosenFabrics(newChosen.sort())
    }

    const addToDb = (fabric: string) => {
        return dispatch(fabricTypeActions.postOne({name: fabric, id: missingFabricTypeId}))
            .then(_ => dispatch(fabricTypeActions.fetchAll()))
    }

    const removeFromDb = (id: FabricTypeId) => {
        dispatch(fabricTypeActions.deleteOne(id))
            .then(_ => dispatch(fabricTypeActions.fetchAll()))
    }

    const createNewFabric = () => {
        addToDb(newFabric)
            .then(_ => {
                setNewFabric("")
                dispatch(fabricTypeActions.fetchAll())
            })
            .catch(_ => {
            })
    }

    const canAddFabric = (newFabric.length !== 0) && !pendingPost;
    return (
        <div>
            <Grid className="kserv-fabric-grid" data={gridData}
                  onRowClick={e => setSelected(e.dataItem.name, !e.dataItem.selected)}>
                <GridColumn field="name" title={t("project.settings.fabric")} />
                <GridColumn title="" width={70}
                            cell={p => <FabricSelectorCell {...p} setSelected={setSelected}
                                                           addToDb={addToDb} removeFromDb={removeFromDb}
                                                           isAdmin={userIsAdmin}/>}/>
            </Grid>
            {userIsAdmin && <div className="kserv-new-fabric">
                <Input label={t("project.settings.newFabric")}
                       className="kserv-project-settings-input"
                       value={newFabric}
                       onChange={e => setNewFabric(e.value)}
                />
                <Button themeColor={canAddFabric ? "primary" : "base"} disabled={!canAddFabric}
                        onClick={createNewFabric}>{t("common.add")}</Button>
            </div>}
        </div>
    )
}


interface FabricCtrlCellProps extends GridCellProps {
    setSelected: (fabric: string, selected: boolean) => void
    removeFromDb: (id: FabricTypeId) => void
    addToDb: (fabric: string) => void
    isAdmin: boolean
}

const FabricSelectorCell: React.FunctionComponent<FabricCtrlCellProps> = (props) => {
    const {
        dataItem,
        setSelected,
        removeFromDb,
        addToDb,
        isAdmin
    } = props
    let ctrl = null;
    if (isAdmin) {
        if (dataItem.id > 0 && !dataItem.selected) {
            ctrl = <Button title="Remove from DB" onClick={() => removeFromDb(dataItem.id)}>x</Button>
        } else if (dataItem.id < 0) {
            ctrl = <Button title="Add back to DB" onClick={() => addToDb(dataItem.name)}>+</Button>
        }
    }
    return (
        <td className={dataItem.id < 0 ? "not-in-db": ""}>
            <div className={"kserv-fabric-ctrl"}>
                <Checkbox checked={dataItem.selected} onChange={e => setSelected(dataItem.name, e.value)}/>
                {ctrl}
            </div>
        </td>
    )
}