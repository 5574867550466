import React from "react";
import { useTranslation } from "react-i18next";


export const EmptyPage = () => {
  const { t } = useTranslation();

  return (
    <div className={"center"}>
      <h1>{t("empty.title")}</h1>
    </div>
  );
};
