import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userActions, userSelectors } from "../../store/userSlice";
import { AppDispatch } from "../../store";
import "./style.scss";
import { User, UserRoles } from "../../backend/model";
import { useTranslation } from "react-i18next";
import { AdminGrid } from "./admin-grid";
import { selectLoggedInUser } from "../../store/loginSlice";
import { EmptyPage } from "../empty";
import {PageTitle} from "../../components";

export const AdminPage = () => {
  const users = useSelector(userSelectors.selectAll());
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const currentUser = useSelector(selectLoggedInUser);

  useEffect(() => {
      dispatch(userActions.fetchAll());
  }, [dispatch, users.length]);

  const doDelete = (dataItem: User) => {
    dispatch(userActions.deleteOne(dataItem.id))
      .catch((_) => {})
      .finally(() => dispatch(userActions.fetchAll()))
  };

  const doEdit = (dataItem: User) => {
    dispatch(userActions.updateOne(dataItem, dataItem.id))
      .catch((_) => {})
      .finally(() => doRefresh())
  };

  const doRefresh = ()  => {
    dispatch(userActions.fetchAll());
  };

  const doPost = (dataItem: User) => {
    dispatch(userActions.postOne(dataItem))
      .then(id => dispatch(userActions.fetchById(id)))
      .catch((_) => {})
  };

  const content = currentUser && currentUser.role === UserRoles.admin ? (
    <>
      <PageTitle title={t("admin.title")} />
      <div className={"kserv-content"}>
        <AdminGrid users={users} onDelete={doDelete} onAdd={doPost} onEdit={doEdit} onRefresh={doRefresh}/>
      </div>
    </>
  ) : (
    <>
    <EmptyPage />
    </>
);

  return (
    <div>
      {content}
    </div>
  );
};
