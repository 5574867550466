import React from "react";
import { useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {KregProjectNew} from "./kreg-project-new"
import {KDrainProjectNew} from "./kdrain-project-new"
import "./style.scss"
import {selectIsUserAdmin} from "../../store/loginSlice";

import {PageTitle} from "../../components";
import {TabStrip, TabStripSelectEventArguments, TabStripTab} from "@progress/kendo-react-layout";

export const ProjectNewPage = () => {
    const isAdmin = useSelector(selectIsUserAdmin);
    const {t} = useTranslation();

    const [selected, setSelected] = React.useState(0);

    const handleSelect = (e : TabStripSelectEventArguments) => {
        setSelected(e.selected);
    };

    const content = (
        <TabStrip selected={selected} onSelect={handleSelect}>
            <TabStripTab title="KReg">
                <KregProjectNew />
            </TabStripTab>
            <TabStripTab title="KDrain">
                <KDrainProjectNew />
            </TabStripTab>
        </TabStrip>
    )

    return (
        <div>
            <PageTitle title={t("project.newProject")} />
            <div className="kserv-content kserv-new-project">
                {isAdmin && content}
            </div>
        </div>
    );
};
