import React from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {AutoComplete, DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {ColumnStateEnum, ColumnSpecWithStatus} from "../../../backend/projectStatus";
import {Button} from "@progress/kendo-react-buttons";
import "./style.scss"
import {defaultDrillDiameterValue, defaultStateValue} from "./page";

interface MapFilterBoxProps {
  columns: ColumnSpecWithStatus[],
  selectedColumn?: ColumnSpecWithStatus,
  selectColumn: (name: string) => void
  setMapFilter: (newFilter: { drill: string, state: string, column: string }) => void
}

export const MapFilterBox: React.FunctionComponent<MapFilterBoxProps> = ({
                                                                           columns,
                                                                           selectedColumn,
                                                                           selectColumn,
                                                                           setMapFilter
                                                                         }) => {

  const {t} = useTranslation()

  const [data, setData] = React.useState<string>("")

  const drillDiameter = React.useMemo(() => {
    return columns.map((e) => e.drill_diameter).filter((value, i, self) => {
      return self.indexOf(value) === i
    })
  }, [columns])

  React.useEffect(() => {
    if (selectedColumn) {
      if (columns.find((e) => e.name === selectedColumn.name)) {
        setData(selectedColumn ? selectedColumn.name : "")
      }
    }
  }, [columns, selectedColumn])

  const columnNames = React.useMemo(() => {
    return columns ? columns.map((e) => e.name) : []
  }, [columns])

  const filtered = React.useMemo(() => {
    return columnNames ? columnNames.length > 0 ? filterBy(columnNames, {
      value: data,
      operator: 'startswith',
      ignoreCase: true
    }) : [] : []
  }, [data, columnNames])

  const initFilter = {drill: t(`${defaultDrillDiameterValue}`), state: t(`${defaultStateValue}`), column: ""}
  const [filter, setFilter] = React.useState<{ drill: string, state: string, column: string }>(initFilter)

  return (
    <div className="kserv-drawing-map-details-holder">
      <div className={`k-card-list`}>
        <Card className="kserv-drawing-map-details">
          <CardBody>
            <CardTitle className="kserv-card-title">{t("project.details.selectColumnByName")}</CardTitle>
            <div className="kserv-card-entry">
              <AutoComplete label={t("project.details.selectColumn")}
                            data={filtered}
                            value={data}
                            suggest={false}
                            onChange={(e) => {
                              const value = e.target.value
                              const eventType = e.nativeEvent.type
                              setData(value ? value : "")
                              if (eventType === 'click' || value !== data) {
                                selectColumn(value)
                              }
                            }}
              />
            </div>
          </CardBody>
        </Card>
        <Card className="kserv-drawing-map-details">
          <CardBody>
            <CardTitle className="kserv-card-title">{t("project.details.filterColumns")}</CardTitle>
            <div className="kserv-card-entry">
              <DropDownList className="kserv-filter-box-comp-width"
                            label={t("project.details.state")}
                            data={[t(`${defaultStateValue}`), ...Object.values(ColumnStateEnum)]}
                            value={filter.state}
                            onChange={(e) => {
                              const value = e.target.value
                              setFilter({...filter, state: value ? value : ""})
                            }}
              />
            </div>
            <div className="kserv-card-entry">
              <DropDownList className="kserv-filter-box-comp-width"
                            label={t("project.details.drillDiameter")}
                            data={columns ? [t(`${defaultDrillDiameterValue}`), ...drillDiameter] : []}
                            value={filter.drill.toString()}
                            onChange={(e) => {
                              const value = e.target.value
                              setFilter({...filter, drill: value ? value : ""})
                            }}
                            defaultValue={t(`${defaultDrillDiameterValue}`)}
              />
            </div>
            <div className="kserv-card-entry">
              <AutoComplete className="kserv-filter-box-comp-width"
                            label={t("project.details.filterByName")}
                            data={columns ? columns.length > 0 ? filterBy(columnNames, {
                              value: filter.column ? filter.column : "",
                              operator: 'startswith',
                              ignoreCase: true
                            }) : [] : []}
                            value={filter.column ? filter.column : ""}
                            onChange={(e) => {
                              const value = e.target.value
                              setFilter({...filter, column: value ? value : ""})
                            }}
              />
            </div>
            <div className="kserv-map-filter-button-holder">
              <Button className="kserv-map-filter-button k-button"
                      onClick={() => {
                        setMapFilter(initFilter)
                        setFilter(initFilter)
                      }}
              >
                {t("common.reset")}
              </Button>
              <Button className="kserv-map-filter-button k-primary k-button"
                      onClick={() => {
                        setMapFilter(filter)
                      }}
              >
                {t("common.apply")}
              </Button>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  )
}