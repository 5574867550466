import React from "react";
import {User, UserRoles} from "../../backend/model";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {EditDialog} from "../edit-dialog";
import {useTranslation} from "react-i18next";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {useSelector} from "react-redux";
import {userSelectors} from "../../store/userSlice";

interface UserEditDialogProps {
  itemInEdit: User | any,
  setItemInEdit: (value: User | any) => void,
  onDelete: (value: User) => void,
  onEdit: (value: User) => void
}

export const UserEditDialog: React.FunctionComponent<UserEditDialogProps> = (props) => {
  const postPending = useSelector(userSelectors.selectPostRequestPending());

  const {t} = useTranslation();

  const updateUser = (u: Partial<User>) => props.setItemInEdit((p: any) => ({...p, ...u}));

  const saveItem = () => {
    if (props.itemInEdit) {
      props.onEdit(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const deleteItem = () => {
    if (props.itemInEdit) {
      props.onDelete(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const cancel = () => {
    props.setItemInEdit(undefined);
  };

  const doValidate = () => {
    return postPending || props.itemInEdit ? (props.itemInEdit.name === ""
      || props.itemInEdit.name.trim() === ""
      || props.itemInEdit.email === ""
      || props.itemInEdit.email.trim() === "") : false;
  };

  return (
    <EditDialog save={saveItem}
                delete={deleteItem}
                cancel={cancel}
                validate={doValidate}>
      <div className="kserv-dialog-container">
        <div className="kserv-dialog-field">
          <legend className="kserv-dialog-legend">{t("admin.editUser")}</legend>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.name")} <br/>
              <Input type="text"
                     autoFocus
                     value={props.itemInEdit.name}
                     pattern={"^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$"}
                     onChange={e => {
                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                       updateUser({name: v})
                     }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.email")} <br/>
              <Input type="email"
                     value={props.itemInEdit.email}
                     minLength={1}
                     pattern={"\\S+@\\S+\\.\\S+"}
                     onChange={e => {
                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                       updateUser({email: v})
                     }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.role")} <br/>
              <DropDownList data={[...Object.values(UserRoles)]}
                            defaultItem={Object.values(UserRoles).find(r => r === props.itemInEdit.role)}
                            onChange={e => {
                              const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                              updateUser({role: v})
                            }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.isActive")}<br/>
              <Checkbox checked={props.itemInEdit.is_active}
                        onChange={e => {
                          const v = e.value
                          updateUser({is_active: v})
                        }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.dashboardAccess")}<br/>
              <Checkbox checked={props.itemInEdit.total_dashboard}
                        onChange={e => {
                          const v = e.value
                          updateUser({total_dashboard: v})
                        }}/>
            </label>
          </div>
        </div>
      </div>
    </EditDialog>
  )
}
