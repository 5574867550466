import { createSlice, PayloadAction, Draft } from "@reduxjs/toolkit";
import {
  fetchAllRequestId,
  createRequestStateAdapter,
  postRequestId,
} from "./requestStateAdapter";
import { AppThunk } from "../index";
import { API, EntityEndpoint } from "../../backend/api";
import { Id } from "../../backend/model";
import {updateErrorNotification} from "../notificationSlice";
//TODO: Document this architecture
// We basically return only factory methods in order to avoid cyclic type dependencies.

export function createEndpointAdapter<EntityType extends { id: number }, EntityTypeName extends string>(
  name: string
) {
  const requestStateAdapter = createRequestStateAdapter();

  type IdType = Id<EntityTypeName>;
  type State = {
    ids: number[];
    entities: { [id: number]: EntityType | undefined };
    postResult?: IdType;
  } & typeof requestStateAdapter.initialState;

  const initialState: State = {
    ...requestStateAdapter.initialState,
    ids: [],
    entities: {},
  };

  const getSlice = () =>
    createSlice({
      name,
      initialState,
      reducers: {
        setPending: requestStateAdapter.setPending,
        setError: requestStateAdapter.setError,
        setAll: (state, action: PayloadAction<EntityType[]>) => {
          state.entities = {};
          state.ids = [];
          for (const e of action.payload) {
            state.entities[e.id] = e as Draft<EntityType>;
            state.ids.push(e.id);
          }
        },
        addOne: (state, action: PayloadAction<EntityType>) => {
          const id = action.payload.id;
          if (!state.ids.includes(id)) state.ids.push(id);
          state.entities[id] = action.payload as Draft<EntityType>;
        },
        setPostResult: (state, action: PayloadAction<IdType>) => {
          state.postResult = action.payload;
        }
      },
    });

  type SliceType = ReturnType<typeof getSlice>;

  const getActions = (
    slice: SliceType,
    endpointSelector: (api: API) => EntityEndpoint<EntityType, IdType>
  ) => {
    const { setAll, addOne, setPostResult, setPending } = slice.actions;

    const fetchAll = (): AppThunk<Promise<EntityType[]>> => (dispatch, getState, {api}) => {
      dispatch(setPending(fetchAllRequestId, true));
      const fetchPromise = endpointSelector(api).getAll();
      fetchPromise
        .then(entities => dispatch(setAll(entities)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(fetchAllRequestId, false)));
      return fetchPromise;
    };

    const fetchById = (id: IdType): AppThunk<Promise<EntityType>> => (dispatch, getState, {api}) => {
      dispatch(setPending(id, true));
      const fetchPromise = endpointSelector(api).getOne(id);
      fetchPromise
        .then(entity => dispatch(addOne(entity)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
      return fetchPromise;
    };

    const postOne = (entity: EntityType): AppThunk<Promise<IdType>> => (dispatch, getState, {api}) => {
      dispatch(setPending(postRequestId, true));
      const postPromise = endpointSelector(api).postOne(entity);
      postPromise
        .then(id => dispatch(setPostResult(id)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(postRequestId, false)));
      return postPromise;
    };

    const updateOne = (entity: EntityType, id: IdType): AppThunk<Promise<IdType>> => (dispatch, getState, {api}) => {
      dispatch(setPending(entity.id, true));
      const postPromise = endpointSelector(api).putOne(entity, id);
      postPromise
        .then(id => dispatch(setPostResult(id)))
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(entity.id, false)));
      return postPromise;
    };

    const deleteOne = (id: IdType): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
      dispatch(setPending(id, true));
      const deletePromise = endpointSelector(api).deleteOne(id);
      deletePromise
        .catch(err => dispatch(updateErrorNotification(err)))
        .finally(() => dispatch(setPending(id, false)));
      return deletePromise;
    };

    return {
      fetchAll,
      fetchById,
      postOne,
      updateOne,
      deleteOne,
    };
  };

  const getSelectors = <S>(stateSelector: (state: S) => State) => {
    const requestStateSelectors = requestStateAdapter.getSelectors<S>(stateSelector);

    return {
      selectAll: () => (s: S) => {
        const state = stateSelector(s);
        return state.ids.map(id => state.entities[id] as EntityType);
      },
      selectById: (id: IdType) => (state: S) => stateSelector(state).entities[id],
      selectPostResult: () => (state: S) => stateSelector(state).postResult,
      selectByIdRequestPending: (id: IdType) => requestStateSelectors.selectIsPending(id),
      selectByIdRequestError: (id: IdType) => requestStateSelectors.selectError(id),
      selectAllRequestPending: () => requestStateSelectors.selectIsPending(fetchAllRequestId),
      selectAllRequestError: () => requestStateSelectors.selectError(fetchAllRequestId),
      selectPostRequestPending: () => requestStateSelectors.selectIsPending(postRequestId),
      selectPostRequestError: () => requestStateSelectors.selectError(postRequestId),
    };
  };
  return {
    getSlice,
    getActions,
    getSelectors,
  };
}
