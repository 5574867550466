import React from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import "./style.scss"
import {DetailedKDrainProjectSummary} from "../../../backend/summary";
import {useTranslation} from "react-i18next";
import {ProjectBase} from "../../../backend/model";

interface ProjectDateIntervalProps {
    data: DetailedKDrainProjectSummary | undefined;
    project: ProjectBase | undefined;
}

export const KDrainProjectSummaryCard: React.FunctionComponent<ProjectDateIntervalProps> = ({data, project}) => {

    const {t} = useTranslation()

    const formatDateOutput = (date: Date) => {
        return new Date(date).toLocaleDateString()
    }

    const productionData = () => {
        return data ? data.production_days_per_machine ? data.production_days_per_machine.map((entry) => {
            return (
                <div className="kserv-dashboard-layout-row" key={entry.machine_name}>
                    <span className="project-dashboard-card-text">{entry.machine_name} : </span>
                    <span className="project-dashboard-card-text"> {entry.production_days}</span>
                </div>
            )
        }) : <div className="kserv-dashboard-layout-row">
            <span className="project-dashboard-card-text">- :</span>
            <span className="project-dashboard-card-text"> -</span>
        </div> : <div className="kserv-dashboard-layout-row">
            <span className="project-dashboard-card-text">- :</span>
            <span className="project-dashboard-card-text"> -</span>
        </div>
    }

    return (
        <Card className="kserv-dashboard-card">
            <CardBody>
                <CardTitle className="kserv-card-title">{t("common.details")}</CardTitle>
                <div className="kserv-dashboard-layout-col">
                    <div className="kserv-dashboard-layout-col">
                        <div className="kserv-dashboard-layout-row">
                            <span className="project-dashboard-card-title">{t("project.id")}</span>
                            <span className="project-dashboard-card-title caret"/>
                            <span className="project-dashboard-card-title ">  {t("project.name")}</span>
                            <span className="project-dashboard-card-title caret"/>
                            <span className="project-dashboard-card-title "> {t("project.location")}</span>
                        </div>
                        <div className="kserv-dashboard-layout-row">
                            <span className="project-dashboard-card-text">{project ? project.keller_id : " - "} </span>
                            <span className="project-dashboard-card-text caret"/>
                            <span className="project-dashboard-card-text"> {project ? project.name : " - "}</span>
                            <span className="project-dashboard-card-text caret"/>
                            <span className="project-dashboard-card-text"> {project ? project.location : " - "}</span>
                        </div>
                    </div>
                    <div className="kserv-dashboard-layout-row">
                        <div className="kserv-dashboard-layout-col">
                            <span className="project-dashboard-card-title">{t("project.dashboard.startDate")}</span>
                            <span
                                className="project-dashboard-card-text">{data ? formatDateOutput(data.project_start_date) : "  "}</span>
                        </div>
                        <div className="kserv-dashboard-layout-col">
                            <span className="project-dashboard-card-title">{t("project.dashboard.endDate")}</span>
                            <span
                                className="project-dashboard-card-text">{data ? formatDateOutput(data.project_end_date) : "  "}</span>
                        </div>
                    </div>
                    <div className="kserv-dashboard-layout-row">
                        <div className="kserv-dashboard-layout-col">
                            <CardTitle className="kserv-card-title">{t("project.dashboard.total")}</CardTitle>
                            <span
                                className="project-dashboard-card-title">{t("project.dashboard.totalDrillLength")}:</span>
                            <span
                                className="project-dashboard-card-text">{`${data ? data.total_max_drill_depth.toFixed(2) : " - "} m`}</span>
                        </div>
                        <div className="kserv-dashboard-layout-col">
                            <CardTitle className="kserv-card-title">{t("project.dashboard.average")}</CardTitle>
                            <span className="project-dashboard-card-title">{t("project.dashboard.drillDepthMax")}</span>
                            <span
                                className="project-dashboard-card-text">{`${data ? data.max_drill_depth.toFixed(2) : " - "} m`}</span>
                            <span className="project-dashboard-card-title">{t("project.dashboard.drillDepthAvg")}</span>
                            <span
                                className="project-dashboard-card-text">{`${data ? data.avg_drill_depth.toFixed(2) : " - "} m`}</span>
                        </div>
                        <div className="kserv-dashboard-layout-col">
                            <CardTitle className="kserv-card-title">{t("project.dashboard.productivity")}</CardTitle>
                            <span
                                className="project-dashboard-card-title">{t("project.dashboard.productionDays")}</span>
                            <span
                                className="project-dashboard-card-text">{data ? data.total_production_days : " - "}</span>
                            <div className="kserv-dashboard-layout-row">
                                <span
                                    className="project-dashboard-card-title">{t("project.dashboard.machineName")} :</span>
                                <span className="project-dashboard-card-title">{t("project.dashboard.days")}</span>
                            </div>
                            {data && productionData()}
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    )
}