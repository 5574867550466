import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent, GridPagerSettings,
  GridRowClickEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import {Customer, missingCustomerId} from "../../backend/model";
import { CustomerAddDialog } from "./customer-add-dialog";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import "./style.scss"
import {
  DetailsCell,
  EditCell,
  ToggleButton,
  RefreshButton,
  AddButton,
  CustomerEditDialog,
  LoadingPanel
} from "../../components";
import {useSelector} from "react-redux";
import {customerSelectors} from "../../store/customerSlice";
import {selectIsUserAdmin} from "../../store/loginSlice";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

interface CustomerGridProps {
  value: Customer[],
  onAdd: (value: Customer) => void,
  onDelete: (value: Customer) => void,
  onDetails: (value: Customer) => void
  onEdit: (value: Customer) => void
  onRefresh: () => void,
}

export const CustomerGrid: React.FunctionComponent<CustomerGridProps> = (props) => {

  const initialSort: SortDescriptor[] = [{field: '', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const { t } = useTranslation();
  const [itemInEdit, setItemInEdit] = React.useState<Customer | undefined>(undefined);
  const [newItemIn, setNewItemIn] = React.useState<Customer | undefined>(undefined);
  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const dataLoading = useSelector(customerSelectors.selectAllRequestPending())
  const isAdmin = useSelector(selectIsUserAdmin);

  const addItem = (dataItem: Customer) => {
    props.onAdd(dataItem);
    setNewItemIn(undefined);
  };

  const editItem = (dataItem: Customer) => {
    // TODO: Might need a selectAllRequestPending to check before allowing edit
    setItemInEdit({...dataItem});
  };

  const fillEmptyNewItem = () => {
    setNewItemIn({id: missingCustomerId, name: ""})
  }

  const onRowClick = (e: GridRowClickEvent) => {
    props.onDetails(e.dataItem);
  };

  const showItem = (itemToShow: Customer) => {
    props.onDetails(itemToShow)
  };

  const cancel = () => {
    setNewItemIn(undefined);
  };

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridData = filterBy(orderBy(props.value, sort), filter)
  const gridName = "kserv-customer-grid"
  return (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        resizable
        pageable={pageAble}
        onPageChange={onPageChange}
        onRowClick={onRowClick}>
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            {isAdmin && <AddButton callback={fillEmptyNewItem}/>}
            <RefreshButton  callback={props.onRefresh}/>
          </div>
        </GridToolbar>
        <Column field="name" title={t("customer.name")} />
        <Column field="details" title={" "} width="50px" sortable={false} filterable={false} cell={(p) => <DetailsCell<Customer> {...p} callback={showItem}/>} />
        <Column field="edit" title={" "} width="50px" sortable={false} filterable={false} cell={(p) => <EditCell<Customer> {...p} callback={editItem}/>}/>
      </Grid>

      {itemInEdit && <CustomerEditDialog itemInEdit={itemInEdit}
                                         setItemInEdit={setItemInEdit}
                                         onDelete={props.onDelete}
                                         onEdit={props.onEdit} />
      }
      {newItemIn && <CustomerAddDialog itemInEdit={newItemIn}
                                       setItemInEdit={setNewItemIn}
                                       save={addItem}
                                       cancel={cancel}/>}
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  );
};
