import React from "react";
import {ProjectId} from "../../../backend/model";
import "./style.scss"
import {DrainStatusChart} from "./drain-status-chart";
import {KDrainProjectSummaryCard} from "./project-summary";
import {KDrainDrillWeekChart} from "./k-drain-drill-week-chart";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import {useTranslation} from "react-i18next";
import {kDrainSummaryActions, kDrainSummarySelectors} from "../../../store/kDrainSummarySlice";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {DrainWeekChart} from "./drain-week-chart";

interface ProjectDashboardPageProps {
    id: ProjectId;
}

export const KDrainProjectDashboardPage: React.FunctionComponent<ProjectDashboardPageProps> = ({id}) => {

    const project = useSelector(kDrainProjectDetailsSelectors.selectProject(id));

    const {t} = useTranslation()
    const dispatch: AppDispatch = useDispatch();

    const fetchPending = useSelector(kDrainProjectDetailsSelectors.selectByIdRequestPending(id))
    const summary = useSelector(kDrainSummarySelectors.selectProjectSummaryById(id))

    React.useEffect(() => {
        dispatch(kDrainProjectDetailsActions.fetchProject(id))
            .then(() => {
                dispatch(kDrainSummaryActions.getKDrainProjectDashboard(id))
                    .catch(() => {
                    })
            })
            .catch(() => {
            })
        return (() => {
        })
    }, [id, dispatch])

    return (
        <div className="kserv-content">
            {!fetchPending ?
                <div className="kserv-dashboard">
                    <KDrainProjectSummaryCard data={summary} project={project}/>
                    <DrainStatusChart data={summary}/>
                    <DrainWeekChart data={summary}/>
                    <KDrainDrillWeekChart data={summary}/>
                </div>
                :
                <p>{t("common.fetching")}</p>
            }
        </div>
    )
}