import { createEndpointAdapter } from "./util/endpointAdapter";
import { RootState } from ".";
import { User } from "../backend/model";

const adapter = createEndpointAdapter<User, "User">("user");
const slice = adapter.getSlice();

export const userActions = {
  ...adapter.getActions(slice, api => api.user)
};

export const userSelectors = {
  ...adapter.getSelectors((state: RootState) => state.user),
  selectUserByEmail: (userName: string) => (state: RootState) => {
    for (const u in state.user.entities) {
      if (state.user.entities.hasOwnProperty(u)) {
        const user = state.user.entities[u];
        if (user && user.email === userName) {
          return user;
        }
      }
    }
    return undefined
  }
};
export { missingUserId } from "../backend/model";
export default slice.reducer;
