import React, {useEffect} from "react";
import {Grid, GridDetailRowProps, GridNoRecords} from "@progress/kendo-react-grid";
import {useTranslation} from "react-i18next";
import {ColumnAnnotation, DrainHistoryEntry} from "../../backend/column_log";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {useSelector} from "react-redux";
import {kDrainProjectDetailsSelectors} from "../../store/kDrainProjectDetailsSlice";
import moment from "moment";
import {LoadingPanel} from "../loading-panel";
import {ProjectId} from "../../backend/model";
import {ColumnStateEnum} from "../../backend/projectStatus";
import {EditDrainLogDialog} from "./edit-drain-log";

const LogDetails: React.FunctionComponent<GridDetailRowProps> = ({dataItem}) => {
    const {t} = useTranslation();
    const annotation = dataItem.annotation as ColumnAnnotation;
    const data = [];
    const numAnnotations = annotation.depth === null ? 0 : annotation.depth.length;
    for (let i = 0; i < numAnnotations; i++) {
        data.push({
            depth: annotation.depth && annotation.depth[i].toFixed(1) + "m",
            operator: annotation.operator && annotation.operator[i],
            note: annotation.note && annotation.note[i]
        });
    }
    return (
        <Grid data={data} className="annotation-list">
            <GridNoRecords>
                {t("project.columnDetails.noAnnotation")}
            </GridNoRecords>
            <Column field="depth" title={t("project.columnDetails.depth")} width={65}/>
            <Column field="operator" title={t("project.columnDetails.operator")} width={85}/>
            <Column field="note" title={t("project.columnDetails.note")}/>
        </Grid>
    );
};

interface DrainHistoryGridProps {
    id: ProjectId;
    selectedColumn?: string;
    getDrainHistory: (name: string) => void;
}

export const DrainHistoryGrid: React.FunctionComponent<DrainHistoryGridProps> =
    ({id, selectedColumn, getDrainHistory}) => {
        const columnHistory = useSelector(kDrainProjectDetailsSelectors.selectColumnHistory())
        const entries = columnHistory?.entries || []
        const dataLoading = useSelector((kDrainProjectDetailsSelectors.selectByIdRequestPending(id)))
        const [editDrainLog, setEditDrainLog] = React.useState<DrainHistoryEntry | undefined>(undefined)
        const {t} = useTranslation()

        useEffect(() => {
            if (!selectedColumn) {
                return
            }
            getDrainHistory(selectedColumn)
            return (() => {
            })
        }, [getDrainHistory, selectedColumn])


        const dataSorted =
            [...entries].sort((a, b) => moment(a.start_time).unix() - moment(b.start_time).unix())

        const gridData = dataSorted.length > 0 ? dataSorted
            .map((e) => {
                    return {
                        date: moment(e.start_time).format("YYYY-MM-DD"),
                        start_time: moment(e.start_time).format("HH:mm"),
                        completion_time: moment(e.completion_time).format("HH:mm"),
                        status: e.state !== ColumnStateEnum.columnStateFailed ? e.state : `${e.state} - ${e.fail_reason}`,
                        drilled: e.drain_stats.max_drill_depth.toFixed(2),
                        fabric: e.fabric,
                        water: e.water_used ? "on" : "off",
                        annotation: e.annotation,
                        rawEntry: e
                    }
                }
            ) : []


        const gridName = "kserv-entries-history-entry-grid"

        const onClose = (updated: boolean) => {
            setEditDrainLog(undefined);
            if (updated && selectedColumn) {
                setTimeout(() => getDrainHistory(selectedColumn), 100)
            }
        }

        return (
            <div className={`${selectedColumn ? "" : "kserv-hidden"}`}>
                <Grid className={gridName} data={gridData} detail={LogDetails} onRowClick={e => setEditDrainLog(e.dataItem.rawEntry)}>
                    <Column field="date" width={105} title={t("project.columnDetails.date")}/>
                    <Column field="start_time" width={65} title={t("project.columnDetails.startTime")}/>
                    <Column field="completion_time" width={65} title={t("project.columnDetails.completionTime")}/>
                    <Column field="drilled" width={70} title={t("project.columnDetails.drilled")}/>
                    <Column field="fabric" width={105} title={t("project.columnDetails.fabric")}/>
                    <Column field="water" width={70} title={t("project.columnDetails.waterUsed")}/>
                    <Column field="status" title={t("project.columnDetails.status")}/>
                </Grid>
                {dataLoading && <LoadingPanel name={gridName}/>}
                {editDrainLog && <EditDrainLogDialog entry={editDrainLog} onClose={onClose}/>}
            </div>

        )
    }
