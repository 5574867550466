import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {machineActions, machineSelectors, missingMachineId} from "../../store/machineSlice";
import { AppDispatch } from "../../store";
import "./style.scss";
import {Machine, MachineAdmissionId} from "../../backend/model";
import { useTranslation } from "react-i18next";
import {MachineAdmissionsList} from "./machine-admission-grid";
import {machineAdmissionActions, machineAdmissionSelectors} from "../../store/machineAdmissionSlice";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {PageTitle} from "../../components";

export const MachineAdmissionPage = () => {
  const machineAdmissions = useSelector(machineAdmissionSelectors.selectAll());
  const machines = useSelector(machineSelectors.selectAll());
  const postPendingMachine = useSelector(machineSelectors.selectPostRequestPending());
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const [newMachine, setNewMachine] = React.useState<Machine|undefined>(undefined)

  useEffect(() => {
    dispatch(machineAdmissionActions.fetchAll());
    dispatch(machineActions.fetchAll());
  }, [dispatch]);

  const doReject = (id : number) => {
    dispatch(machineAdmissionActions.rejectAdmission(id as MachineAdmissionId))
      .then((_) => dispatch(machineAdmissionActions.fetchAll()))
      .catch(() => {})
  };

  const doApprove = (new_machine_entry : Machine) => {
    if (machines && machines.find((e) => e.name === new_machine_entry.name)) {
      setNewMachine(new_machine_entry)
    } else {
        dispatch(machineActions.postOne({...new_machine_entry, id: missingMachineId}))
          .then(() => dispatch(machineAdmissionActions.fetchAll()))
          .then(() =>  dispatch(machineActions.fetchAll()))
          .catch((_) => {})
    }
  };

  const doRefresh = ()  => {
    dispatch(machineAdmissionActions.fetchAll());
  };

  const UpdateMachineKey = () => {
    return (
      <Dialog>
        {t("machine.updateMachineKey")}
        <DialogActionsBar>
          <button className="k-button"
                  onClick={() => setNewMachine(undefined)}>
            <FontAwesomeIcon icon={faWindowClose}/>
          </button>
          <button className="k-button"
                  onClick={() => {
                    if (!!newMachine) {
                      const old_machine = machines.find(({name}) => name === newMachine.name)
                      if (!!old_machine) {
                        dispatch(machineActions.updateOne({...old_machine, pubkey: newMachine.pubkey}, old_machine.id))
                          .then(() => doReject(newMachine.id))
                          .then(() => dispatch(machineAdmissionActions.fetchAll()))
                          .then(() => dispatch(machineActions.fetchAll()))
                          .catch((_) => {
                          })
                          .finally(() => setNewMachine(undefined))
                      }
                    }
                  }}>
            <FontAwesomeIcon icon={faCheck}/>
          </button>
        </DialogActionsBar>
      </Dialog>
    )
  }

  const setting = {
    data: machineAdmissions,
    doApprove: doApprove,
    doReject: doReject,
    doRefresh: doRefresh,
    postPendingMachine: postPendingMachine,
  }

  return (
    <div>
      <PageTitle title={t("machine.titleAdmission")} />
      <div className="kserv-content">
        <MachineAdmissionsList {...setting} />
        {newMachine && <UpdateMachineKey />}
      </div>
    </div>
  );
};
