import React from "react";
import {Input} from "@progress/kendo-react-inputs";
import { customerSelectors } from "../../store/customerSlice";
import {useSelector} from "react-redux";
import { useTranslation } from "react-i18next";
import {Customer} from "../../backend/model";
import {AddDialog} from "../../components";

interface AddCustomerDialogProps {
    itemInEdit: Customer | any,
    setItemInEdit: (value: Customer|any) => void,
    save: (dataItem: Customer) => void
    cancel: () => void
}

export const CustomerAddDialog: React.FunctionComponent<AddCustomerDialogProps> = (props) => {
    const postPending = useSelector(customerSelectors.selectPostRequestPending());

    const {t} = useTranslation();

    const updateCustomer = (u: Partial<Customer>) => props.setItemInEdit((p: any) => ({ ...p, ...u }));

    const saveItem = () => {
        if (props.itemInEdit) {
            props.save(props.itemInEdit);
        }
    };

    const customerNameValidationMessage = t("customer.fieldEmpty");

    const doValidate = () => {
        if (props.itemInEdit) {
            return !(postPending || props.itemInEdit.name !== "" || props.itemInEdit.name.trim() !== "")
        }
        return false
    }

    return  (
      <AddDialog save={saveItem}
                 cancel={props.cancel}
                 validate={doValidate}>
          <div className="kserv-dialog-container">
              <div className="kserv-dialog-field">
                  <legend className="kserv-dialog-legend">{t("customer.addCustomer")}</legend>
                  <div className="kserv-dialog-item">
                      <Input value={props.itemInEdit ? props.itemInEdit.name : ""}
                             autoFocus
                             label={t("customer.customerName")}
                             onChange={e => {
                                 const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                                 updateCustomer({name: v})
                             }}
                             validationMessage={customerNameValidationMessage} />
                  </div>
              </div>
          </div>
      </AddDialog>
    );
};
