import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import "./style.scss"

interface PageTitleProps {
  title?: string
  icon ?: IconProp
  children?: React.ReactNode
}

export const PageTitle: React.FunctionComponent<PageTitleProps> = (props) => {

  return (
    <div className="kserv-page-title">
      <span className="kserv-page-title-text">
        {props.icon ? <FontAwesomeIcon icon={props.icon }/> : <></>}
        {props.title}
        {props.children}
      </span>
    </div>
  )
}