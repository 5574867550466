import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel, faFilePdf} from "@fortawesome/free-solid-svg-icons";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {ExportFileType} from "../../store/exportSlice";

interface ExportCellProps<R> extends GridCellProps {
  callback: (p: R, t: ExportFileType) => void
  disabled: boolean
}

export const ExportCell: <T>(p: ExportCellProps<T>) => React.ReactElement<ExportCellProps<T>> = (p) => {

  const {t} = useTranslation()

  return (<td colSpan={p.colSpan} style={p.style} className="kserv-export-cell">
    <Button title={t("project.details.export")}
            className="k-button kserv-pdf-button"
            disabled={p.disabled}
            onClick={() => p.callback(p.dataItem, ExportFileType.PDF)}>
      <FontAwesomeIcon icon={faFilePdf}/>
    </Button>
    <Button title={t("project.details.export")}
            className="k-button kserv-excel-button"
            disabled={p.disabled}
            onClick={() => p.callback(p.dataItem, ExportFileType.XLSX)}>
      <FontAwesomeIcon icon={faFileExcel}/>
    </Button>
  </td>)
};
