import React from "react";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridPagerSettings,
  GridRowClickEvent,
  GridToolbar
} from "@progress/kendo-react-grid";
import { useTranslation } from "react-i18next";
import { Machine } from "../../backend/model";
import { CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor } from "@progress/kendo-data-query";
import {
  DetailsCell,
  EditCell,
  ToggleButton,
  RefreshButton,
  MachineEditDialog, LoadingPanel, machineTypeText,
} from "../../components";
import {useSelector} from "react-redux";
import {machineSelectors} from "../../store/machineSlice";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

interface MachineGridProps {
  machines: Machine[],
  onDelete: (value: Machine) => void,
  onDetails: (value: Machine) => void
  onEdit: (value: Machine) => void
  onRefresh: () => void,
}

export const MachineGrid: React.FunctionComponent<MachineGridProps> = (props) => {

  const initialSort: SortDescriptor[] = [{field: 'id', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const { t } = useTranslation();
  const [itemInEdit, setItemInEdit] = React.useState<Machine | undefined>(undefined);
  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const dataLoading = useSelector(machineSelectors.selectAllRequestPending())

  const editItem = (dataItem: Machine) => {
    // TODO: Might need a selectAllRequestPending to check before allowing edit
    setItemInEdit({...dataItem});
  };

  const onRowClick = (e: GridRowClickEvent) => {
    props.onDetails(e.dataItem);
  };

  const showItem = (itemToShow: Machine) => {
    props.onDetails(itemToShow)
  };

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridData = filterBy(orderBy(props.machines.map(m => ({
    ...m,
    machineType: machineTypeText(m.machine_type)
  })), sort), filter)
  const gridName = "kserv-machine-grid"

  return (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        resizable
        pageable={pageAble}
        onPageChange={onPageChange}
        onRowClick={onRowClick}>
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            <RefreshButton  callback={props.onRefresh}/>
          </div>
        </GridToolbar>
        <Column field="name" title={t("machine.name")} />
        <Column field="machineType" title={t("machine.type")} />
        <Column field="details" title={" "} sortable={false} filterable={false} width={"50px"} cell={(p) => <DetailsCell<Machine> {...p} callback={showItem}/>} />
        <Column field="edit" title={" "} sortable={false} filterable={false} width={"50px"} cell={(p) => <EditCell<Machine> {...p} callback={editItem}/>}/>
      </Grid>
      {itemInEdit && <MachineEditDialog itemInEdit={itemInEdit}
                                        setItemInEdit={setItemInEdit}
                                        onDelete={props.onDelete}
                                        onEdit={props.onEdit} />}
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  );
};