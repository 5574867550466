import React, {useEffect} from "react";
import {ProjectId} from "../../../backend/model";
import {GraphCategoryEntry, GraphDataFilterI} from "./edit-k-drain-column-graph";

import {Config, Data, Datum, Layout, PlotMouseEvent, PlotSelectionEvent} from "plotly.js"
import Plot, {Figure} from 'react-plotly.js';
import {useTranslation} from "react-i18next";
import {ColumnLogFilter} from "../../../backend/column_log";
import * as d3 from "d3";

interface GraphProps {
  id: ProjectId;
  column: string;
  data: GraphDataFilterI[]
  filter: ColumnLogFilter
  categories: GraphCategoryEntry[]
  showDiff: boolean
  onClick: (signal: number, pos: number) => void
  onGraphMaskValues: (signals: GraphDataFilterI[]) => void
}

export const KservGraph: React.FunctionComponent<GraphProps> = (props) => {

  const {t} = useTranslation()
  const measureRef = React.useRef<HTMLDivElement>(null);
  const [size, setSize] = React.useState({width: 0, height: 0});
  const [selectedPoints, setSelectedPoints] = React.useState<GraphDataFilterI[]>([]);
  const hoverTooltip = React.useRef<HTMLDivElement>(null);

  React.useLayoutEffect(() => {
    const el = measureRef.current;
    const updateSize = () => {
      if (el !== null) {
        setSize({width: el.clientWidth, height: el.clientHeight});
      }
    };
    if (el !== null) {
      window.addEventListener("resize", updateSize);
    }
    updateSize();
    return (() => {
      if (el !== null) {
        window.removeEventListener("resize", updateSize);
      }
    });
  }, [measureRef]);

  const BaseLayout: Partial<Layout> = React.useMemo(() => {
    return {
      autosize: false,
      margin: {
        l: 40,
        r: 30,
        b: 15,
        t: 15,
        pad: 4
      },
      paper_bgcolor: "#f3f3f4",
      plot_bgcolor: "#fafafa",
      legend: {traceorder: 'reversed'},
    }
  }, [])

  const [layout, setLayout] = React.useState(BaseLayout)
  const [plotData, setPlotData] = React.useState([] as Data[])

  const getDomain = (pos: number, height: number, nbr: number) => {
    return [(pos-1)*(1/nbr) + 13/height, 1 - (nbr-pos)*(1/nbr)]
  }

  React.useEffect(() => {
    const colors = [
      '#1f77b4',  // muted blue
      '#ff7f0e',  // safety orange
      '#2ca02c',  // cooked asparagus green
      '#d62728',  // brick red
      '#9467bd',  // muted purple
      '#8c564b',  // chestnut brown
      '#e377c2',  // raspberry yogurt pink
      '#7f7f7f',  // middle gray
      '#bcbd22',  // curry yellow-green
      '#17becf'   // blue-teal
    ]

    const colorsDarkened = [
      '#1a6497',  // muted blue
      '#d56a0c',  // safety orange
      '#258625',  // cooked asparagus green
      '#b32121',  // brick red
      '#7c569e',  // muted purple
      '#75483f',  // chestnut brown
      '#be64a2',  // raspberry yogurt pink
      '#6a6a6a',  // middle gray
      '#9d9e1c',  // curry yellow-green
      '#139fad'   // blue-teal
    ]

    const removeMasked = (e: GraphDataFilterI) => {
      const index =  props.filter ? props.filter.findIndex((f) => e.pos === f.position && e.signal === f.signal && f.masked) : undefined
      return index === undefined || index < 0;
    }
    const getValue = (e: GraphDataFilterI, field: string) => {
      switch (field) {
        case "time":
          return e.time

        case "value":
          const index = props.filter ? props.filter.findIndex((f) => e.pos === f.position && e.signal === f.signal) : undefined
          if (index === undefined || index < 0) {
            return e.valueOriginal
          }
          return props.filter[index].value

        default:
          return 0
      }
    }
    const categories = props.categories
    let traces: Data[] =  categories.map((c, i) => {
      return {
        x: props.data.filter((e) => e.signal === c.signal).filter(removeMasked).map((e) => getValue(e, "time")),
        y: props.data.filter((e) => e.signal === c.signal).filter(removeMasked).map((e) => getValue(e, "value")),
        yaxis: `y${i+1}`,
        name: t(`${c.name}`),
        connectgaps: true,
        mode: 'lines+markers',
        type: 'scatter',
        line: {
          shape: "hv",
          width: 1
        },
        marker: {
          color: colors[i],
          size: 3,
        },
        selected: {
          marker: {
            color: "black",
          }
        },
      }
    })

    if (props.showDiff) {
      let diffs: Data[] =  categories.map((c, i) => {
        return {
          x: props.data.filter((e) => e.signal === c.signal).map((e) => e.time),
          y: props.data.filter((e) => e.signal === c.signal).map((e) => e.valueOriginal),
          yaxis: `y${i+1}`,
          name: t(`${c.name}`)+" original",
          connectgaps: true,
          opacity: 0.8,
          type: 'scatter',
          marker: {
            color: colorsDarkened[i],
            opacity: 0.6
          }
        }
      })
      traces = [...traces, ...diffs]
    }
    setPlotData([...traces])

    const tooltipDiv: number[] = [1]

    if (hoverTooltip.current !== null) {
      d3.select(hoverTooltip.current).selectAll('.select-tooptip')
        .data(tooltipDiv)
        .enter()
        .append("div")
        .attr("class", "select-tooptip k-button k-primary")
        .style("opacity", 0);
    }

  }, [t, props.categories, props.data, props.filter, props.showDiff])

  React.useEffect(() => {
    const cat_len = props.categories.length

    const newLayout = BaseLayout
    newLayout.height = (cat_len <= 3) ? size.height-20 : (size.height/3)*cat_len-20
    newLayout.width = size.width

    // TODO Make this smoother
    newLayout.yaxis = {domain: getDomain(1, newLayout.height, cat_len)}
    newLayout.yaxis2 = {domain: getDomain(2, newLayout.height, cat_len)}
    newLayout.yaxis3 = {domain: getDomain(3, newLayout.height, cat_len)}
    newLayout.yaxis4 = {domain: getDomain(4, newLayout.height, cat_len)}
    newLayout.yaxis5 = {domain: getDomain(5, newLayout.height, cat_len)}
    newLayout.yaxis6 = {domain: getDomain(6, newLayout.height, cat_len)}
    newLayout.yaxis7 = {domain: getDomain(7, newLayout.height, cat_len)}
    newLayout.yaxis8 = {domain: getDomain(8, newLayout.height, cat_len)}
    newLayout.yaxis9 = {domain: getDomain(9, newLayout.height, cat_len)}
    setLayout({...newLayout})
    }, [size.height, size.width, props.categories, BaseLayout])

  const graphConfig: Partial<Config> = {
    modeBarButtonsToRemove: ['toImage', "pan3d"],
    scrollZoom: true,
    displaylogo: false,
    responsive: true,
    displayModeBar: true
  }

  const updateGraph = (figData: Figure) => {
    setLayout(figData.layout)
  }

  const getPointFromSelection = (curveNumber: number, xPos: Datum, yPos: Datum) => {
    const possiblePoints = (props.data
        .filter((e) => e.signal === props.categories[curveNumber].signal && e.time === xPos)
    )
    return possiblePoints.map(e => {
      const found = props.filter.find((f) => f.signal === e.signal && f.position === e.pos)
      if (found) {
        return {...e, value: found.value, masked: found.masked}
      }
      return {...e, value: e.valueOriginal, masked: false}
    }).filter((e) => !e.masked).find((e) => e.value === yPos)
  }

  const onClick = (event: Readonly<PlotMouseEvent>) => {
    setSelectedPoints([])

    if (event.points[0] === null) {
      return
    }
    const pointClicked = getPointFromSelection(event.points[0].curveNumber % props.categories.length, event.points[0].x, event.points[0].y)

    if (pointClicked) {
      props.onClick(event.points[0].curveNumber % props.categories.length, pointClicked.pos)
    }
  }

  const onSelected = (event: Readonly<PlotSelectionEvent>) => {
    if (!event || !event.points || event.points[0] === null) {
      setSelectedPoints([])
      return
    }

    const pointCollection = event.points.map((point, index) => {
      let newPoint = getPointFromSelection(point.curveNumber % props.categories.length, point.x, point.y)
      if (newPoint && index > 2 && event.points[index].x === event.points[index-3].x && event.points[index].y === event.points[index-3].y) {
        return {...newPoint, pos: newPoint.pos + 3}
      }
      if (newPoint && index > 1 && event.points[index].x === event.points[index-2].x && event.points[index].y === event.points[index-2].y) {
        return {...newPoint, pos: newPoint.pos + 2}
      }
      if (newPoint && index > 0 && event.points[index].x === event.points[index-1].x && event.points[index].y === event.points[index-1].y) {
        return {...newPoint, pos: newPoint.pos + 1}
      }

      return newPoint
    })

    if (pointCollection && pointCollection.length >= 0) {
      const temp = pointCollection.map((e) => {
        if (e === undefined){
          return {time: -1, pos: -1, signal: -1, valueOriginal: -1}
        }
        return {
          time: e.time,
          pos: e.pos,
          signal: e.signal,
          valueOriginal: e.valueOriginal
        }
      }).filter(e => e.time !== -1)
      setSelectedPoints(temp)
    }
  }

  useEffect(() => {
    if (hoverTooltip.current !== null) {
      if (selectedPoints && selectedPoints.length > 0) {
        d3.select(hoverTooltip.current).selectAll('.select-tooptip').transition()
          .duration(100)
          .style("opacity", 1);
        d3.select(hoverTooltip.current)
          .selectAll('.select-tooptip')
          .html(t('project.editColumn.maskValues'))
          .style("left", (size.width-100) + "px")
          .style("top", (size.height+50) + "px")
          .on("click", (event: any, d: any) => {
            props.onGraphMaskValues(selectedPoints)
            setSelectedPoints([])
          })
      } else {
        d3.select(hoverTooltip.current).selectAll('.select-tooptip').transition()
          .duration(100)
          .style("opacity", 0);
      }

    }
  }, [t, props, size.height, size.width, selectedPoints])

  return (
    <div className="kserv-column-graph-svg noselect" ref={measureRef}>
      <Plot
        data={plotData}
        layout={layout}
        frames={[]}
        config={graphConfig}
        onInitialized={(figure) => updateGraph(figure)}
        onUpdate={(figure) => updateGraph(figure)}
        onClick={(e) => onClick(e)}
        onSelected={(e) => onSelected(e)}
      />
      <div ref={hoverTooltip} />

    </div>
  )
}