import {loadMessages} from "@progress/kendo-react-intl";
import seMessages from './kendo-sv.json';

export enum ApplicationLanguage {
  english = "en-GB",
  swedish = "sv-SE"
}

export interface LanguageState {
  language: ApplicationLanguage;
  text : string
}

export const supportedLanguages: LanguageState[] = [
  {
    language: ApplicationLanguage.english,
    text: "English"
  },
  {
    language: ApplicationLanguage.swedish,
    text: "Svenska"
  }];

// Must load kendo-ui specific translation file
loadMessages(seMessages, ApplicationLanguage.swedish);