import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {exportSelectors} from "../../../store/exportSlice";
import {Document, Page, pdfjs} from "react-pdf";
import {ProjectId} from "../../../backend/model";
import {LoadingPanel} from "../../../components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface PDFViewerProps {
    id: ProjectId;
    column: string;
    children?: React.ReactNode
}

export const PDFViewer: React.FunctionComponent<PDFViewerProps> = ({id, column, children}) => {

    const {t} = useTranslation()

    const file = useSelector(exportSelectors.selectCachePDF(column))
    const dataLoading = useSelector(exportSelectors.selectByIdRequestPending(id))

    const [data, setData] = React.useState<{ col_name: string, pdf: string } | null>(file ? file : null)
    const [showPdf, setShowPDF] = React.useState<boolean>(false)

    useEffect(() => {
        if (file) {
            if (file.pdf !== "") {
                setData(file)
            } else {
                setData(null)
            }
            setShowPDF(true)
        } else {
            setData(null)
            setShowPDF(true)
        }
    }, [file])

    const divName = "kserv-pdf-div"

    const content =
        showPdf ?
            !!data ? (
                <div className={divName}>
                    <Document file={data.pdf}>
                        <Page pageNumber={1}/>
                    </Document>
                    {children}
                </div>
            ) : (
                <p>{t("project.details.noPDFPreview")}</p>
            ) : (
                <p>{t("common.fetching")}</p>
            )

    return (
        <div>
            {content}
            {dataLoading && <LoadingPanel name={divName}/>}
        </div>
    )
}