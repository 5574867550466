import React, {useEffect} from "react";
import {ProjectId} from "../../../backend/model";
import {Map} from "./map";
import {useDispatch, useSelector} from "react-redux";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {AppDispatch} from "../../../store";
import "./style.scss"
import {CompositeFilterDescriptor, filterBy} from "@progress/kendo-data-query";
import {MapFilterBox} from "./filter-box";
import {useTranslation} from "react-i18next";
import {
    Card,
    CardBody,
    CardTitle,
    Splitter,
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab
} from "@progress/kendo-react-layout";
import {ColumnHistoryGrid} from "../../../components";
import {SplitterPaneProps} from "@progress/kendo-react-layout/dist/npm/splitter/SplitterPane";
import {useHistory} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import {selectShowMapObstacles} from "../../../store/applicationSlice";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {ColumnStateEnum} from "../../../backend/projectStatus";
import {ColumnReportPreview} from "../single-column-page/column-report-preview";

const RTree = require("rtree");

interface MapProps {
    id: ProjectId;
    selectedColumnName: string;
    selectSingleColumnPage: (title: string) => void;
}

export const defaultStateValue = "project.details.selectState"
export const defaultDrillDiameterValue = "project.details.selectDiameter"

export const ProjectMapPage: React.FunctionComponent<MapProps> = ({id, selectSingleColumnPage, selectedColumnName}) => {

    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation()
    const history = useHistory();

    const project = useSelector(kRegProjectDetailsSelectors.selectProject(id))
    const scale = useSelector(kRegProjectDetailsSelectors.selectMapScale());
    const map = useSelector(kRegProjectDetailsSelectors.selectMapData);

    const x = useSelector(kRegProjectDetailsSelectors.selectMapX());
    const y = useSelector(kRegProjectDetailsSelectors.selectMapY());
    const updateRef = useSelector(kRegProjectDetailsSelectors.selectColumnStatusRef());
    const showMapObstacles = useSelector(selectShowMapObstacles);

    const initPanes = [
        {
            resizable: true
        },
        {
            min: "32%",
            size: "45%",
        },
        {
            size: "38%",
            resizable: true,
        }
    ]

    const [panes, setPanes] = React.useState<SplitterPaneProps[]>(initPanes)
    const [filter, setFilter] = React.useState({
        drill: t(`${defaultDrillDiameterValue}`),
        state: t(`${defaultStateValue}`),
        column: ""
    })
    const [tab, setTab] = React.useState(0)

    const doRefresh = React.useCallback(() => {
        dispatch(kRegProjectDetailsActions.getUpdate(id, updateRef ? updateRef : undefined))
            .catch(() => {
            })
    }, [dispatch, id, updateRef])

    useEffect(() => {
        const interval = setInterval(() => {
            dispatch(kRegProjectDetailsActions.getUpdate(id, updateRef ? updateRef : undefined))
                .catch(() => {
                })
        }, 30000);
        return () => clearInterval(interval);
    }, [dispatch, id, updateRef]);

    const previewColumn = React.useMemo(() => {
        const column = map.columns.find((e) => e.name === selectedColumnName)
        if (column && column.status !== ColumnStateEnum.columnStateUnprocessed
            && column.status !== ColumnStateEnum.columnStateFailed) {
            return selectedColumnName
        }
        return ""
    }, [map.columns, selectedColumnName])

    const getFilters = React.useCallback(() => {
        const filters: CompositeFilterDescriptor = {logic: 'and', filters: []}
        filters.filters.push(...filters.filters,
            {
                value: filter.column ? filter.column : "",
                field: 'name',
                operator: 'startswith',
                ignoreCase: true
            })

        if (filter.state !== t(`${defaultStateValue}`)) {
            filters.filters.push(...filters.filters,
                {
                    value: filter.state ? filter.state : "",
                    field: 'status',
                    operator: 'eq',
                    ignoreCase: true
                })
        }

        if (filter.drill !== t(`${defaultDrillDiameterValue}`)) {
            filters.filters.push(...filters.filters,
                {
                    value: filter.drill,
                    field: 'drill_diameter',
                    operator: 'eq',
                    ignoreCase: true
                })
        }
        return filters
    }, [filter.column, filter.drill, filter.state, t])


    const getFilteredColumns = React.useMemo(() => {
        return filterBy(map.columns, getFilters())
    }, [getFilters, map.columns])


    const getRTRee = React.useMemo(() => {
        const rtree = RTree(10)
        getFilteredColumns.forEach((c, i) => {
            rtree.insert(
                {
                    x: c.location[0] - c.drill_diameter / 1000 / 2,
                    y: c.location[1] - c.drill_diameter / 1000 / 2,
                    w: c.drill_diameter / 1000,
                    h: c.drill_diameter / 1000
                } as any, i as any)
        })
        return rtree

    }, [getFilteredColumns])

    const selectColumn = (name: string) => {
        history.push(`/project/kreg/details/${id}/map?c=${name}`);
    }

    const getColumnHistory = React.useCallback((name: string) => {
        doRefresh()
        dispatch(kRegProjectDetailsActions.getColumnHistory(id, name))
    }, [dispatch, doRefresh, id])

    const selectedColumn = map.columns ? map.columns.find((e) => e.name === selectedColumnName) : undefined

    useEffect(() => {
        if (selectedColumn) {
            dispatch(kRegProjectDetailsActions.setMapPos({
                x: selectedColumn.location[0],
                y: selectedColumn.location[1]
            }))
        }
    }, [dispatch, selectedColumn])

    const handleSelect = (e: TabStripSelectEventArguments) => {
        setTab(e.selected)
    }


    const columnDetailsCard = (
        <Card className="kserv-drawing-map-details">
            <CardBody>
                <CardTitle className="kserv-card-title kserv-column-details-card-header">
                    {selectedColumn ? <span/> : <span/>}
                    {selectedColumn ? selectedColumn.name : t("project.columnDetails.noColumnSelected")}
                    {selectedColumn ?
                        <Button themeColor="primary" className="span-first-child-unhack"
                                onClick={() => selectSingleColumnPage(selectedColumn ? selectedColumn.name.toString() : "")}>
                            <FontAwesomeIcon icon={faInfo}/>
                        </Button> : <span/>}
                </CardTitle>
                <TabStrip selected={tab} onSelect={(e) => handleSelect(e)} keepTabsMounted={true}>
                    <TabStripTab title={t("project.columnDetails.annotations")}>
                        <ColumnHistoryGrid id={id}
                                           selectedColumn={selectedColumn}
                                           getColumnHistory={getColumnHistory}
                        />
                    </TabStripTab>
                    {project &&
                        <TabStripTab title="PDF">
                            <ColumnReportPreview project={project}
                                                 previewColumn={previewColumn}/>
                        </TabStripTab>
                    }
                </TabStrip>
            </CardBody>
        </Card>
    )

    return (
        <Splitter panes={panes}
                  orientation="horizontal"
                  onChange={(e) => setPanes(e.newState)}
        >
            <MapFilterBox columns={map.columns}
                          selectedColumn={selectedColumn}
                          selectColumn={selectColumn}
                          setMapFilter={setFilter}
            />
            <Map x={x}
                 y={y}
                 bearing={0}
                 scale={scale}
                 mapDrawing={{...map, rtree: getRTRee, columns: getFilteredColumns}}
                 selectedColumn={selectedColumn}
                 selectColumn={selectColumn}
                 onRefresh={doRefresh}
                 showMapObstacles={showMapObstacles}
            />
            <div className="kserv-drawing-map-details-holder">
                {columnDetailsCard}
            </div>
        </Splitter>
    )
}

