import React from "react";
import {Dialog} from "@progress/kendo-react-dialogs";
import {EditColumnLog, ProjectId} from "../../backend/model";
import {ColumnSpec} from "../../backend/drawing";
import {ColumnHistoryEntry} from "../../backend/column_log";
import {useDispatch, useSelector} from "react-redux";
// import {RootState} from "../../store";
import {DropDownList, MultiColumnComboBox,  ComboBoxFilterChangeEvent} from "@progress/kendo-react-dropdowns";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import {useTranslation} from "react-i18next";
import {Card, CardActions, CardBody} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox, TextArea} from "@progress/kendo-react-inputs";
import {selectLoggedInUser} from "../../store/loginSlice";
import {kRegProjectDetailsSelectors, kRegProjectDetailsActions} from "../../store/kRegProjectDetailsSlice";


export interface EditColomnLogProps {
    entry: ColumnHistoryEntry
    onClose: (updated: boolean) => void
}

const distance = (a: ColumnSpec, b: ColumnSpec) => {
    return Math.sqrt(Math.pow(a.location[0] - b.location[0], 2) + Math.pow(a.location[1] - b.location[1], 2))
}

export const EditColumnLogDialog: React.FunctionComponent<EditColomnLogProps> = ({entry, onClose}) => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const user = useSelector(selectLoggedInUser)
    const mapData = useSelector(kRegProjectDetailsSelectors.selectMapData);
    const columns = mapData.columns
    const thisColumn = columns.find(c => c.name === entry.column_name)

    const [sortByLength, setSortByLength] = React.useState(false)

    const availableColumns = React.useMemo(() => thisColumn === undefined ? [] : columns
        .map(c => ({name: c.name, dist: distance(c, thisColumn)}))
        .sort((a, b) => {
            return sortByLength ? a.dist - b.dist : a.name.localeCompare(b.name)
        })
    , [columns, sortByLength, thisColumn])

    const data = availableColumns.map(n => ({
        name: n.name,
        dist: n.dist === 0 ? "-" : `${n.dist.toFixed(2)}m`
    }))

    const [selectedColumn, setSelectedColumn] = React.useState(thisColumn && {
        name: thisColumn.name,
        dist: "-"
    });
    const [status, setStatus] = React.useState(entry.state)
    const [note, setNote] = React.useState("")

    const [filter, setFilter] = React.useState<FilterDescriptor>();

    const handleFilterChange = (event: ComboBoxFilterChangeEvent) => {
        if (event) {
            setFilter(event.filter);
        }
    };

    const canSend = thisColumn
        && ((entry.state !== status) || (thisColumn.name !== selectedColumn?.name))
        && note !== ""

    const send = () => {
        if (selectedColumn && status) {
            const editColumnLog: EditColumnLog = {
                hash: entry.hash,
                project_id: entry.project_id,
                old_column_name: entry.column_name,
                new_column_name: selectedColumn.name,
                new_state: status,
                annotation: {
                    depth: 0,
                    operator: user?.name || "",
                    note: note
                }
            };
            dispatch(kRegProjectDetailsActions.editLog(editColumnLog))
            onClose(true)
        }
    }

    let columnsLabel = t("project.details.columnSpec");
    return (
        <Dialog title={t("project.details.editLog")} onClose={() => onClose(false)}>
            <Card>
                <CardBody className="kreg-column-management-edit-dialog">
                    <MultiColumnComboBox data={filter ? filterBy(data, filter) : data}
                                         columns={[
                                             {field: "name", header: columnsLabel, width: "100px"},
                                             {field: "dist", header: t("project.details.distance"), width: "80px"}
                                         ]}
                                         value={selectedColumn}
                                         onChange={e => setSelectedColumn(e.value)}
                                         textField="name"
                                         filterable={true}
                                         onFilterChange={handleFilterChange}
                                         label={columnsLabel}
                    />
                    <Checkbox label={t("project.details.sortByDist")} labelPlacement="before"
                              value={sortByLength}
                              onChange={(e) => setSortByLength(e.value)}
                    />
                    <DropDownList data={["completed", "aborted", "failed"]}
                                  value={status}
                                  onChange={e => setStatus(e.value)}
                                  label="Status"
                    />
                    <TextArea value={note}
                              onChange={e => setNote(e.value as string)}
                              rows={3}
                              placeholder={t("project.details.comment")}
                    />
                </CardBody>
                <CardActions className="kreg-column-management-edit-dialog-actions">
                    <Button onClick={() => onClose(false)}>{t("common.cancel")}</Button>
                    <Button onClick={send} disabled={!canSend}>{t("common.update")}</Button>
                </CardActions>
            </Card>
        </Dialog>
    )
}