import React from "react";
import {ProjectId} from "../../../backend/model";
import "./style.scss"
import {ColumnStatusChart} from "./column-status-chart";
import {ProjectSummaryCard} from "./project-summary";
import {DrillLengthChart} from "./drill-length-chart";
import {DrillWeekChart} from "./drill-week-chart";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import {useTranslation} from "react-i18next";
import {kRegSummaryActions, kRegSummarySelectors} from "../../../store/kRegSummarySlice";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";

interface ProjectDashboardPageProps {
  id: ProjectId;
}

export const KRegProjectDashboardPage:React.FunctionComponent<ProjectDashboardPageProps> = ({id}) => {

  const project = useSelector(kRegProjectDetailsSelectors.selectProject(id));

  const {t} = useTranslation()
  const dispatch: AppDispatch = useDispatch();

  const fetchPending = useSelector(kRegProjectDetailsSelectors.selectByIdRequestPending(id))
  const summary = useSelector(kRegSummarySelectors.selectProjectSummaryById(id))

  React.useEffect(() => {
    dispatch(kRegProjectDetailsActions.fetchProject(id))
      .then(() => {
        dispatch(kRegSummaryActions.getKRegProjectDashboard(id))
          .catch(() => {})
      })
      .catch(() => {})
    return (() => {})
  }, [id, dispatch])

  return (
    <div className="kserv-content">
      {!fetchPending ?
      <div className="kserv-dashboard">
        <ProjectSummaryCard data={summary} project={project}/>
        <ColumnStatusChart data={summary}/>
        <DrillLengthChart data={summary}/>
        <DrillWeekChart data={summary}/>
      </div>
      :
      <p>{t("common.fetching")}</p>
      }
    </div>
  )
}