import React from "react";
import {KRegColumnReportSummary} from "../../../backend/column_log";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardHeader, CardTitle} from "@progress/kendo-react-layout";
import {ColumnStateEnum} from "../../../backend/projectStatus";

export interface RangeStatisticsProps {
    data: KRegColumnReportSummary[]
}

export const RangeStatistics: React.FunctionComponent<RangeStatisticsProps> = ({data}) => {
    const {t} = useTranslation()

    const stats = data.reduce((stats, col) => ({
        max_drill_depth: stats.max_drill_depth + col.max_drill_depth,
        empty_length: stats.empty_length + col.dry_drilling,
        stabilized_length: stats.stabilized_length + col.stabilized_length,
        total_binder_weight: stats.total_binder_weight + col.total_binder_weight,
        avg_binder_content: stats.avg_binder_content + (col.avg_binder_content * col.stabilized_length),
        avg_binder_content_cube: stats.avg_binder_content_cube + (col.binder_content_per_cube_m * col.stabilized_length),
        avg_rpm: stats.avg_rpm + (col.avg_rpm * col.stabilized_length),
        completed: stats.completed + (col.state === ColumnStateEnum.columnStateCompleted ? 1 : 0),
        aborted: stats.aborted + (col.state === ColumnStateEnum.columnStateAborted ? 1 : 0),
        failed: stats.failed + (col.state === ColumnStateEnum.columnStateFailed ? 1 : 0)
    }), {max_drill_depth: 0, empty_length: 0, stabilized_length: 0, total_binder_weight: 0, avg_binder_content: 0, avg_binder_content_cube: 0, avg_rpm: 0, completed: 0, aborted: 0, failed: 0})


    return (
        <Card className="kserv-report-categories-card">
            <CardHeader>
                <CardTitle>{t("project.details.rangeStatistics")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="kserv-range-statistics">
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.drilledLength")}</span>
                        <span className="range-statistics-text">{stats.max_drill_depth.toFixed(1)} m</span>
                    </div>
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.emptyLength")}</span>
                        <span className="range-statistics-text">{stats.empty_length.toFixed(1)} m</span>
                    </div>
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.stabilizedLength")}</span>
                        <span className="range-statistics-text">{stats.stabilized_length.toFixed(1)} m</span>
                    </div>
                    <div className="range-statistics-entry w-m">
                        <span className="range-statistics-title">{t("project.details.binderWeight")}</span>
                        <span className="range-statistics-text">{(stats.total_binder_weight).toFixed(0)} kg</span>
                    </div>
                    <div className="range-statistics-entry w-l">
                        <span className="range-statistics-title">{t("project.details.avgBinderContent")}</span>
                        <span className="range-statistics-text">{(stats.avg_binder_content / stats.stabilized_length).toFixed(2)} kg/m</span>
                    </div>
                    <div className="range-statistics-entry w-l">
                        <span className="range-statistics-title">{t("project.details.avgBinderContent")}</span>
                        <span className="range-statistics-text">{(stats.avg_binder_content_cube / stats.stabilized_length).toFixed(2)} kg/m3</span>
                    </div>
                    <div className="range-statistics-entry w-l">
                        <span className="range-statistics-title">{t("project.details.avgRotation")}</span>
                        <span className="range-statistics-text">{(stats.avg_rpm / stats.stabilized_length).toFixed(2)} rpm</span>
                    </div>
                    <div className="range-statistics-entry w-s">
                        <span className="range-statistics-title">{t("columnState.installed")}</span>
                        <span className="range-statistics-text">{stats.completed}</span>
                    </div>
                    <div className="range-statistics-entry w-s">
                        <span className="range-statistics-title">{t("columnState.aborted")}</span>
                        <span className="range-statistics-text">{stats.aborted}</span>
                    </div>
                    <div className="range-statistics-entry w-s">
                        <span className="range-statistics-title">{t("columnState.failed")}</span>
                        <span className="range-statistics-text">{stats.failed}</span>
                    </div>

                </div>
            </CardBody>
        </Card>
    )
}