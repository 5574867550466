import React, {useEffect} from "react";
import {Grid, GridDetailRowProps, GridNoRecords} from "@progress/kendo-react-grid";
import {useTranslation} from "react-i18next";
import {ColumnAnnotation, ColumnHistoryEntry} from "../../backend/column_log";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {ColumnSpecWithStatus} from "../../backend/projectStatus";
import {useSelector} from "react-redux";
import {kRegProjectDetailsSelectors} from "../../store/kRegProjectDetailsSlice";
import moment from "moment";
import {LoadingPanel} from "../loading-panel";
import {ProjectId} from "../../backend/model";
import {EditDrainLogDialog} from "./edit-drain-log";
import {EditColumnLogDialog} from "./edit-column-log-dialog";

const LogDetails: React.FunctionComponent<GridDetailRowProps> = ({dataItem}) => {
    const {t} = useTranslation();
    const annotation = dataItem.annotation as ColumnAnnotation;
    const data = [];
    const numAnnotations = annotation.depth === null ? 0 : annotation.depth.length;
    for (let i = 0; i < numAnnotations; i++) {
        data.push({
            depth: annotation.depth && annotation.depth[i].toFixed(1) + "m",
            operator: annotation.operator && annotation.operator[i],
            note: annotation.note && annotation.note[i]
        });
    }
    return (
        <Grid data={data} className="annotation-list">
            <GridNoRecords>
                {t("project.columnDetails.noAnnotation")}
            </GridNoRecords>
            <Column field="depth" title={t("project.columnDetails.depth")} width={65}/>
            <Column field="operator" title={t("project.columnDetails.operator")} width={80}/>
            <Column field="note" title={t("project.columnDetails.note")}/>
        </Grid>
    );
};

interface ColumnHistoryGridProps {
    id: ProjectId;
    selectedColumn?: ColumnSpecWithStatus;
    getColumnHistory: (name: string) => void;
}

export const ColumnHistoryGrid: React.FunctionComponent<ColumnHistoryGridProps> = ({
                                                                                       id,
                                                                                       selectedColumn,
                                                                                       getColumnHistory
                                                                                   }) => {

    const column = useSelector(kRegProjectDetailsSelectors.selectColumnHistoryEntry())
    const dataLoading = useSelector((kRegProjectDetailsSelectors.selectByIdRequestPending(id)))
    const [editColumnLog, setEditColumnLog] = React.useState<ColumnHistoryEntry | undefined>(undefined)
    const {t} = useTranslation()

    useEffect(() => {
        if (!selectedColumn) {
            return
        }
        getColumnHistory(selectedColumn.name)
        return (() => {
        })
    }, [getColumnHistory, selectedColumn])


    const dataSorted = column ? column.length > 0 ?
        [...column].sort((a, b) => moment(a.start_time).unix() - moment(b.start_time).unix())
        : column : column

    const gridData = dataSorted.length > 0 ? dataSorted
        .map((e) => {
                return {
                    column_bottom: e.column_stats.column_bottom.toFixed(2) + "m",
                    column_top: e.column_stats.column_top.toFixed(2) + "m",
                    date: moment(e.start_time).format("YYYY-MM-DD"),
                    start_time: moment(e.start_time).format("HH:mm"),
                    completion_time: moment(e.completion_time).format("HH:mm"),
                    avg_binder_content: e.column_stats.avg_binder_content.toFixed(1),
                    status: e.state,
                    annotation: e.annotation,
                    rawEntry: e
                }
            }
        ) : []


    const gridName = "kserv-history-grid-grid"

    const onClose = (updated: boolean) => {
        setEditColumnLog(undefined);
        if (updated && selectedColumn) {
            setTimeout(() => getColumnHistory(selectedColumn.name), 100)
        }
    }

    return (
        <div className={`${selectedColumn ? "" : "kserv-hidden"}`}>
            <Grid className={gridName} data={gridData} detail={LogDetails} onRowClick={e => setEditColumnLog(e.dataItem.rawEntry)}>
                <Column field="column_bottom" width={75} title={t("project.columnDetails.bottom")}/>
                <Column field="column_top" width={75} title={t("project.columnDetails.top")}/>
                <Column field="date" width={105} title={t("project.columnDetails.date")}/>
                <Column field="start_time" width={65} title={t("project.columnDetails.startTime")}/>
                <Column field="completion_time" width={65} title={t("project.columnDetails.completionTime")}/>
                <Column field="avg_binder_content" width={60}
                        title={t("project.columnDetails.avgBinderContent")}/>
                <Column field="status" title={t("project.columnDetails.status")}/>
            </Grid>
            {dataLoading && <LoadingPanel name={gridName}/>}
            {editColumnLog && <EditColumnLogDialog entry={editColumnLog} onClose={onClose}/>}
        </div>

    )
}
