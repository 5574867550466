import { createEndpointAdapter } from "./util/endpointAdapter";
import { RootState } from ".";
import { Machine } from "../backend/model";

const adapter = createEndpointAdapter<Machine, "Machine">("machine");
const slice = adapter.getSlice();

export const machineActions = adapter.getActions(slice, api => api.machine);
export const machineSelectors = adapter.getSelectors((state: RootState) => state.machine);
export { missingMachineId } from "../backend/model";
export default slice.reducer;
