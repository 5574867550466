import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"

interface DateCellProps<R> extends GridCellProps {
  time: string
  callback?: (p: R) => void
}

export const DateCell: <T>(p: DateCellProps<T>) => React.ReactElement<DateCellProps<T>> = (p) => {

  const time = (p.time.slice(0, 19)).split('T');

  return (
    <td colSpan={p.colSpan} style={p.style} className="kserv-edit-cell">
      {time[0]} <br/>
      {time[1]}
    </td>
  )
};
