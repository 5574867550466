import React from "react";
import ReactDOM from "react-dom";
import * as moment from "moment-timezone";
import "./keller-blue-light.css";
import {initi18n} from "./i18n";

import "./index.css";
import { App } from "./App";
import { createStore } from "./store";
import { Provider } from "react-redux";
import { createRestAPI } from "./backend/api";
import {createAADAuthenticator} from "./backend/authenticator";
import { setLanguage } from "./store/applicationSlice";
import {checkLogin} from "./store/loginSlice";

moment.tz.guess(true);

const authenticator = createAADAuthenticator();

const api = createRestAPI("./api/v2.0/", authenticator);
const store = createStore({api,authenticator});

initi18n((lang) => store.dispatch(setLanguage(lang)))

store.dispatch(checkLogin())

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
