import React from "react";
import {ProjectId} from "../../../backend/model";
import {useDispatch, useSelector} from "react-redux";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {AppDispatch} from "../../../store";
import {ColumnHistoryGrid} from "../../../components";
import {
    Card,
    CardBody,
    CardTitle,
    Splitter,
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab
} from "@progress/kendo-react-layout";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {EditColumnGraph} from "./edit-column-graph";
import {ColumnFilter} from "../../../backend/column_log";
import {kRegColumnActions} from "../../../store/kRegColumnSlice";
import {SplitterPaneProps} from "@progress/kendo-react-layout/dist/npm/splitter/SplitterPane";
import {ColumnStateEnum} from "../../../backend/projectStatus";
import {ColumnReportPreview} from "./column-report-preview";

interface SingleColumnProps {
    id: ProjectId;
    column: string
}

export const SingleColumnPage: React.FunctionComponent<SingleColumnProps> = ({id, column}) => {

    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation()

    const initPanes = [{size: "65%", resizable: true}, {}]

    const [panes, setPanes] = React.useState<SplitterPaneProps[]>(initPanes)

    const [logFilter, setLogFilter] = React.useState<ColumnFilter | undefined>(undefined)
    const [tab, setTab] = React.useState(0)

    const project = useSelector(kRegProjectDetailsSelectors.selectProject(id))
    const selectedColumn = useSelector(kRegProjectDetailsSelectors.selectMapData).columns.find(c => c.name === column)

    const hasFillData = React.useMemo(() => {
        return selectedColumn
            && selectedColumn.status !== ColumnStateEnum.columnStateUnprocessed
            && selectedColumn.status !== ColumnStateEnum.columnStateFailed;
    }, [selectedColumn])

    React.useEffect(() => {
        if (hasFillData) {
            dispatch(kRegColumnActions.clearColumnRawData())
            dispatch(kRegColumnActions.fetchColumnRawData(id, column))
        }

    }, [dispatch, id, column, selectedColumn, hasFillData])

    const getColumnHistory = React.useCallback((name: string) => {
        dispatch(kRegProjectDetailsActions.getColumnHistory(id, name))
    }, [dispatch, id])

    const handleTabSelect = (e: TabStripSelectEventArguments) => {
        setTab(e.selected)
    }

    return (
        <div className="kserv-content">
            <div className="kserv-tab-content">
                <Splitter panes={panes}
                          orientation="horizontal"
                          onChange={(e) => setPanes(e.newState)}
                >
                    <TabStrip selected={tab} onSelect={handleTabSelect}>
                        <TabStripTab title="Comment">
                            <div className="kserv-drawing-map-details-holder">
                                <Card className="kserv-drawing-map-details">
                                    <CardBody>
                                        <CardTitle
                                            className="kserv-card-title">{selectedColumn ? selectedColumn.name : t("project.columnDetails.noColumnSelected")}</CardTitle>
                                        <ColumnHistoryGrid id={id} selectedColumn={selectedColumn}
                                                           getColumnHistory={getColumnHistory}/>
                                    </CardBody>
                                </Card>
                            </div>
                        </TabStripTab>
                        <TabStripTab title="Edit">
                            <div className="kserv-drawing-map-details-holder">
                                <EditColumnGraph id={id} column={column} preview={(e) => setLogFilter(e)}/>
                            </div>
                        </TabStripTab>
                    </TabStrip>
                    <div>
                        {project && <ColumnReportPreview project={project} previewColumn={hasFillData ? column : ""}
                                                         logFilter={logFilter}/>}
                    </div>
                </Splitter>
            </div>
        </div>
    )
}