import React from "react";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {User, UserRoles} from "../../backend/model";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {userSelectors} from "../../store/userSlice";
import {AddDialog} from "../../components";

interface UserAddDialogProps {
  itemInEdit: User | any,
  setItemInEdit: (value: User | any) => void,
  save: (dataItem: User) => void
  cancel: () => void
}

export const UserAddDialog: React.FunctionComponent<UserAddDialogProps> = (props) => {
  const postPending = useSelector(userSelectors.selectPostRequestPending());

  const {t} = useTranslation();

  const regex_user = "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{1,}$"
  const regex_email = "\\S+@\\S+\\.\\S+"

  const updateUser = (u: Partial<User>) => props.setItemInEdit((p: any) => ({...p, ...u}));

  const saveItem = () => {
    if (props.itemInEdit) {
      props.save(props.itemInEdit);
    }
  };

  const adminNameValidationMessage = t("admin.fieldEmpty");

  const doValidate = () => {
    if (props.itemInEdit) {
      return postPending
        || props.itemInEdit.name === ""
        || props.itemInEdit.name.trim() === ""
        || !props.itemInEdit.name.match(regex_user)
        || props.itemInEdit.email === ""
        || props.itemInEdit.email.trim() === ""
        || !props.itemInEdit.email.match(regex_email)
    }
    return false
  }


  return (
    <AddDialog save={saveItem}
               cancel={props.cancel}
               validate={doValidate}>
      <div className="kserv-dialog-container">
        <div className="kserv-dialog-field">
          <legend className="kserv-dialog-legend">{t("customer.addCustomer")}</legend>
          <div className="kserv-dialog-item">
            <Input
              value={props.itemInEdit.name}
              autoFocus
              label={t("admin.name")}
              pattern={regex_user}
              validationMessage={adminNameValidationMessage}
              onChange={e => {
                const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                updateUser({name: v})
              }}
            />
          </div>
          <div className="kserv-dialog-item">
            <Input
              value={props.itemInEdit.email}
              label={t("admin.email")}
              pattern={regex_email}
              onChange={e => {
                const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                updateUser({email: v})
              }}
            />
          </div>
          <div className="kserv-dialog-item">
            <DropDownList
              data={[...Object.values(UserRoles)]}
              label={t("admin.role")}
              defaultItem={Object.values(UserRoles).find(r => r === props.itemInEdit.role)}
              onChange={e => {
                const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                updateUser({role: v})
              }}/>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.isActive")}<br/>
              <Checkbox
                checked={props.itemInEdit.is_active}
                onChange={e => {
                  const v = e.value
                  updateUser({is_active: v})
                }}
              />
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("admin.dashboardAccess")}<br/>
              <Checkbox
                checked={props.itemInEdit.total_dashboard}
                onChange={e => {
                  const v = e.value
                  updateUser({total_dashboard: v})
                }}
              />
            </label>
          </div>
        </div>
      </div>
    </AddDialog>
  );
};
