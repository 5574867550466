import React from "react";
import {Customer} from "../../backend/model";
import {Input} from "@progress/kendo-react-inputs";
import {EditDialog} from "../edit-dialog";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {customerSelectors} from "../../store/customerSlice";

interface CustomerEditDialogProps {
  itemInEdit: Customer | any,
  setItemInEdit: (value: Customer|any) => void,
  onDelete: (value: Customer) => void,
  onEdit: (value: Customer) => void
}

export const CustomerEditDialog: React.FunctionComponent<CustomerEditDialogProps> = (props) => {

  const { t } = useTranslation()

  const postPending = useSelector(customerSelectors.selectPostRequestPending());
  const updateCustomer = (u: Partial<Customer>) => props.setItemInEdit((p: any) => ({ ...p, ...u }));

  const saveItem = () => {
    if (props.itemInEdit) {
      props.onEdit(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const deleteItem = () => {
    if (props.itemInEdit) {
      props.onDelete(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const cancel = () => {
    props.setItemInEdit(undefined);
  };

  const doValidate = () => {
    const inputOk = props.itemInEdit ? (props.itemInEdit.name === "" || props.itemInEdit.name.trim() === "") : false;
    return postPending || inputOk
  };

  return (
    <EditDialog save={saveItem}
                validate={doValidate}
                delete={deleteItem}
                cancel={cancel}>
      <div className="kserv-dialog-container">
        <div className="kserv-dialog-field">
          <legend className="kserv-dialog-legend">{t("customer.editCustomer")}</legend>
          <div className="kserv-dialog-item">
            <Input type="text"
                   label={t("customer.customerName")}
                   autoFocus
                   value={props.itemInEdit.name}
                   minLength={1}
                   onChange={e => {
                     const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                     updateCustomer({name: v})
                   }}/>
          </div>
        </div>
      </div>
    </EditDialog>
  )
}
