export enum JobType {
  Reprocess = "reprocess",
  PdfReport = "pdf",
  XlsxReport = "xlsx"
}

export enum BatchJobStatus {
  BatchJobCreated = "created",
  BatchJobCompleted = "completed",
  BatchJobFailed = "failed",
  BatchJobAborted = "aborted"
}

export interface BatchJob {
  pid: number,
  job_type: JobType,
  start_time: Date,
  nbr_of_columns: number,
  processed_columns: number,
  status: BatchJobStatus,
  comment: string,
  error: string
}