import React, {ReactElement} from "react";
import {useTranslation} from "react-i18next";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {Grid, GridPageChangeEvent, GridPagerSettings, GridRowProps} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {LoadingPanel} from "../../../components";
import {DrawingUploadResponse} from "../../../backend/drawing";
import "./style.scss"

interface DrawingDifferenceProps {
  download_response: DrawingUploadResponse | undefined
  dataLoading: boolean
}


export const DrawingDifference: React.FunctionComponent<DrawingDifferenceProps> = ({download_response, dataLoading}) => {

  const {t} = useTranslation()

  const green = { backgroundColor: "rgb(55, 180, 0,0.32)" };
  const red = { backgroundColor: "rgb(243, 23, 0, 0.32)" };

  const initialSort: SortDescriptor[] = [{field: '', dir: 'desc'}];
  const pageAble: GridPagerSettings = {info: true, type: 'input', previousNext: true};

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [pageState, setPageState] = React.useState({skip: 0, take: 50});

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const rowRender = (trElement: ReactElement<HTMLTableRowElement>, props: GridRowProps ) => {
    let trProps;
    if (props.dataItem && download_response && download_response.success) {
      trProps = {style: green};
    } else {
      trProps = {style: red};
    }
    return React.cloneElement(trElement as React.ReactElement, {...trProps}, trElement.props.children);
  }

  const gridName: string = "kserv-project-drawing-difference"

  const gridData = (
    download_response ? (
      download_response.column_updates ? (
        download_response.column_updates
      ) : []
    ) : []
  )

  return (
    <>
      <Grid
        className={gridName}
        data={orderBy(gridData ? gridData: [], sort).slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        pageable={pageAble}
        scrollable={"scrollable"}
        onPageChange={onPageChange}
        rowRender={(e, r) => rowRender(e, r)}
      >
        <Column field="column_name" title={t("project.details.name")}/>
        <Column field="operation" title={t("project.drawing.operation")} filter={'text'} />
      </Grid>
      {dataLoading && <LoadingPanel name={gridName}/>}
    </>
  )
}
