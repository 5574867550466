import React, {Suspense} from "react";
import {HashRouter, Route, Redirect, Switch} from "react-router-dom";
import {KservNotification, NavigationBar} from "./components";

import {
    CustomersPage,
    CustomerPage,
    MachineAdmissionPage,
    MachinesPage,
    MachinePage,
    UserProjectsPage,
    ProjectsPage,
    KRegProjectPage,
    KDrainProjectPage,
    ProjectNewPage,
} from "./pages";

import {LocalizationProvider} from "@progress/kendo-react-intl";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useSelector} from "react-redux";
import {selectIsLoggedIn} from "./store/loginSlice";
import {selectLanguage} from "./store/applicationSlice";
import {AdminPage} from "./pages";
import {EmptyPage} from "./pages";
import {TotalDashboard} from "./pages/total-dashboard";

// Application routing is based on the HashRouter.
export const AppRouter = () => {
    // Find out which language the application is set to use.
    const currentLang = useSelector(selectLanguage);
    // If the contents of the page has not finished loading for some reason
    // we will show this div instead.
    const loadingImage = (
        <div className="kserv-loading-container">
            <FontAwesomeIcon icon={faSpinner} size="6x" spin/>
        </div>
    );

    // Render different depending on if we are logged in or not
    const isLoggedIn = useSelector(selectIsLoggedIn);

    const content = isLoggedIn ? (
        <Switch>
            <Route exact path="/" render={() => <Redirect to="/project/user"/>}/>
            <Route exact path="/customer" component={CustomersPage}/>
            <Route path="/customer/:id" component={CustomerPage}/>
            <Route exact path="/machine" component={MachinesPage}/>
            <Route exact path="/machine/admissions" component={MachineAdmissionPage}/>
            <Route path="/machine/:id" component={MachinePage}/>
            <Route exact path="/project/user" component={UserProjectsPage}/>
            <Route exact path="/project" component={ProjectsPage}/>
            <Route path="/project/kreg/details/:id/:page?/:column?" component={KRegProjectPage}/>
            <Route path="/project/kdrain/details/:id/:page?/:column?" component={KDrainProjectPage}/>
            <Route exact path="/project/new" component={ProjectNewPage}/>
            <Route exact path="/dashboard" component={TotalDashboard}/>
            <Route exact path="/admin" component={AdminPage}/>
            <Route component={EmptyPage}/>
        </Switch>
    ) : (
        <div>
        </div>
    );

    return (
        <HashRouter>
            <div className="kserv-page-root">
                <Suspense fallback={loadingImage}>
                    <LocalizationProvider language={currentLang}>
                        <NavigationBar/>
                        <div className="kserv-page-body">
                            <KservNotification/>
                            {content}
                        </div>
                    </LocalizationProvider>
                </Suspense>
            </div>
        </HashRouter>
    );
};
