import React, {useEffect, useState} from "react";
import "../style.scss"
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {
    Grid,
    GridColumn as Column,
    GridPageChangeEvent,
    GridPagerSettings,
    GridRowClickEvent,
    GridToolbar
} from "@progress/kendo-react-grid";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {KRegProject, ProjectId, ProjectRole} from "../../../backend/model";
import {
    DateCell,
    DropdownFilterCell,
    ExportCategoriesI,
    ExportCell,
    ExportColumnCategories,
    ExportGraphCategories,
    KRegExportProductionCategories,
    KRegDefaultProductionCategories,
    KservChipList,
    KservDateRangePicker,
    LoadingPanel,
    RefreshButton,
    ToggleButton,
    rangeToStartTimeFilter
} from "../../../components";
import {ExportFileInterface, ExportFileType, exportSelectors} from "../../../store/exportSlice";
import {addWeeks, getDate} from "@progress/kendo-date-math";
import moment from "moment";
import {filterOperators} from "../../../components/grid-filter-operations/GridFilterOperators";
import {ColumnFilter, KRegColumnReportSummary} from "../../../backend/column_log";
import {DateRange} from "../../../components/date-range-picker/kserv-date-range-picker";
import {ColumnStateEnum} from "../../../backend/projectStatus";
import {AppDispatch} from "../../../store";
import {UnitCell} from "../../../components/custom-cell/unit-cell";
import {Button} from "@progress/kendo-react-buttons";
import {Checkbox, TextArea, TextAreaChangeEvent} from "@progress/kendo-react-inputs";
import {Card, CardActions, CardBody, CardHeader, CardTitle, PanelBar, PanelBarItem} from '@progress/kendo-react-layout';
import {faFileDownload, faFileExcel, faFilePdf, faFilter} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {RangeStatistics} from "./range-statistics";
import {applyPreferences} from "../../../components/chip-list/ExportCategories";
import {KRegProjectPreferences} from "../../../backend/preferences";

interface DownloadDialogProps {
    showDialog: boolean
    setShowDialog: (show: boolean) => void
    callback: (t: ExportFileInterface) => void
}

const DownloadDialog: React.FunctionComponent<DownloadDialogProps> = (props) => {

    const {t} = useTranslation()
    const [comment, setComment] = React.useState<string>("")

    const download = (ext: ExportFileType) => {
        props.callback({extension: ext, comment: comment})
        props.setShowDialog(false)
        setComment("")
    }

    const onTextAreaChange = (e: TextAreaChangeEvent) => {
        setComment(e.value + "")
    }

    const buttonDisabled = comment.trim() === ""

    return (<>
            {props.showDialog &&
                <Dialog className="kserv-download-dialog"
                        title={t("export.exportColumnReport")}
                        onClose={() => props.setShowDialog(false)}>
                    <div>
                        <p style={{margin: "25px", textAlign: "center"}}>
                            {t("export.addComment")}
                        </p>
                        <TextArea value={comment}
                                  onChange={onTextAreaChange}
                                  rows={3}
                        />
                    </div>
                    <DialogActionsBar>
                        <button className="k-button k-primary kserv-category-export-button"
                                disabled={buttonDisabled}
                                onClick={(_) => download(ExportFileType.XLSX)}>
                            <FontAwesomeIcon icon={faFileExcel}/>
                            {t("project.details.export")}
                        </button>
                        <button className="k-button k-primary kserv-category-export-button"
                                disabled={buttonDisabled}
                                onClick={(_) => download(ExportFileType.PDF)}>
                            <FontAwesomeIcon icon={faFilePdf}/>
                            {t("project.details.export")}
                        </button>
                    </DialogActionsBar>
                </Dialog>
            }
        </>
    )
}


interface ColumnLogGridProps {
    project: KRegProject
    data: KRegColumnReportSummary[],
    onColDownload: (proj_id: ProjectId, options: ExportFileInterface) => void,
    onColBatchDownload: (proj_id: ProjectId, options: ExportFileInterface) => void,
    onProjDownload: (proj_id: ProjectId, options: ExportFileInterface) => void,
    onRefresh: () => void,
    selectSingleColumnPage: (title: string) => void
}

export const ColumnLogGrid: React.FunctionComponent<ColumnLogGridProps> = (props) => {
    const {t} = useTranslation();
    const dispatch: AppDispatch = useDispatch();

    const projectRange = React.useMemo(() => {
        if (!props.data || props.data.length === 0) {
            return {start: addWeeks(getDate(new Date()), -1), end: getDate(new Date())}
        }
        const start_times = props.data.map(s => moment(s.start_time))
        return {
            start: getDate(start_times.reduce((a, b) => a.isBefore(b) ? a : b).toDate()),
            end: getDate(start_times.reduce((a, b) => a.isAfter(b) ? a : b).toDate())
        }
    }, [props.data])
    const [dateRange, setDateRange] = useState<DateRange>(projectRange)

    const [filter, setFilter] = useState<CompositeFilterDescriptor>({logic: "and", filters: []})

    const initialSort: SortDescriptor[] = [{field: 'start_time', dir: 'desc'}];
    const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
    const [pageState, setPageState] = React.useState({skip: 0, take: 10});
    const [showFilters, setShowFilters] = React.useState(false);
    const preferences = props.project.preferences;
    const defaultProductionCategories = applyPreferences(KRegExportProductionCategories, preferences.defaultProductionCategories || KRegDefaultProductionCategories);
    const [productionCategories, setProductionCategories] = React.useState<ExportCategoriesI[]>(defaultProductionCategories);
    const defaultProductionDashboard = preferences.defaultProductionDashboard || false
    const [productionDashboard, setProductionDashboard] = React.useState<boolean>(defaultProductionDashboard);
    const defaultColumnCategories = applyPreferences(ExportColumnCategories, preferences.defaultColumnCategories);
    const [columnCategories, setColumnCategories] = useState(defaultColumnCategories)
    const defaultGraphCategories = applyPreferences(ExportGraphCategories, preferences.defaultGraphCategories)
    const [graphCategories, setGraphCategories] = React.useState<ExportCategoriesI[]>(defaultGraphCategories);
    const defaultReplaceBRN = preferences.defaultReplaceBRN || false;
    const [replaceBRN, setReplaceBRN] = React.useState(defaultReplaceBRN)

    const updatePreferences = (preferences: KRegProjectPreferences) => {
        const project = {...props.project, preferences}
        dispatch(kRegProjectDetailsActions.putProject(project))
    }

    const saveColumnDefault = () => {
        const defaultColumnCategories = columnCategories.filter(e => e.selected).map(e => e.value)
        updatePreferences({...preferences, defaultColumnCategories})
    }

    const saveProductionDefault = () => {
        const defaultProductionCategories = productionCategories.filter(e => e.selected).map(e => e.value)
        updatePreferences({...preferences, defaultProductionCategories, defaultProductionDashboard: productionDashboard})
    }

    const saveGraphsDefault = () => {
        const defaultGraphCategories = graphCategories.filter(e => e.selected).map(e => e.value)
        updatePreferences({...preferences, defaultGraphCategories, defaultReplaceBRN: replaceBRN})
    }

    const [showBatchDownloadWindow, setShowBatchDownloadWindow] = React.useState(false);

    const [userIsAdmin, setUserIsAdmin] = React.useState(false)

    const dataLoading = useSelector(kRegProjectDetailsSelectors.selectByIdRequestPending(props.project.id))
    const exportPending = useSelector(exportSelectors.selectByIdRequestPending(props.project.id))

    useEffect(() => {
        dispatch(kRegProjectDetailsActions.getMyProjectRole(props.project.id))
            .then(role => setUserIsAdmin(role === ProjectRole.admin))
            .catch(() => {
            })
        return (() => {
        })
    }, [dispatch, props.project.id, setUserIsAdmin]);

    const onPageChange = (e: GridPageChangeEvent) => {
        setPageState(e.page)
    }

    const gridName: string = "kserv-project-column-log-grid"

    const graphs = () =>
        graphCategories
            .filter(e => e.selected)
            .map(e => e.value)
            .map(e => replaceBRN ? e.replace("graph_brn", "graph_rpm") : e)

    const exportColReport = (dataItem: KRegColumnReportSummary, type: ExportFileType, payload ?: ColumnFilter) => {
        const cat = columnCategories.filter(e => e.selected).map(e => e.value)
        props.onColDownload(props.project.id, {
            col_id: dataItem.column_id,
            extension: type,
            export: true,
            filter: filter ? filter.filters : undefined,
            categories: cat,
            graphs: graphs()
        })
    }

    const exportColReportBatch = (options: ExportFileInterface) => {
        const cat = columnCategories.filter(e => e.selected).map(e => e.value)
        props.onColBatchDownload(props.project.id, {
            ...options,
            sort: sort.length ? sort : undefined,
            filter: filter ? filter.filters : undefined,
            export: true,
            dateRange: rangeToStartTimeFilter(dateRange).filters,
            categories: cat,
            graphs: graphs()
        })
    }

    const exportProductionReport = (options: ExportFileInterface) => {
        props.onProjDownload(props.project.id, {
            ...options,
            sort: sort.length ? sort : undefined,
            filter: filter ? filter.filters : undefined,
            dateRange: rangeToStartTimeFilter(dateRange).filters,
            extension: ExportFileType.XLSX,
            dashboard: productionDashboard
        })
    }

    const onRowClick = (e: GridRowClickEvent) => {
        const data = e.dataItem.column_id
        props.selectSingleColumnPage(data ? data : "");
    };

    const gridData = filterBy(filterBy(orderBy(props.data ? props.data.map((entry) => {
        return {
            ...entry,
            total_binder_weight: parseFloat(entry.total_binder_weight.toFixed(1)),
            max_drill_depth: parseFloat(entry.max_drill_depth.toFixed(1)),
            stabilized_length: parseFloat(entry.stabilized_length.toFixed(1)),
            avg_binder_content: parseFloat(entry.avg_binder_content.toFixed(1)),
            avg_binder_content_kgm3: parseFloat(entry.binder_content_per_cube_m.toFixed(1)),
            avg_lift_coef: parseFloat(entry.avg_lift_coef.toFixed(1)),
            avg_rpm: parseFloat(entry.avg_rpm.toFixed(1)),
            rawEntry: entry
        }
    }) : [], sort), filter), rangeToStartTimeFilter(dateRange))

    const exportDisabled = (exportPending !== undefined ? exportPending : false) || !gridData.length
    const exportProductionDisabled = !gridData.length
    const exportBatchDisabled = exportProductionDisabled || !userIsAdmin


    const exportBatchButtons = (
        <Button className="kserv-category-export-button"
                themeColor="primary"
                disabled={exportBatchDisabled ? exportBatchDisabled : false}
                onClick={() => setShowBatchDownloadWindow(true)}
        >
            <FontAwesomeIcon icon={faFileDownload}/>
            {t("common.export")}
        </Button>)

    const exportReportButton = (
        <>
            <Button className="kserv-category-export-button k-button"
                    themeColor="primary"
                    disabled={exportProductionDisabled ? exportProductionDisabled : false}
                    onClick={(e) => {
                        exportProductionReport({categories: productionCategories.filter((e) => e.selected).map((e) => e.value)})
                    }}>
                <FontAwesomeIcon icon={faFileExcel}/>
                {t("common.export")}
            </Button>
        </>
    )

    const productionReportCard = (
        <Card className="kserv-report-categories-card">
            <CardHeader>
                <CardTitle>{t("export.exportProductionReport")}</CardTitle>
            </CardHeader>
            <CardBody>
                <PanelBar className="kserv-panelbar-categories">
                    <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("common.options")}>
                        <KservChipList categories={productionCategories}
                                       setCategories={setProductionCategories}
                                       onDefault={() => {
                                           setProductionCategories(defaultProductionCategories)
                                           setProductionDashboard(defaultProductionDashboard)
                                       }}
                                       onSaveDefault={saveProductionDefault}
                        >
                            <Checkbox label={t("project.includeDashboard")}
                                      value={productionDashboard}
                                      onChange={(e) => {
                                          setProductionDashboard(!productionDashboard)
                                      }}
                            />
                        </KservChipList>
                    </PanelBarItem>
                </PanelBar>
            </CardBody>
            <CardActions layout='end'>
                {exportReportButton}
            </CardActions>
        </Card>
    )



    const columnReportCard = (
        <Card className="kserv-report-categories-card">
            <CardHeader>
                <CardTitle>{t("export.exportColumnReport")}</CardTitle>
            </CardHeader>
            <CardBody>
                <PanelBar className="kserv-panelbar-categories">
                    <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("project.categories")}>
                        <KservChipList text={"export.meta"} categories={columnCategories}
                                       setCategories={setColumnCategories}
                                       onDefault={() => {
                                           setColumnCategories(defaultColumnCategories)
                                       }}
                                       onSaveDefault={saveColumnDefault}
                        />
                    </PanelBarItem>
                    <PanelBarItem expanded={true} className="kserv-panelbar-item" title={t("project.graphs")}>
                        <KservChipList text={"export.graphText"}
                                       categories={graphCategories}
                                       setCategories={setGraphCategories}
                                       onDefault={() => {
                                           setGraphCategories(defaultGraphCategories)
                                           setReplaceBRN(defaultReplaceBRN)
                                       }}
                                       onSaveDefault={saveGraphsDefault}
                        >
                            <Checkbox label={t("export.graph.rpm")}
                                      value={replaceBRN}
                                      onChange={(e) => {
                                          setReplaceBRN(!replaceBRN)
                                      }}
                            />
                        </KservChipList>
                    </PanelBarItem>
                </PanelBar>
            </CardBody>
            <CardActions layout='end'>
                {exportBatchButtons}
            </CardActions>
        </Card>
    )

    return (
        <>
            <Grid
                className={gridName}
                data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
                sortable={{
                    allowUnsort: true,
                    mode: 'multiple'
                }}
                sort={sort}
                onSortChange={(e) => setSort(e.sort)}
                filterable={showFilters}
                filter={filter}
                onFilterChange={(e) => setFilter(e.filter)}
                filterOperators={filterOperators}
                skip={pageState.skip}
                take={pageState.take}
                total={gridData.length}
                resizable
                pageable={pageAble}
                onRowClick={onRowClick}
                onPageChange={onPageChange}
            >
                <GridToolbar>
                    <div style={{display: "block", width: "100%"}}>
                        <div className="kserv-grid-toolbar-categories">
                            <div className="kserv-grid-toolbar-splitter">
                                {productionReportCard}
                            </div>
                            <div className="kserv-grid-toolbar-splitter">
                                {columnReportCard}
                            </div>
                        </div>
                        <RangeStatistics data={gridData.map(e => e.rawEntry)}/>
                        <div className="kserv-grid-toolbar">
                            <KservDateRangePicker onUpdate={setDateRange}
                                                  minDate={projectRange.start}
                                                  maxDate={projectRange.end}/>
                            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
                            <RefreshButton callback={props.onRefresh}/>
                        </div>
                    </div>
                </GridToolbar>
                <Column field="column_id" title={t("project.details.name")}/>
                <Column field="start_time" title={t("project.details.startTime")} filterable={false} format="{0:d}"
                        cell={(p) => <DateCell {...p} time={p.dataItem.start_time}/>}/>
                <Column field="max_drill_depth" title={t("project.details.drilledLength")} filter={'numeric'}/>
                <Column field="stabilized_length" title={t("project.details.stabilizedLength")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" m"}/>}/>
                <Column field="total_binder_weight" title={t("project.details.binderWeight")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" kg"}/>}/>
                <Column field="avg_binder_content" title={t("project.details.avgBinderContent")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" kg/m"}/>}/>
                <Column field="avg_binder_content_kgm3" title={t("project.details.avgBinderContent")}
                        filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" kg/m3"}/>}/>
                <Column field="avg_lift_coef" title={t("project.details.avgLiftCoEff")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" mm/rev"}/>}/>
                <Column field="avg_rpm" title={t("project.details.avgRotation")} filter={'numeric'}
                        cell={(p) => <UnitCell {...p} unit={" rpm"}/>}/>
                <Column field="machine_number" title={t("project.details.machineName")} filter={'text'}/>
                <Column field="machine_operator" title={t("project.details.operator")} filter={'text'}/>
                <Column field="state" title={t("project.details.state")} width={160}
                        filterCell={(p) => <DropdownFilterCell {...p} data={[...Object.values(ColumnStateEnum)]}
                                                               defaultItem={'All'}/>}/>
                <Column field="num_records" title={t("project.details.records")} filter={"numeric"}/>
                <Column field="export" title={t("project.details.export")} sortable={false} filterable={false}
                        cell={(p) =>
                            <ExportCell<KRegColumnReportSummary> {...p} callback={exportColReport}
                                                                 disabled={exportDisabled ? exportDisabled : p.dataItem ? p.dataItem.state === ColumnStateEnum.columnStateFailed : true}/>}/>
            </Grid>
            {dataLoading && <LoadingPanel name={gridName}/>}
            <DownloadDialog showDialog={showBatchDownloadWindow}
                            setShowDialog={setShowBatchDownloadWindow}
                            callback={exportColReportBatch}/>
        </>
    )
}
