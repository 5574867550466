import React, {useEffect} from "react";
import {AppDispatch} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {selectLoggedInUser} from "../../store/loginSlice";
import {EmptyPage} from "../empty";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import "./style.scss"
import {
  Chart, ChartCategoryAxis, ChartCategoryAxisItem, ChartLegend,
  ChartSeries, ChartSeriesItem,
  ChartTooltip,
  ChartValueAxis,
  ChartValueAxisItem, SharedTooltipContext, TooltipContext
} from "@progress/kendo-react-charts";
import {MachineProduction} from "../../backend/summary";
import {kRegSummaryActions, kRegSummarySelectors} from "../../store/kRegSummarySlice";
import {PageTitle} from "../../components";
import {kDrainSummaryActions, kDrainSummarySelectors} from "../../store/kDrainSummarySlice";


const useMonths = () => {
  const {t} = useTranslation();

  return [t("totalDashboard.month.january"),
    t("totalDashboard.month.february"),
    t("totalDashboard.month.march"),
    t("totalDashboard.month.april"),
    t("totalDashboard.month.may"),
    t("totalDashboard.month.june"),
    t("totalDashboard.month.july"),
    t("totalDashboard.month.august"),
    t("totalDashboard.month.september"),
    t("totalDashboard.month.october"),
    t("totalDashboard.month.november"),
    t("totalDashboard.month.december")];
}

function ProductionPerMachine(props: { production_days_per_machine: MachineProduction[]}) {

  const {t} = useTranslation();

  const productionTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    const category = e.hasOwnProperty('point') ? (e as TooltipContext).point.category : "";
    return (<span><b>{t("totalDashboard.productionTooltip", {category: category, data: data})}</b></span>)
  }

  const data = props.production_days_per_machine ?
    props.production_days_per_machine.length > 0 ?
      props.production_days_per_machine.map((e) => {
        return {production_days: e.production_days, machine_name: e.machine_name}
      })
    : [{production_days: 0, machine_name: ""}]
    : [{production_days: 0, machine_name: ""}]

  return (
    <Card className="kserv-dashboard-card">
    <CardBody>
      <CardTitle className="kserv-card-title">{t("totalDashboard.machineProduction")}</CardTitle>
      <Chart>
        <ChartValueAxis>
          <ChartValueAxisItem title={{text: t("totalDashboard.productionDays")}}/>
        </ChartValueAxis>
        <ChartTooltip render={productionTooltipRender}/>
        <ChartSeries>
          <ChartSeriesItem type="column"
                           spacing={0.4}
                           data={data}
                           field={"production_days"}
                           categoryField={"machine_name"}
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem labels={{rotation: 'auto'}}/>
        </ChartCategoryAxis>
      </Chart>
    </CardBody>
  </Card>);
}


function KRegMonthChar(props: { month_drilled_length: number[], month_stabilized_length: number[]}) {
  const {t} = useTranslation();
  const categories = useMonths()
  const columnTitles = [
    t("totalDashboard.totalDrilledLength"),
    t("totalDashboard.stabilizedLength")
  ]

  const monthTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    return (<span><b>{t("totalDashboard.monthTooltip", {data: data})}</b></span>)
  }

  return <Card className="kserv-dashboard-card">
    <CardBody>
      <CardTitle className="kserv-card-title">{t("totalDashboard.monthDrilled")}</CardTitle>
      <Chart style={{width: 600}}>
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem title={{text: "km"}}/>
        </ChartValueAxis>
        <ChartTooltip render={monthTooltipRender}/>
        <ChartSeries>
          <ChartSeriesItem type="column"
                           gap={2}
                           name={columnTitles[0]}
                           data={props.month_drilled_length ? props.month_drilled_length.map((e) => (e/1000).toFixed(3)) : []}
          />
          <ChartSeriesItem type="column"
                           name={columnTitles[1]}
                           data={props.month_stabilized_length ? props.month_stabilized_length.map((e) => (e/1000).toFixed(3)) : []}
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories.slice(0, props.month_drilled_length.length)} startAngle={45}
                                 labels={{rotation: "auto"}}/>
        </ChartCategoryAxis>
      </Chart>
    </CardBody>
  </Card>;
}

function KDrainDrainsPerMonth(props: { drains_per_month: number[] }) {
  const {t} = useTranslation();
  const categories = useMonths()

  const monthTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    return (<span><b>{data}</b></span>)
  }

  return <Card className="kserv-dashboard-card">
    <CardBody>
      <CardTitle className="kserv-card-title">{t("totalDashboard.drainsPerMonth")}</CardTitle>
      <Chart style={{width: 400}}>
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem title={{text: ""}}/>
        </ChartValueAxis>
        <ChartTooltip render={monthTooltipRender}/>
        <ChartSeries>
          <ChartSeriesItem type="column"
              // gap={2}
                           data={props.drains_per_month ? props.drains_per_month : []}
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories.slice(0, props.drains_per_month.length)} startAngle={45}
                                 labels={{rotation: "auto"}}/>
        </ChartCategoryAxis>
      </Chart>
    </CardBody>
  </Card>;
}

function KDrainDrilledPerMonth(props: { drilled_per_month: number[] }) {
  const {t} = useTranslation();
  const categories = useMonths()

  const monthTooltipRender = (e: TooltipContext | SharedTooltipContext) => {
    const data = e.hasOwnProperty('point') ? (e as TooltipContext).point.value : "";
    return (<span><b>{t("totalDashboard.monthTooltip", {data: data})}</b></span>)
  }

  return <Card className="kserv-dashboard-card">
    <CardBody>
      <CardTitle className="kserv-card-title">{t("totalDashboard.monthDrilled")}</CardTitle>
      <Chart style={{width: 400}}>
        <ChartLegend position="bottom" orientation="horizontal" />
        <ChartValueAxis>
          <ChartValueAxisItem title={{text: "km"}}/>
        </ChartValueAxis>
        <ChartTooltip render={monthTooltipRender}/>
        <ChartSeries>
          <ChartSeriesItem type="column"
                           // gap={2}
                           data={props.drilled_per_month ? props.drilled_per_month.map((e) => (e/1000).toFixed(3)) : []}
          />
        </ChartSeries>
        <ChartCategoryAxis>
          <ChartCategoryAxisItem categories={categories.slice(0, props.drilled_per_month.length)} startAngle={45}
                                 labels={{rotation: "auto"}}/>
        </ChartCategoryAxis>
      </Chart>
    </CardBody>
  </Card>;
}

export const TotalDashboard = () => {
  const BASE_YEAR = 2020

  const dispatch: AppDispatch = useDispatch();

  const {t} = useTranslation();

  const currentUser = useSelector(selectLoggedInUser);
  const [year, setYear] = React.useState((new Date()).getFullYear().toString())
  const kRegData = useSelector(kRegSummarySelectors.selectTotalSummary(+year));
  const kDrainData = useSelector(kDrainSummarySelectors.selectTotalSummary(+year));

  useEffect(() => {
    dispatch(kRegSummaryActions.getKRegTotalDashboard(+year))
    dispatch(kDrainSummaryActions.getKDrainTotalDashboard(+year))
  }, [dispatch, year])

  const years = React.useMemo(() => {
    let arr = []
    for(let i = BASE_YEAR; i <= (new Date()).getFullYear(); i++){
      arr.push(i)
    }
    return arr.reverse()
  }, [])

  const kreg_month_drilled_length = kRegData ? kRegData.drilled_length_chart_data.map((e) => e.total_max_drill_depth) : []
  const kreg_month_stabilized_length = kRegData ? kRegData.drilled_length_chart_data.map((e) => e.total_stabilized_length) : []
  const kdrain_month_drilled_length = kDrainData ? kDrainData.month_chart_data.map((e) => e.total_max_drill_depth) : []
  const kdrain_month_drains = kDrainData ? kDrainData.month_chart_data.map((e) => e.total_drains) : []

  const content = (
    <div>
      <div className="kserv-total-dashboard-year">
        <span className="kserv-total-dashboard-year-text">{t("totalDashboard.chooseYear")}</span>
        <DropDownList style={{minWidth: "100px", marginBottom: 5}}
                      data={years}
                      onChange={(e) => setYear(e.target.value)}
                      value={year}
        />
      </div>
      <div className="kserv-total-dashboard-machine-title">
        KReg
      </div>
      <div className="kserv-total-dashboard-cards k-card-group">
        <Card className="kserv-dashboard-card">
          <CardBody>
            <CardTitle className="kserv-card-title">{t("totalDashboard.total")}</CardTitle>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalDrilledLength")}</span> <br/>
            <span className="project-dashboard-card-text">{kRegData ? (kRegData.total_max_drill_depth/1000).toFixed(2) : "-"} km</span> <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalStabilizedLength")}</span> <br/>
            <span className="project-dashboard-card-text">{kRegData ? (kRegData.total_stabilized_length/1000).toFixed(2) : "-"} km</span>
            <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalColumns")}</span> <br/>
            <span className="project-dashboard-card-text">{kRegData ? kRegData.total_columns : "-"}</span> <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalBinderWeight")}</span> <br/>
            <span className="project-dashboard-card-text">{kRegData ? (kRegData.total_binder_weight/1000).toFixed(2) : "-"} ton</span> <br/>
          </CardBody>
        </Card>
        <Card className="kserv-dashboard-card">
          <CardBody>
            <CardTitle className="kserv-card-title">{t("totalDashboard.average")}</CardTitle>
            <span className="project-dashboard-card-title">{t("totalDashboard.stabilizedLength")}</span> <br/>
            <span
              className="project-dashboard-card-text">{kRegData ? kRegData.avg_stabilized_length.toFixed(2) : "-"} m</span>
            <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.pullRate")}</span><br/>
            <span
              className="project-dashboard-card-text">{kRegData ? kRegData.avg_pull_rate.toFixed(2) : "-"} m/min</span>
            <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.avgRPM")}</span><br/>
            <span className="project-dashboard-card-text">{kRegData ? kRegData.avg_rpm.toFixed(0) : "-"} RPM</span> <br/>
          </CardBody>
        </Card>
        <ProductionPerMachine  production_days_per_machine={kRegData ? kRegData.production_days_per_machine : []}/>
        <KRegMonthChar  month_drilled_length={kreg_month_drilled_length}
                         month_stabilized_length={kreg_month_stabilized_length}/>
      </div>
      <div className="kserv-total-dashboard-machine-title">
        KDrain
      </div>
      <div className="kserv-total-dashboard-cards k-card-group">
        <Card className="kserv-dashboard-card">
          <CardBody>
            <CardTitle className="kserv-card-title">{t("totalDashboard.total")}</CardTitle>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalDrilledLength")}</span> <br/>
            <span className="project-dashboard-card-text">{kDrainData ? (kDrainData.total_max_drill_depth/1000).toFixed(2) : "-"} km</span> <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.totalDrains")}</span> <br/>
            <span className="project-dashboard-card-text">{kDrainData ? kDrainData.total_drains : "-"}</span> <br/>
            <span className="project-dashboard-card-title">{t("totalDashboard.avgMaxDrillDepth")}</span> <br/>
            <span className="project-dashboard-card-text">{kDrainData ? (kDrainData.avg_max_drill_depth).toFixed(2) : "-"} m</span> <br/>
          </CardBody>
        </Card>
        <ProductionPerMachine  production_days_per_machine={kDrainData ? kDrainData.production_days_per_machine : []}/>
        <KDrainDrainsPerMonth drains_per_month={kdrain_month_drains} />
        <KDrainDrilledPerMonth drilled_per_month={kdrain_month_drilled_length} />
      </div>
    </div>
  )

  return (
    <div className={"center"}>
      {currentUser && currentUser.total_dashboard ? (
        <>
          <PageTitle title={t("totalDashboard.title") + " - " + year}/>
          <div className="kserv-content kserv-total-dashboard">
            {content}
          </div>
        </>
      ) : (
        <>
          <EmptyPage/>
        </>
      )}
    </div>
  )
}