import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {AutoComplete, DropDownList} from "@progress/kendo-react-dropdowns";
import {filterBy} from "@progress/kendo-data-query";
import {ColumnStateEnum, DrainSpecWithStatus} from "../../../backend/projectStatus";
import {Button} from "@progress/kendo-react-buttons";
import "./style.scss"
import {defaultStateValue} from "./page";
import {ProjectId} from "../../../backend/model";
import {useDispatch, useSelector} from "react-redux";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {AppDispatch} from "../../../store";
import {NumericTextBox} from "@progress/kendo-react-inputs";

interface DrainMapDetailsProps {
    projectId: ProjectId,
    columns: DrainSpecWithStatus[],
    selectedColumn?: DrainSpecWithStatus,
    selectColumn: (name: string) => void
    setMapFilter: (newFilter: { state: string, column: string }) => void,
}

export const DrainMapDetails: React.FunctionComponent<DrainMapDetailsProps> = ({
                                                                                   projectId,
                                                                                   columns,
                                                                                   selectedColumn,
                                                                                   selectColumn,
                                                                                   setMapFilter
                                                                               }) => {

    const {t} = useTranslation()
    const dispatch: AppDispatch = useDispatch();

    const [data, setData] = React.useState<string>("")
    const currentProject = useSelector(kDrainProjectDetailsSelectors.selectProject(projectId))
    const [drainDiameterDb, setDrainDiameterDb] = React.useState(0)
    const drainDiameter = useSelector(kDrainProjectDetailsSelectors.selectDrainDiameter())


    useEffect(() => {
        if (currentProject !== undefined) {
            const diameter = currentProject.settings.drain_diameter;
            setDrainDiameterDb(diameter)
            dispatch(kDrainProjectDetailsActions.setDrainDiameter(diameter))
        }
    }, [currentProject, dispatch])

    const drainDiameterChanged = drainDiameter !== drainDiameterDb

    React.useEffect(() => {
        if (selectedColumn) {
            if (columns.find((e) => e.name === selectedColumn.name)) {
                setData(selectedColumn ? selectedColumn.name : "")
            }
        }
    }, [columns, selectedColumn])

    const columnNames = React.useMemo(() => {
        return columns ? columns.map((e) => e.name) : []
    }, [columns])

    const filtered = React.useMemo(() => {
        return columnNames ? columnNames.length > 0 ? filterBy(columnNames, {
            value: data,
            operator: 'startswith',
            ignoreCase: true
        }) : [] : []
    }, [data, columnNames])

    const initFilter = {state: t(`${defaultStateValue}`), column: ""}
    const [filter, setFilter] = React.useState<{ state: string, column: string }>(initFilter)

    return (
        <div className="kserv-drawing-map-details-holder">
            <div className={`k-card-list`}>
                <Card className="kserv-drawing-map-details">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.details.selectDrainByName")}</CardTitle>
                        <div className="kserv-card-entry">
                            <AutoComplete label={t("project.details.selectDrain")}
                                          data={filtered}
                                          value={data}
                                          suggest={false}
                                          onChange={(e) => {
                                              const value = e.target.value
                                              const eventType = e.nativeEvent.type
                                              setData(value ? value : "")
                                              if (eventType === 'click' || value !== data) {
                                                  selectColumn(value)
                                              }
                                          }}
                            />
                        </div>
                    </CardBody>
                </Card>
                <Card className="kserv-drawing-map-details">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.details.filterDrains")}</CardTitle>
                        <div className="kserv-card-entry">
                            <DropDownList className="kserv-filter-box-comp-width"
                                          label={t("project.details.state")}
                                          data={[t(`${defaultStateValue}`), ...Object.values(ColumnStateEnum)]}
                                          value={filter.state}
                                          onChange={(e) => {
                                              const value = e.target.value
                                              setFilter({...filter, state: value ? value : ""})
                                          }}
                            />
                        </div>
                        <div className="kserv-card-entry">
                            <AutoComplete className="kserv-filter-box-comp-width"
                                          label={t("project.details.filterByName")}
                                          data={columns ? columns.length > 0 ? filterBy(columnNames, {
                                              value: filter.column ? filter.column : "",
                                              operator: 'startswith',
                                              ignoreCase: true
                                          }) : [] : []}
                                          value={filter.column ? filter.column : ""}
                                          onChange={(e) => {
                                              const value = e.target.value
                                              setFilter({...filter, column: value ? value : ""})
                                          }}
                            />
                        </div>
                        <div className="kserv-map-filter-button-holder">
                            <Button className="kserv-map-filter-button k-button"
                                    onClick={() => {
                                        setMapFilter(initFilter)
                                        setFilter(initFilter)
                                    }}
                            >
                                {t("common.reset")}
                            </Button>
                            <Button className="kserv-map-filter-button k-primary k-button"
                                    onClick={() => {
                                        setMapFilter(filter)
                                    }}
                            >
                                {t("common.apply")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
                <Card className="kserv-drawing-map-details">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.settings.viewSettings")}</CardTitle>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.drainDiameter")}
                                            value={drainDiameter}
                                            format="n1"
                                            min={0.1}
                                            max={5}
                                            step={0.1}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null) {
                                                    dispatch(kDrainProjectDetailsActions.setDrainDiameter(v))
                                                }
                                            }}/>
                        </div>
                        <div className="kserv-map-filter-button-holder">
                            <Button disabled={!drainDiameterChanged}
                                    className="kserv-map-filter-button k-button"
                                    onClick={() => {
                                        dispatch(kDrainProjectDetailsActions.setDrainDiameter(drainDiameterDb))
                                    }}
                            >
                                {t("common.reset")}
                            </Button>
                            <Button disabled={!drainDiameterChanged}
                                    className="kserv-map-filter-button k-primary k-button"
                                    onClick={() => {
                                        if (currentProject !== undefined) {
                                            dispatch(kDrainProjectDetailsActions.putProject({
                                                ...currentProject,
                                                settings: {
                                                    ...currentProject.settings,
                                                    drain_diameter: drainDiameter
                                                }
                                            }))
                                        }
                                    }}
                            >
                                {t("common.save")}
                            </Button>
                        </div>
                    </CardBody>
                </Card>
            </div>
        </div>
    )
}