import React from "react";
import {ProjectId} from "../../../backend/model";
import {useDispatch, useSelector} from "react-redux";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {AppDispatch} from "../../../store";
import {DrainHistoryGrid} from "../../../components";
import {
    Card,
    CardBody,
    CardTitle,
    TabStrip,
    TabStripSelectEventArguments,
    TabStripTab
} from "@progress/kendo-react-layout";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {EditKDrainColumnGraph} from "./edit-k-drain-column-graph";
import {kDrainColumnActions} from "../../../store/kDrainColumnSlice";
import {ColumnStateEnum} from "../../../backend/projectStatus";

interface SingleColumnProps {
    id: ProjectId;
    column: string
}

export const SingleColumnPage: React.FunctionComponent<SingleColumnProps> = ({id, column}) => {

    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation()

    const [tab, setTab] = React.useState(0)

    const selectedColumn = useSelector(kDrainProjectDetailsSelectors.selectMapData).columns.find(c => c.name === column)

    React.useEffect(() => {
        if (column && column !== "" && selectedColumn
            && selectedColumn.status !== ColumnStateEnum.columnStateUnprocessed
            && selectedColumn.status !== ColumnStateEnum.columnStateFailed) {
            dispatch(kDrainColumnActions.clearColumnRawData())
            dispatch(kDrainColumnActions.fetchColumnRawData(id, column))
        }

    }, [dispatch, id, column, selectedColumn])

    const getColumnHistory = React.useCallback((name: string) => {
        dispatch(kDrainProjectDetailsActions.getDrainHistory(id, name))
    }, [dispatch, id])

    const handleTabSelect = (e: TabStripSelectEventArguments) => {
        setTab(e.selected)
    }


    return (
        <div className="kserv-content">
            <div className="kserv-tab-content">
                <TabStrip selected={tab} onSelect={handleTabSelect}>
                    <TabStripTab title="Comment">
                        <div className="kserv-drawing-map-details-holder">
                            <Card className="kserv-drawing-map-details">
                                <CardBody>
                                    <CardTitle
                                        className="kserv-card-title">{selectedColumn ? selectedColumn.name : t("project.columnDetails.noColumnSelected")}</CardTitle>
                                    <DrainHistoryGrid id={id} selectedColumn={selectedColumn?.name}
                                                      getDrainHistory={getColumnHistory}/>
                                </CardBody>
                            </Card>
                        </div>
                    </TabStripTab>
                    <TabStripTab title="Edit">
                        <div className="kserv-drawing-map-details-holder">
                            <EditKDrainColumnGraph id={id} column={column}/>
                        </div>
                    </TabStripTab>
                </TabStrip>
            </div>
        </div>
    )
}