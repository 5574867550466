import { AppThunk, RootState } from ".";
import { createRequestStateAdapter } from "./util/requestStateAdapter";
import { ProjectId} from "../backend/model";
import {createSlice, Draft, PayloadAction} from "@reduxjs/toolkit";
import {API, EndpointSummary} from "../backend/api";
import {GlobalKRegProjectSummary, DetailedKRegProjectSummary} from "../backend/summary";
import {updateErrorNotification} from "./notificationSlice";

const totalDashboardId = -1
const requestStateAdapter = createRequestStateAdapter();

type State = {
  ids: number[];
  project: { [id: number]: DetailedKRegProjectSummary};
  total: { [year: number]: GlobalKRegProjectSummary };
} & typeof requestStateAdapter.initialState;


const initialState: State = {
  ids: [],
  project: {},
  total: {},
  ...requestStateAdapter.initialState,
};

const slice =
  createSlice({
    name: "kreg-summary",
    initialState,
    reducers: {
      addProjectSummary: (state, action: PayloadAction<{ id: ProjectId, summary: DetailedKRegProjectSummary }>) => {
        const id = action.payload.id
        if (!state.ids.includes(id)) state.ids.push(id);
        state.project[id] = action.payload.summary as Draft<DetailedKRegProjectSummary>;
      },
      setProjectsSummary: (state, action: PayloadAction<{year: number,  data: GlobalKRegProjectSummary}>) => {
        const year = action.payload.year
        if (!state.ids.includes(year)) state.ids.push(year);
        state.total[year] = action.payload.data;
      },
      setPending: requestStateAdapter.setPending,
      setError: requestStateAdapter.setError,

    },
  });


const getActions = (
  endpointSelector: (api: API) => EndpointSummary
) => {
  const { setPending, addProjectSummary, setProjectsSummary } = slice.actions;


  const getKRegProjectDashboard = (id: ProjectId): AppThunk<Promise<DetailedKRegProjectSummary>>  => (dispatch, getState, {api}) => {
    dispatch(setPending(id, true));
    const getPromise = endpointSelector(api).getKRegProjectDashboard(id);
    getPromise
      .then((entity) => {
        return dispatch(addProjectSummary({id: id, summary: entity}))
      })
      .catch(err => dispatch(updateErrorNotification(err)))
      .finally(() => dispatch(setPending(id, false)));
    return getPromise
  }

  const getKRegTotalDashboard = (year: number): AppThunk<Promise<GlobalKRegProjectSummary>>  => (dispatch, getState, {api}) => {
     dispatch(setPending(totalDashboardId, true));
    const getPromise = endpointSelector(api).getKRegTotalDashboard(year);
    getPromise
      .then((entity) => {
        return dispatch(setProjectsSummary({year: year,  data: entity}))
      })
     .catch(err => dispatch(updateErrorNotification(err)))
     .finally(() => dispatch(setPending(totalDashboardId, false)));
    return getPromise
  }

  return {
    getKRegProjectDashboard,
    getKRegTotalDashboard,
  };
};

const getSelectors = () => {
  return {
    selectProjectSummaryById: (id: ProjectId) => (state: RootState) => state.kRegSummary.project[id],
    selectTotalSummary: (year: number) => (state: RootState) => state.kRegSummary.total[year],
    selectByIdRequestPending: (id: ProjectId) => (state: RootState) => state.kRegSummary.pending[id],
    selectByIdRequestError: (id: ProjectId) => (state: RootState) => state.kRegSummary.error[id],
  }
}

export const kRegSummaryActions = getActions(api => api.summary);
export const kRegSummarySelectors = getSelectors();
export default slice.reducer;
