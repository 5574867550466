import {ExportCategoriesI} from "./ExportCategories";

export const KDrainExportProductionCategories: ExportCategoriesI[] = [
  {value: 'drain_name', label: ("export.category.drainName"), selected: true, disabled: true},
  {value: 'start_time', label: ("export.category.startTime"), selected: true, disabled: true},
  {value: 'completion_time', label: ("export.category.completionTime"), selected: false, disabled: false},
  {value: 'max_drill_depth', label: ("export.category.drilledLength"), selected: true, disabled: true},
  {value: 'fabric_used', label: ("export.category.fabricUsed"), selected: true, disabled: true},
  {value: 'annotation', label: ("export.category.annotation"), selected: true, disabled: true},
  {value: 'design_easting', label: ("export.category.designCoordinateX"), selected: false, disabled: false},
  {value: 'design_northing', label: ("export.category.designCoordinateY"), selected: false, disabled: false},
  {value: 'design_z', label: ("export.category.designCoordinateZ"), selected: false, disabled: false},
  {value: 'target_depth', label: ("export.category.targetDepth"), selected: false, disabled: false},
  {value: 'actual_easting', label: ("export.category.asBuiltCoordinateX"), selected: false, disabled: false},
  {value: 'actual_northing', label: ("export.category.asBuiltCoordinateY"), selected: false, disabled: false},
  {value: 'surface_elevation', label: ("export.category.asBuiltCoordinateZWorkPlatform"), selected: false, disabled: false},
  {value: 'machine_number', label: ("export.category.machineNumber"), selected: false, disabled: false},
  {value: 'machine_operator', label: ("export.category.machineOperator"), selected: false, disabled: false},
  {value: 'work_leader', label: ("export.category.workLeader"), selected: false, disabled: false},
  {value: 'avg_hydraulic_pressure', label: ("export.category.avgHydraulicPressure"), selected: false, disabled: false},
  {value: 'fabric', label: ("export.category.fabric"), selected: false, disabled: false},
  {value: 'water_used', label: ("export.category.waterUsed"), selected: false, disabled: false},
  {value: 'fail_reason', label: ("export.category.failReason"), selected: false, disabled: false},
]