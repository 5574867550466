import React from "react";
import './style.scss'
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Checkbox, Input, NumericTextBox} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {useTranslation} from "react-i18next";
import {
    Customer,
    KRegProject,
    ProjectRole,
    ProjectRoles,
    User,
    UserId,
    UserRoles
} from "../../backend/model";
import {orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {Grid, GridCellProps} from "@progress/kendo-react-grid";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";


interface KRegProjectSettingsProps {
    project?: KRegProject,
    roles?: ProjectRoles,
    canUpdateRoles: boolean,
    updateProject: (p: Partial<KRegProject>) => void,
    updateProjectRoles: (pp: ProjectRoles) => void
    customers: Customer[],
    users: User[]
}

const roleSortOrder: Record<ProjectRole, number> = {"admin": 0, "user": 1, "guest": 2, "none": 3}
const userRoleSortOrder: Record<UserRoles, number> = {"admin": 0, "user": 0, "guest": 2}

export const KRegProjectSettingsPanel: React.FunctionComponent<KRegProjectSettingsProps> =
    ({project, updateProject, roles, updateProjectRoles, canUpdateRoles, users, customers}) => {
        const {t} = useTranslation();

        const sort: SortDescriptor[] = [{field: "roleSort", dir: "asc"}, {field: "name", dir: "asc"}]
        const userPermissions = roles && orderBy(users.map(u => {
            const isOwner = project?.user_id === u.id;
            const isGuest = u.role === UserRoles.guest
            let role = isOwner ? ProjectRole.admin : roles[u.id] || ProjectRole.none;
            return ({name: u.name, id: u.id, role, isOwner, isGuest, roleSort: roleSortOrder[role], userRoleSort: userRoleSortOrder[u.role]})
        }), sort)

        return (
            <div className="kserv-project-settings-details k-card-deck">

                <Card className="kserv-project-card">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.projectDetails")}</CardTitle>
                        <div className="kserv-card-entry">
                            <Input label={t("project.id")}
                                   className="kserv-project-settings-input"
                                   value={project ? project.keller_id : ''}
                                   onChange={e => {
                                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                                       updateProject({keller_id: v});
                                   }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <Input label={t("project.projectName")}
                                   className="kserv-project-settings-input"
                                   value={project ? project.name : ''}
                                   onChange={e => {
                                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                                       updateProject({name: v});
                                   }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <Input label={t("project.location")}
                                   className="kserv-project-settings-input"
                                   value={project ? project.location : ''}
                                   onChange={e => {
                                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                                       updateProject({location: v});
                                   }}/>
                        </div>
                      <div className="kserv-card-entry">
                        <DropDownList label={t("project.customer")}
                                      className="kserv-project-settings-input"
                                      data={customers}
                                      textField="name"
                                      defaultItem={customers.find(c => c.id === (project ? project.customer_id : -1))}
                                      dataItemKey="id"
                                      onChange={e => {
                                        const v = e.target.value.id;
                                        updateProject({customer_id: v});
                                      }}/>
                      </div>
                      <div className="kserv-card-entry">
                        <DropDownList label={t("project.owner")}
                                      className="kserv-project-settings-input"
                                      data={users.filter(u => u.role !== UserRoles.guest)}
                                      textField="name"
                                      defaultItem={users.find(c => c.id === (project ? project.user_id : -1))}
                                      dataItemKey="id"
                                      disabled={!canUpdateRoles}
                                      onChange={e => {
                                        const v = e.target.value.id;
                                        updateProject({user_id: v});
                                      }}/>
                      </div>
                      <div className="kserv-card-entry">
                        <br/>
                        <Checkbox
                          label={t("project.active")}
                          checked={project ? project.active : false}
                          onChange={e => {
                            const v = e.value
                            updateProject({active: v})
                          }}
                        />
                      </div>
                    </CardBody>
                </Card>
                <Card className="kserv-project-card">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.projectReport")}</CardTitle>
                      <div className="kserv-card-entry">
                        <NumericTextBox label={t("project.settings.glide")}
                                        value={project ? project.glide : null}
                                        format="n1"
                                        min={1}
                                        max={100}
                                        step={1}
                                        onChange={e => {
                                          const v = e.value;
                                          if (v !== null && project !== undefined) {
                                            updateProject({glide: v});
                                          }
                                        }}/>
                      </div>
                      <div className="kserv-card-entry">
                        <NumericTextBox label={t("project.settings.dpiPreview")}
                                        value={project ? project.dpi_preview : null}
                                        format="n"
                                        min={100}
                                        max={600}
                                        step={100}
                                        onChange={e => {
                                          const v = e.value;
                                          if (v !== null && project !== undefined) {
                                            updateProject({dpi_preview: v});
                                          }
                                        }}/>
                      </div>
                      <div className="kserv-card-entry">
                        <NumericTextBox label={t("project.settings.dpiExport")}
                                        value={project ? project.dpi_export : null}
                                        format="n"
                                        min={100}
                                        max={600}
                                        step={100}
                                        onChange={e => {
                                          const v = e.value;
                                          if (v !== null && project !== undefined) {
                                            updateProject({dpi_export: v});
                                          }
                                        }}/>
                      </div>
                    </CardBody>
                </Card>
                <Card className="kserv-project-card">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.projectPermissions")}</CardTitle>
                        <div className="kserv-card-entry">
                            <Grid
                                className="kserv-permissions-grid"
                                data={userPermissions}>
                                <Column field="name" title={t("customer.name")}/>
                                <Column field="role" title={t("project.role")} width={80}
                                        cell={p => <ProjectRoleCell {...p} update={updateProjectRoles} disabled={!canUpdateRoles} />}/>
                            </Grid>
                        </div>
                    </CardBody>
                </Card>
                <Card className="kserv-project-card">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.machineSettings")}</CardTitle>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.target_lift_coefficient")}
                                            value={project ? project.settings.target_lift_coefficient : null}
                                            format="n2"
                                            min={0}
                                            step={0.1}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({
                                                        settings: {
                                                            ...project.settings,
                                                            target_lift_coefficient: v
                                                        }
                                                    });
                                                }
                                            }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.targetRPM")}
                                            value={project ? project.settings.target_rpm : null}
                                            min={0}
                                            step={1}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({settings: {...project.settings, target_rpm: v}});
                                                }
                                            }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.transition_length")}
                                            format="n2"
                                            min={0}
                                            step={0.1}
                                            value={project ? project.settings.transition_length : null}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({
                                                        settings: {
                                                            ...project.settings,
                                                            transition_length: v
                                                        }
                                                    });
                                                }
                                            }}/>
                        </div>
                    </CardBody>
                </Card>
                <Card className="kserv-project-card">
                    <CardBody>
                        <CardTitle className="kserv-card-title">{t("project.projectTolerance")}</CardTitle>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.column_top_tolerance")}
                                            format="n2"
                                            min={0}
                                            step={0.1}
                                            value={project ? project.settings.column_top_tolerance : null}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({
                                                        settings: {
                                                            ...project.settings,
                                                            column_top_tolerance: v
                                                        }
                                                    });
                                                }
                                            }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.upperDensityTolerance")}
                                            format="p"
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            value={project ? project.settings.upper_density_tolerance : null}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({
                                                        settings: {
                                                            ...project.settings,
                                                            upper_density_tolerance: v
                                                        }
                                                    });
                                                }
                                            }}/>
                        </div>
                        <div className="kserv-card-entry">
                            <NumericTextBox label={t("project.settings.lowerDensityTolerance")}
                                            format="p"
                                            min={0}
                                            max={1}
                                            step={0.01}
                                            value={project ? project.settings.lower_density_tolerance : null}
                                            onChange={e => {
                                                const v = e.value;
                                                if (v !== null && project !== undefined) {
                                                    updateProject({
                                                        settings: {
                                                            ...project.settings,
                                                            lower_density_tolerance: v
                                                        }
                                                    });
                                                }
                                            }}/>
                        </div>
                    </CardBody>
                </Card>
            </div>
        )
    }

interface ProjectRoleCellProps extends GridCellProps {
    update: (pp: ProjectRoles) => void,
    disabled: boolean
}

const ProjectRoleCell: React.FunctionComponent<ProjectRoleCellProps> = ({update, disabled, dataItem}) => {
    const id = dataItem.id as UserId
    let roles = dataItem.isGuest ? [ProjectRole.none, ProjectRole.guest] : [...Object.values(ProjectRole)];
    return (
        <DropDownList className="kserv-project-role-cell"
                      data={roles}
                      defaultItem={dataItem.role}
                      disabled={disabled || dataItem.isOwner}
                      onChange={e => {
                          let role = e.target.value as ProjectRole;
                          update({[id]: role});
                      }}/>
    )
}