import React from "react";
import {Checkbox, Input} from "@progress/kendo-react-inputs";
import {DropDownList} from "@progress/kendo-react-dropdowns";
import {EditDialog} from "../edit-dialog";
import {useTranslation} from "react-i18next";
import {Customer, ProjectBase, User} from "../../backend/model";
import {useSelector} from "react-redux";
import {projectSelectors} from "../../store/projectSlice";

interface ProjectEditDialogProps {
  itemInEdit: ProjectBase | any,
  setItemInEdit: (value: ProjectBase | any) => void,
  customers: Customer[],
  users: User[],
  onDelete: (value: ProjectBase) => void,
  onEdit: (value: ProjectBase) => void
}

export const ProjectEditDialog: React.FunctionComponent<ProjectEditDialogProps> = (props) => {
  const postPending = useSelector(projectSelectors.selectPostRequestPending())

  const {t} = useTranslation();

  const updateProject = (u: Partial<ProjectBase>) => props.setItemInEdit((p: any) => ({...p, ...u}));

  const saveItem = () => {
    if (props.itemInEdit) {
      props.onEdit(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const deleteItem = () => {
    if (props.itemInEdit) {
      props.onDelete(props.itemInEdit);
      props.setItemInEdit(undefined);
    }
  };

  const cancel = () => {
    props.setItemInEdit(undefined);
  };


  const doValidate = () => {
    return postPending || props.itemInEdit ?
      (
        props.itemInEdit.name === "" ||
        props.itemInEdit.name.trim() === "" ||
        props.itemInEdit.keller_id === "" ||
        props.itemInEdit.keller_id.trim() === "" ||
        props.itemInEdit.location === "" ||
        props.itemInEdit.location.trim() === ""
      )
      : false;
  };


  return (
    <EditDialog save={saveItem}
                delete={deleteItem}
                cancel={cancel}
                validate={doValidate}>
      <div className="kserv-dialog-container">
        <div className="kserv-dialog-field">
          <legend className="kserv-dialog-legend">{t("project.editProject")}</legend>
          <div className="kserv-dialog-item">
            <label>
              {t("project.id")} <br/>
              <Input type="text"
                     autoFocus
                     value={props.itemInEdit.keller_id}
                     onChange={e => {
                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                       updateProject({keller_id: v})
                     }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("project.projectName")} <br/>
              <Input type="text"
                     autoFocus
                     value={props.itemInEdit.name}
                     onChange={e => {
                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                       updateProject({name: v})
                     }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("project.location")} <br/>
              <Input type="text"
                     autoFocus
                     value={props.itemInEdit.location}
                     onChange={e => {
                       const v = e.target.value !== undefined ? e.target.value + "" : e.target.value
                       updateProject({location: v})
                     }}/>
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("project.customer")} <br/>
              <DropDownList
                data={props.customers}
                textField="name"
                defaultItem={props.customers.find(c => c.id === props.itemInEdit.customer_id)}
                dataItemKey="id"
                onChange={e => updateProject({customer_id: e.target.value.id})}
              />
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("project.owner")} <br/>
              <DropDownList
                data={props.users.filter((user) => user.is_active)}
                textField="name"
                defaultItem={props.users.find(c => c.id === props.itemInEdit.user_id)}
                dataItemKey="id"
                onChange={e => updateProject({user_id: e.target.value.id})}
              />
            </label>
          </div>
          <div className="kserv-dialog-item">
            <label>
              {t("project.active")} <br/>
              <Checkbox
                checked={props.itemInEdit.active}
                onChange={e => {
                  const v = e.value
                  updateProject({active: v})
                }}
              />
            </label>
          </div>
        </div>
      </div>
    </EditDialog>
  )
}