import {ExportCategoriesI} from "./ExportCategories";

export const ExportColumnCategories: ExportCategoriesI[] = [
  {value: 'machine_number', label: ("export.category.machineNumber"), selected: true, disabled: true},
  {value: 'start_time', label: ("export.category.startTime"), selected: true, disabled: true},
  {value: 'tool', label: ("export.category.tool"), selected: true, disabled: true},
  {value: 'stabilized_length', label: ("export.category.stabilizedLength"), selected: true, disabled: true},
  {value: 'drill_diameter', label: ("export.category.drillDiameter"), selected: true, disabled: true},
  {value: 'max_drill_depth', label: ("export.category.drilledLength"), selected: true, disabled: false},
  {value: 'total_binder_weight', label: ("export.category.binderWeightKG"), selected: true, disabled: false},
  {value: 'completion_time', label: ("export.category.completionTime"), selected: false, disabled: false},
  {value: 'surface_elevation', label: ("export.category.surfaceElevation"), selected: false, disabled: false},
  {value: 'mixing_blades_nbr', label: ("export.category.mixingBladesNbr"), selected: false, disabled: false},
  {value: 'column_top', label: ("export.category.columnTop"), selected: false, disabled: false},
  {value: 'binder_content_per_cube_m', label: ("export.category.binderWeightKGM3"), selected: false, disabled: false},
  {value: 'avg_lift_coef', label: ("export.category.ascent"), selected: false, disabled: false},
  {value: 'avg_pull_rate', label: ("export.category.pullRate"), selected: false, disabled: false},
  {value: 'avg_rpm', label: ("export.category.rotation"), selected: false, disabled: false},
  {value: 'avg_brn', label: ("export.category.BRN"), selected: false, disabled: false},
  {value: 'start_air_pressure', label: ("export.category.airPressure"), selected: false, disabled: false},
]