import {ExportCategoriesI} from "./ExportCategories";

export const KRegExportProductionCategories: ExportCategoriesI[] = [
  {value: 'column_id', label: ("export.category.columnId"), selected: false, disabled: true},
  {value: 'start_time', label: ("export.category.startTime"), selected: false, disabled: true},
  {value: 'completion_time', label: ("export.category.completionTime"), selected: false, disabled: false},
  {value: 'max_drill_depth', label: ("export.category.drilledLength"), selected: false, disabled: true},
  {value: 'dry_drilling', label: ("export.category.emptyDrillingLength"), selected: false, disabled: true},
  {value: 'stabilized_length', label: ("export.category.stabilizedLength"), selected: false, disabled: true},
  {value: 'total_binder_weight', label: ("export.category.binderWeightKG"), selected: false, disabled: true},
  {value: 'binder_content_per_cube_m', label: ("export.category.binderWeightKGM3"), selected: false, disabled: true},
  {value: 'avg_binder_content', label: ("export.category.binderWeightKGM"), selected: false, disabled: true},
  {value: 'avg_rpm', label: ("export.category.rotation"), selected: false, disabled: false},
  {value: 'avg_brn', label: ("export.category.BRN"), selected: false, disabled: false},
  {value: 'avg_lift_coef', label: ("export.category.ascent"), selected: false, disabled: false},
  {value: 'start_air_pressure', label: ("export.category.airPressure"), selected: false, disabled: false},
  {value: 'dry_drilling_bottom', label: ("export.category.emptyDrillingBottom"), selected: false, disabled: false},
  {value: 'design_easting', label: ("export.category.designCoordinateX"), selected: false, disabled: false},
  {value: 'design_northing', label: ("export.category.designCoordinateY"), selected: false, disabled: false},
  {value: 'design_z', label: ("export.category.designCoordinateZ"), selected: false, disabled: false},
  {value: 'actual_easting', label: ("export.category.asBuiltCoordinateX"), selected: false, disabled: false},
  {value: 'actual_northing', label: ("export.category.asBuiltCoordinateY"), selected: false, disabled: false},
  {value: 'surface_elevation', label: ("export.category.asBuiltCoordinateZWorkPlatform"), selected: false, disabled: false},
  {value: 'column_top', label: ("export.category.asBuiltCoordinateZColumnTop"), selected: false, disabled: false},
  {value: 'column_bottom', label: ("export.category.asBuiltCoordinateZColumnBottom"), selected: false, disabled: false},
  {value: 'machine_number', label: ("export.category.machineNumber"), selected: false, disabled: false},
  {value: 'machine_operator', label: ("export.category.machineOperator"), selected: false, disabled: false},
  {value: 'work_leader', label: ("export.category.workLeader"), selected: false, disabled: false},
  {value: 'tool', label: ("export.category.tool"), selected: false, disabled: false},
  {value: 'binder_material', label: ("export.category.binderWeightMaterial"), selected: false, disabled: false},
  {value: 'annotation', label: ("export.category.annotation"), selected: false, disabled: true}
]

export const KRegDefaultProductionCategories = [
  'column_id',
  'start_time',
  'max_drill_depth',
  'dry_drilling',
  'dry_drilling_bottom',
  'stabilized_length',
  'total_binder_weight',
  'binder_content_per_cube_m',
  'avg_binder_content',
  'avg_rpm',
  'avg_lift_coef',
  'start_air_pressure',
  'design_easting',
  'design_northing',
  'design_z',
  'actual_easting',
  'actual_northing',
  'surface_elevation',
  'column_top',
  'column_bottom',
  'machine_number',
  'machine_operator',
  'tool',
  'binder_material',
  'annotation'
]