import React, {useEffect} from "react";
import {ProjectId} from "../../../backend/model";
import {UploadFile} from "../../../components/upload-file";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {useTranslation} from "react-i18next";
import "./style.scss"
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {DrawingHistoryGrid} from "./drawing-history-grid";
import {DrawingDifference} from "./drawing-diff-grid";
import {DrawingHistory, DrawingUploadResponse} from "../../../backend/drawing";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {Checkbox} from "@progress/kendo-react-inputs";

interface ColumnDrawingProps {
  id: ProjectId;
}

export const ProjectDrawingPage: React.FunctionComponent<ColumnDrawingProps> = ({id}) => {

  const {t} = useTranslation()

  const dispatch: AppDispatch = useDispatch();
  const dataLoading = useSelector(kDrainProjectDetailsSelectors.selectByIdRequestPending(id))

  const [hist, setHist] = React.useState<DrawingHistory | undefined>(undefined)
  const [ignoreDrilledColumns, setIgnoreDrilledColumns] = React.useState<boolean>(false)
  const [diff, setDiff] = React.useState<DrawingUploadResponse | undefined>(undefined)
  const [message, setMessage] = React.useState<string | undefined>(undefined)

  const displayDiff = () => {
    return !!(diff && diff.column_updates.length)
  }

  const updateMessage = (diff: DrawingUploadResponse) => {
    setDiff(diff)
    !diff.success ? setMessage(diff.message) : setMessage("")
    doHistoryUpdate()
    if (diff.success) {
      dispatch(kDrainProjectDetailsActions.getUpdate(id))
    }
  }

  const updateMessageTemp = (diff: void) => {

  }

  const doHistoryUpdate = React.useCallback(() => {
    dispatch(kDrainProjectDetailsActions.getDrawingHistory(id))
      .then((entry) => {
        setHist(entry)
      })
  }, [dispatch, id])

  useEffect(() => {
    doHistoryUpdate()
    return (() => {
    })
  }, [dispatch, id, doHistoryUpdate])

  const drawingUpload = (id: ProjectId, filename: string, file: any) => {
    return kDrainProjectDetailsActions.postDrawing(id, filename, file, ignoreDrilledColumns)
  }

  const drawingLineUpload = (id: ProjectId, filename: string, file: any) => {
    return kDrainProjectDetailsActions.postDrawingObstacles(id, file)
  }

  return (
    <div className="kserv-tab-content">
      <div className="kserv-drawing-upload-page k-card-list">
      <div className="k-card-deck">
          <Card className="kserv-project-card">
            <CardBody>
              <CardTitle className="kserv-card-title">{t("project.details.upload")}</CardTitle>
              <div className="kserv-card-entry">
                <span className="kserv-upload-title">{t("project.details.drawing")}</span>
                <UploadFile<DrawingUploadResponse> id={id} extension={"csv"} endpoint={drawingUpload} message={message}
                                                   setResponse={updateMessage}>
                  <div className="kserv-drawing-checkbox">
                    <Checkbox
                      label={t("project.details.drawingIgnoreDrilledColumns")}
                      checked={ignoreDrilledColumns}
                      onChange={e => {
                        const v = e.value
                        setIgnoreDrilledColumns(v)
                      }}
                    />
                  </div>
                </UploadFile>
              </div>
              <div className="kserv-card-entry">
                <span className="kserv-upload-title">{t("project.details.obstacles")}</span>
                <UploadFile<void> id={id} extension={"csv"} endpoint={drawingLineUpload} message={""}
                                  setResponse={updateMessageTemp}/>
              </div>
            </CardBody>
          </Card>
          <Card className="kserv-project-card">
            <CardBody>
              <CardTitle className="kserv-card-title">{t("project.drawing.drawingHistory")}</CardTitle>
              <div className="kserv-card-entry">
                <DrawingHistoryGrid
                  history={hist ? hist.history : []}
                  dataLoading={dataLoading ? dataLoading : false}/>
              </div>
            </CardBody>
          </Card>
        </div>
        {displayDiff() &&
        <Card className="kserv-project-card">
            <CardBody>
                <CardTitle className="kserv-card-title">{t("project.drawing.diffDrawing")}</CardTitle>
                <div className="kserv-card-entry">
                    <DrawingDifference
                        download_response={diff}
                        dataLoading={dataLoading ? dataLoading : false}/>
                </div>
            </CardBody>
        </Card>}
    </div>
    </div>
  )
}