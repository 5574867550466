import {DateRange} from "./kserv-date-range-picker";
import {CompositeFilterDescriptor} from "@progress/kendo-data-query";
import {addDays, getDate} from "@progress/kendo-date-math";

export { KservDateRangePicker } from "./kserv-date-range-picker";

export const rangeToStartTimeFilter = (dateRange: DateRange): CompositeFilterDescriptor => ({
    logic: 'and', filters: [
        {field: "start_time", operator: 'gte', value: getDate(dateRange.start as Date).toISOString()},
        {field: "start_time", operator: 'lte', value: getDate(addDays(dateRange.end as Date, 1)).toISOString()}
    ]
})