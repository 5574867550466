import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import "./style.scss"
import {ProjectId} from "../../../backend/model";
import {AppDispatch} from "../../../store";

import { kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {KdrainDrainLogGrid} from "./kdrain-drain-log-grid";
import {exportActions, ExportFileInterface} from "../../../store/exportSlice";

interface ColumnLogProps {
  id: ProjectId;
  selectSingleColumnPage: (title: string) => void
}

export const ProjectColumnLogPage: React.FunctionComponent<ColumnLogProps> = ({id, selectSingleColumnPage}) => {
  const dispatch: AppDispatch = useDispatch();

  const columnReportSummary = useSelector(kDrainProjectDetailsSelectors.selectColumnReportSummaryById(id));

  useEffect(() => {
    dispatch(kDrainProjectDetailsActions.getAggregate(id))
      .catch(() => {})
    dispatch(exportActions.getColReportBatchStatus(id))
      .catch(() => {})
  }, [dispatch, id]);

  const doRefresh = () => {
    dispatch(kDrainProjectDetailsActions.getAggregate(id))
      .catch(() => {})
  }

  const doProjDownload = (id: ProjectId, options: ExportFileInterface) => {
    dispatch(exportActions.downloadKDrainProjReport(id, options))
      .catch(() => {})
  }

  return (
    <div>
      <div className="kserv-content">
        <div className="kserv-project-column-log-page">
          <KdrainDrainLogGrid project_id={id} data={columnReportSummary} onProjDownload={doProjDownload} onRefresh={doRefresh}
                              selectSingleColumnPage={selectSingleColumnPage}/>
        </div>
      </div>
    </div>
  );
};
