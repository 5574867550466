import { createEndpointAdapter } from "./util/endpointAdapter";
import { RootState } from ".";
import { FabricType } from "../backend/model";

const adapter = createEndpointAdapter<FabricType, "FabricType">("fabric_type");
const slice = adapter.getSlice();

export const fabricTypeActions = adapter.getActions(slice, api => api.fabric_type);
export const fabricTypeSelectors = adapter.getSelectors((state: RootState) => state.fabric_type);
export { missingFabricTypeId } from "../backend/model";
export default slice.reducer;
