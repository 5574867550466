import React, {useEffect} from "react";
import {ProjectId} from "../../../backend/model";
import {LogUploadResponse} from "../../../backend/drawing";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import "./style.scss"
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {UploadFile} from "../../../components/upload-file";
import {ColumnUpdateLogGrid} from "./log-upload-grid";
import {LogReprocess} from "./log-reprocess";

interface LogUploadProps {
  id: ProjectId;
}

export const ProjectAdminPanel: React.FunctionComponent<LogUploadProps> = ({id}) => {

  const {t} = useTranslation()
  const [message, setMessage] = React.useState<string | undefined>(undefined)
  const dispatch: AppDispatch = useDispatch();
  const columnUpdates = useSelector(kRegProjectDetailsSelectors.selectColumnUpdates())


  useEffect(() => {
    dispatch(kRegProjectDetailsActions.getColumnUpdates(id));
  }, [id, dispatch]);

  const doRefresh = () => {
    dispatch(kRegProjectDetailsActions.getColumnUpdates(id));
  };

  const uploadLog = (id: ProjectId, filename: string, file: any) => {
    return kRegProjectDetailsActions.uploadLog(id, filename, file)
  }

  const updateMessage = (diff: LogUploadResponse) => {
    if (diff.id < 0) {
      setMessage("Error")
    } else {
      setMessage(undefined)
    }
  }

  const adminButtonPanel = (
    <>
      <LogReprocess id={id} />
    </>
  )

  const logUploadCard = (
    <>
      <Card className="kserv-project-card">
        <CardBody>
          <CardTitle className="kserv-card-title">{t("project.details.uploadLog")}</CardTitle>
          <div className="kserv-card-entry">
            <UploadFile<LogUploadResponse> id={id} extension={"zip"} endpoint={uploadLog} message={message}
                                           setResponse={updateMessage}/>
          </div>
        </CardBody>
      </Card>
      <Card className="kserv-project-card">
        <CardBody>
          <CardTitle className="kserv-card-title">{t("project.details.logUpdates")}</CardTitle>
          <div className="kserv-card-entry">
            <ColumnUpdateLogGrid data={columnUpdates ? columnUpdates : []} doRefresh={doRefresh}/>
          </div>
        </CardBody>
      </Card>
    </>
  )


  return (
    <div className="kserv-tab-content">
      <div className="kserv-project-admin-page k-card-list">
        <div className="k-card-list">
          {adminButtonPanel}
          {logUploadCard}
        </div>
      </div>
    </div>
  )
}
