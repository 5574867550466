import React from 'react'
import {Dialog, DialogActionsBar} from "@progress/kendo-react-dialogs";
import {useTranslation} from "react-i18next";
import {faTrashAlt, faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface DeleteDialogProps {
  onDelete: () => void,
  cancelDelete: () => void
}

export const DeleteDialog: React.FunctionComponent<DeleteDialogProps> = (props) => {
  const {t} = useTranslation();

  return (
    <Dialog title={t("common.delete")} onClose={props.cancelDelete}>
      <p style={{margin: "25px", textAlign: "center"}}>
        {t("component.delete.confirm")}
      </p>
      <DialogActionsBar>
        <button className="k-button"
                onClick={() => props.onDelete()}>
          <FontAwesomeIcon icon={faTrashAlt}/>
        </button>
        <button className="k-button k-primary"
                onClick={() => props.cancelDelete()}>
          <FontAwesomeIcon icon={faWindowClose}/>
        </button>
      </DialogActionsBar>
    </Dialog>);
};
