import React from "react";
import "../style.scss"
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridPagerSettings,
  GridToolbar
} from "@progress/kendo-react-grid";
import {useTranslation} from "react-i18next";
import {ProjectId} from "../../../backend/model";
import {DropdownFilterCell, LoadingPanel, RefreshButton, ToggleButton} from "../../../components";
import {filterOperators} from "../../../components/grid-filter-operations/GridFilterOperators";
import {ColumnStateEnum, KRegProjectMapData} from "../../../backend/projectStatus";
import {UnitCell} from "../../../components/custom-cell/unit-cell";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

interface ColumnSpecGridProps {
  project_id: ProjectId
  dataLoading: boolean,
  mapData: KRegProjectMapData,
  onDelete?: (value: any) => void,
  onDetails?: (value: any) => void
  onEdit?: (value: any) => void
  onRefresh: () => void,
}

export const ColumnSpecGrid: React.FunctionComponent<ColumnSpecGridProps> = (props) => {

  const {t} = useTranslation();

  const initialSort: SortDescriptor[] = [{field: '', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridName: string = "kserv-project-column-spec-grid"

  const gridData = React.useMemo(() => {
    return (filterBy(orderBy(props.mapData.columns.map(c => ({
      ...c,
      easting: c.location[0].toFixed(2),
      northing: c.location[1].toFixed(2),
    })), sort), filter))
  }, [props.mapData.columns, sort, filter])

  return (
    <>
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        onFilterChange={(e) => setFilter(e.filter)}
        filterOperators={filterOperators}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        pageable={pageAble}
        onPageChange={onPageChange}
      >
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            <RefreshButton callback={props.onRefresh}/>
          </div>
        </GridToolbar>
        <Column field="status" title={t("project.details.state")} width="160px"
                filterCell={(p) => <DropdownFilterCell {...p} data={[...Object.values(ColumnStateEnum)]}
                                                       defaultItem={'All'}/>}/>
        <Column field="name" title={t("project.name")}/>
        <Column title={t("project.details.GPS")} width="200px" filterable={false}>
          <Column field="easting" title={t("project.details.easting")} width="100px" filterable={true}
                  filter={"numeric"}/>
          <Column field="northing" title={t('project.details.northing')} width="100px" filterable={true}
                  filter={"numeric"}/>
        </Column>
        <Column field="drill_diameter" title={t("project.details.drillDiameter")} filter={"numeric"}
                cell={(p) => <UnitCell {...p} unit={" mm"}/>}/>
        <Column field="empty_length" title={t("project.details.emptyLength")} filter={"numeric"}
                cell={(p) => <UnitCell {...p} unit={" m"}/>}/>
        <Column field="density_zones.zones.length" title={t("project.details.zones")} filter={"numeric"}/>
      </Grid>
      {props.dataLoading && <LoadingPanel name={gridName}/>}
    </>
  )
}

