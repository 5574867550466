import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { projectActions, projectSelectors } from "../../store/projectSlice";
import { AppDispatch } from "../../store";
import { ProjectBase } from "../../backend/model";
import { useTranslation } from "react-i18next";
import { ProjectGrid } from "./project-grid";
import { customerActions, customerSelectors } from "../../store/customerSlice";
import { userActions, userSelectors } from "../../store/userSlice";
import {PageTitle} from "../../components";

export const ProjectsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const projects = useSelector(projectSelectors.selectAll());
  const customers = useSelector(customerSelectors.selectAll());
  const users = useSelector(userSelectors.selectAll());
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(projectActions.fetchAll())
      .then(() => dispatch(customerActions.fetchAll()))
      .then(() => dispatch(userActions.fetchAll()));
  }, [dispatch]);

  const doDetails = (dataItem: ProjectBase) => {
    history.push(`/project/${dataItem.machine_type}/details/${dataItem.id}`);
  };

  const doDelete = (dataItem: ProjectBase) => {
    dispatch(projectActions.deleteOne(dataItem.id))
      .catch(() => {})
      .finally(() => dispatch(projectActions.fetchAll()))
  };

  const doRefresh = () => {
    dispatch(projectActions.fetchAll());
  };

  const doEdit = (dataItem: ProjectBase) => {
    dispatch(projectActions.updateOne(dataItem, dataItem.id))
      .catch(() => {})
      .finally(() => doRefresh())
  };

 return (
    <div>
      <PageTitle title={t("project.title")} />
      <div className="kserv-content">
        <ProjectGrid projects={projects} customers={customers} users={users} onEdit={doEdit} onRefresh={doRefresh} onDelete={doDelete} onDetails={doDetails}/>
      </div>
    </div>
  );
};
