import React from "react";
import {GridCellProps} from "@progress/kendo-react-grid";
import "./style.scss"

interface CheckBoxCellProps<R> extends GridCellProps {
  callback?: (p: R) => void
  checked: boolean
}

export const CheckBoxCell: <T>(p: CheckBoxCellProps<T>) => React.ReactElement<CheckBoxCellProps<T>> = (p) => {

  return (
    <td colSpan={p.colSpan} style={p.style} className="kserv-checkbox-cell kserv-grid-cell">
      <input disabled type={'checkbox'} checked={p.checked}/>
    </td>)

};
