import React from 'react';
import "./style.scss"
import {Button, Chip, ChipList, ChipListChangeEvent, ChipProps,} from '@progress/kendo-react-buttons';
import {useTranslation} from "react-i18next";
import {ExportCategoriesI} from "./ExportCategories";

interface ExportReportFormProp {
    children?: React.ReactNode,
    categories: ExportCategoriesI[],
    setCategories: (data: ExportCategoriesI[]) => void,
    onDefault?: () => void,
    onSaveDefault?: () => void,
    text?: string
}


const CategoryChip = (props: ChipProps) => {
    const {t} = useTranslation();
    return (
        <Chip
            {...props}
            text={t(props.dataItem.label)}
            disabled={props.dataItem.disabled}
            selected={props.dataItem.selected}
            selectedIcon="k-icon k-i-check"
        />
    );
};

export const KservChipList: React.FunctionComponent<ExportReportFormProp> = (props) => {

    const {t} = useTranslation();
    // const [value, setValue] = useState<String[]>([]);

    // React.useEffect(() => console.log(props.categories), [props.categories])

    const SelectAllButton = () => {
        return (
            <Button onClick={(e) => {
                const newCategories = props.categories.map((e) => {
                    return {...e, selected: true}
                })
                props.setCategories(newCategories)
            }}>
                {t("export.selectAllCategories")}
            </Button>
        )
    }

    const SelectDefaultButton = () => {
        return (
            <Button onClick={(e) => {
                e.preventDefault()
                if (props.onDefault) {
                    props.onDefault()
                } else {
                    const newCategories = props.categories.map((e) => e.disabled ? {...e} : {...e, selected: false})
                    props.setCategories(newCategories)
                }
            }}>
                {t("export.selectDefaultCategories")}
            </Button>
        )
    }

    const SaveDefaultButton = () => {
        if (props.onSaveDefault === undefined) return null
        return (
            <Button onClick={props.onSaveDefault}>
                {t("export.saveDefaultCategories")}
            </Button>
        )
    }

    const handleChange = (event: ChipListChangeEvent) => {
        const newCategories = props.categories.map(c => c.value === event.value ? {...c, selected: !c.selected} : c)
        props.setCategories(newCategories)
    }

    return (
        <div className="kserv-chiplist-div">
            <div className="kserv-chiplist">
                <ChipList
                    data={props.categories}
                    selection="single"
                    value={{}}
                    onChange={handleChange}
                    chip={CategoryChip}
                />
            </div>
            <div className="kserv-chiplist-toolbar">
                <SelectAllButton/>
                <SelectDefaultButton/>
                <SaveDefaultButton/>
                {props.children}
            </div>
        </div>
    )
}