import React, {useEffect} from "react";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {Button} from "@progress/kendo-react-buttons";
import {kRegProjectDetailsActions, kRegProjectDetailsSelectors} from "../../../store/kRegProjectDetailsSlice";
import {updateInfoNotification} from "../../../store/notificationSlice";
import {useTranslation} from "react-i18next";
import {AppDispatch} from "../../../store";
import {useDispatch, useSelector} from "react-redux";
import {ProjectId, ProjectRole} from "../../../backend/model";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridPagerSettings,
  GridToolbar
} from "@progress/kendo-react-grid";
import {DateCell, RefreshButton, ToggleButton} from "../../../components";
import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {RemoveCell} from "../../../components/custom-cell/remove-cell";
import {UnitCell} from "../../../components/custom-cell/unit-cell";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";

interface LogReprocessProps {
  id: ProjectId;
}

export const LogReprocess: React.FunctionComponent<LogReprocessProps> = ({id}) => {

  const {t} = useTranslation()
  const dispatch: AppDispatch = useDispatch();
  const [showFilters, setShowFilters] = React.useState(false);
  const [userIsAdmin, setUserIsAdmin] = React.useState(false)

  const initialSort: SortDescriptor[] = [{field: 'start_time', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [5], previousNext: true};

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 5});


  const reprocessJobs = useSelector(kRegProjectDetailsSelectors.selectReprocessLogBatchStatus(id))

  useEffect(() => {
    dispatch(kRegProjectDetailsActions.getMyProjectRole(id))
      .then(role => setUserIsAdmin(role === ProjectRole.admin))
      .catch(() => {})
    return (() => {})
  }, [dispatch, id, setUserIsAdmin]);

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const doRefresh = () => {
    dispatch(kRegProjectDetailsActions.getReprocessLogs(id))
      .then(() => dispatch(updateInfoNotification("common.updated")))
      .catch(() => {})
  }

  const doStopBatch = (pid: number) => {
    dispatch(kRegProjectDetailsActions.stopReprocessLogs(id, pid))
      .then(() => doRefresh())
      .catch(() => {})
  }

  const logsReprocessButton = (
    <Button onClick={() => {
      dispatch(kRegProjectDetailsActions.startReprocessLogs(id))
        .then(() => doRefresh())
        .catch(() => {})
    }}>
      {t("project.details.reprocessLogs")}
    </Button>
  )

  const gridName = "kserv-log-reprocess-grid"

  const gridData = () => {
    return reprocessJobs.map((e) => {
      return {
        ...e,
        progress: Math.round(100*(e.processed_columns / e.nbr_of_columns))
      }
    })
  }

  return(
    <>
      <Card className="kserv-project-card">
        <CardBody>
          <CardTitle className="kserv-card-title">{t("project.details.logHandling")}</CardTitle>
          <div className="kserv-card-entry">
            <div className="kserv-project-admin-panel">
              {logsReprocessButton}
            </div>
            <Grid
              className={gridName}
              data={filterBy(orderBy(gridData(), sort), filter)}
              sortable
              sort={sort}
              onSortChange={(e) => setSort(e.sort)}
              filterable={showFilters}
              filter={filter}
              resizable
              onFilterChange={(e) => setFilter(e.filter)}
              skip={pageState.skip}
              take={pageState.take}
              total={gridData.length}
              pageable={pageAble}
              onPageChange={onPageChange}
            >
              <GridToolbar>
                <div className="kserv-grid-toolbar">
                  <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
                  <RefreshButton  callback={doRefresh}/>
                </div>
              </GridToolbar>
              <Column field="start_time" title={t("project.details.startTime")} filterable={false} format="{0:d}"
                      cell={(p) => <DateCell {...p} time={p.dataItem.start_time}/>}/>
              <Column field="nbr_of_columns" title={t("project.details.nbrOfColumns")} filter={'numeric'}/>
              <Column field="processed_columns" title={t("project.details.processColumns")} filter={'numeric'}/>
              <Column field="progress" title={t("project.details.state")} filterable={false} width="80px"
                      cell={(p) => <UnitCell {...p} unit={" %"}/>}/>
              <Column field="status" title={t("export.formatText")} filter={'text'}/>
              <Column field="error" title={t("error.title")} filter={'text'}/>
              <Column field="remove" title={t("common.delete")} filterable={false} sortable={false} width="80px"
                      cell={(p) => <RemoveCell {...p} disabled={!userIsAdmin} callback={doStopBatch}/>}/>
            </Grid>
          </div>
        </CardBody>
      </Card>
    </>
  )
}