import React from "react";
import {ColumnStateEnum, ColumnStateTranslation} from "../../../backend/projectStatus";
import {
  Chart, ChartArea,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartSeriesLabels,
} from "@progress/kendo-react-charts";
import {Card, CardBody, CardTitle} from "@progress/kendo-react-layout";
import {DetailedKDrainProjectSummary} from "../../../backend/summary";
import 'hammerjs';
import {useTranslation} from "react-i18next";

interface ColumnStatusChartProps {
  data: DetailedKDrainProjectSummary | undefined;
}

export const DrainStatusChart: React.FunctionComponent<ColumnStatusChartProps> = ({data}) => {

  const {t} = useTranslation()


  const getPercentage = () => {
    if(data === undefined) return 0
    return data.column_state_count.completed  * 100.0 / (data.column_state_count.unprocessed + data.column_state_count.failed + data.column_state_count.completed + data.column_state_count.aborted)
  }

  const donutCenterRenderer = () => (<span><h3>{getPercentage().toFixed(2)}%</h3></span>);

  const columnStatuses = [
    {
      name: t(ColumnStateTranslation[ColumnStateEnum.columnStateUnprocessed]),
      share: data ? data.column_state_count.unprocessed : 0,
    },
    {
      name: t(ColumnStateTranslation[ColumnStateEnum.columnStateCompleted]),
      share: data ? data.column_state_count.completed : 0,
    },
    {
      name: t(ColumnStateTranslation[ColumnStateEnum.columnStateAborted]),
      share: data ? data.column_state_count.aborted : 0,
    },
    {
      name: t(ColumnStateTranslation[ColumnStateEnum.columnStateFailed]),
      share: data ? data.column_state_count.failed : 0,
    }

  ];

  const labelContent = (e: any) => (`${ e.category }: \n ${e.value}`);

  return (
    <Card className="kserv-dashboard-card kserv-column-count-chart">
      <CardBody>
        <CardTitle className="kserv-card-title">{t("project.dashboard.drainTitle")}</CardTitle>
        <Chart donutCenterRender={donutCenterRenderer}>
          <ChartLegend visible={false}/>
          <ChartArea background={"none"}/>
          <ChartSeries>
            <ChartSeriesItem
              type="donut"
              overlay={{
                gradient: "glass"
              }}
              data={columnStatuses}
              categoryField="name"
              field="share"
              size={10}
              colorField="color"
            >
              {
                <ChartSeriesLabels
                  position={"outsideEnd"}
                  background={"none"}
                  distance={20}
                  content={labelContent}
                />
              }
            </ChartSeriesItem>
          </ChartSeries>
        </Chart>
      </CardBody>
    </Card>
  )
}