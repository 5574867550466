import React from "react";
import * as d3 from "d3";
import {useDispatch} from "react-redux";
import {Button} from "@progress/kendo-react-buttons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCrosshairs,
    faHandPointer,
    faSearchMinus,
    faSearchPlus,
    faSyncAlt,
    faEye,
    faEyeSlash,
    faTimes, faExpand, faCompress
} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {kDrainProjectDetailsActions} from "../../../store/kDrainProjectDetailsSlice";
import "./style.scss"
import {ColumnStateEnum, KDrainProjectMapData, DrainSpecWithStatus} from "../../../backend/projectStatus";
import {CircleObstacle, LineObstacle, Obstacle} from "../../../backend/drawing";
import {updateShowMapObstacles} from "../../../store/applicationSlice";

// Limit below which labels are invisible.
const SCALE_LIMIT = 40;

interface MapProps {
    x: number,
    y: number,
    bearing: number,
    scale: number,
    drainDiameter: number,
    mapDrawing: KDrainProjectMapData,
    selectedColumn?: DrainSpecWithStatus,
    onRefresh: () => void
    selectColumn: (name: string) => void
    showMapObstacles: boolean
}


export const Map: React.FunctionComponent<MapProps> = ({x, y, bearing, scale,drainDiameter, mapDrawing, selectedColumn, onRefresh, selectColumn, showMapObstacles}) => {
    const [size, setSize] = React.useState({width: 0, height: 0});

    const [enableDrag, setEnableDrag] = React.useState<boolean>(true);

    const svgRef = React.useRef<SVGSVGElement>(null);
    const panningRef = React.useRef<SVGGElement>(null);
    const circleGroupRef = React.useRef<SVGGElement>(null);
    const obstacleGroupRef = React.useRef<SVGGElement>(null);
    const labelGroupRef = React.useRef<SVGGElement>(null);
    const {t} = useTranslation();
    const dispatch = useDispatch();

    React.useLayoutEffect(() => {
        const el = svgRef.current;
        const updateSize = () => {
            if (el !== null) {
                setSize({width: el.clientWidth, height: el.clientHeight});
            }
        };
        if (el !== null) {
            window.addEventListener("resize", updateSize);
        }
        updateSize();
        return (() => {
            if (el !== null) {
                window.removeEventListener("resize", updateSize);
            }
        });
    }, [svgRef]);

    // On screen coordinates.
    const tx = size.width / 2;
    const ty = size.height / 2;

    const getX = React.useCallback(() => {
        return x
    }, [x])

    const getY = React.useCallback(() => {
        return y
    }, [y])

    const getBearing = React.useCallback(() => {
        return bearing
    }, [bearing])

    const getColor = (color: string) => {
        switch (color) {
            case "blue":
                return "#007bc3"
            case "yellow":
                return "#FFF000"
            case "red":
                return "#d92800"
            case "green":
                return "forestgreen"
            case "white":
                return "silver"
            case "black":
                return "#000000"
            default:
                return "#007bc3"
        }
    }

    // Transformations based on:
    //
    //     /        \   /                   \   /       \   /        \
    //     | 1 0 tx |   | cos(a) -sin(a)  0 |   | s 0 0 |   | 1 0 ux |
    // M = | 0 1 ty | . | sin(a)  cos(a)  0 | . | 0 s 0 | . | 0 1 uy |
    //     | 0 0 1  |   |   0       0     1 |   | 0 0 1 |   | 0 0 1  |
    //     \        /   \                   /   \       /   \        /
    //
    //     translate     rotate                   scale      translate
    //     screen                                            map
    //     offset                                            offset

    const Map2Screen = (mx: number, my: number): [number, number] => {
        const cos = Math.cos(getBearing() * Math.PI / 180);
        const sin = Math.sin(getBearing() * Math.PI / 180);
        const x = scale * (mx - getX()) * cos - scale * (my - getY()) * sin + tx;
        const y = scale * (mx - getX()) * sin + scale * (my - getY()) * cos + ty;
        return [x, y];
    };

    // Just the inverse relationship based on M^(-1).

    const Screen2Map = React.useCallback((x: number, y: number) => {
        const cos = Math.cos(getBearing() * Math.PI / 180);
        const sin = Math.sin(getBearing() * Math.PI / 180);
        const mx = -1 / scale * ((tx - x) * cos + (ty - y) * sin + scale * (-getX()));
        const my = -1 / scale * ((x - tx) * sin + (ty - y) * cos + scale * (-getY()));
        return [mx, my];
    }, [scale, tx, ty, getX, getY, getBearing])

    // Transformation of group of circles. Same calculation as Map2Screen earlier. Here we are using an offset
    // to get smaller magnitude of the numeric values for the calculations.
    let transform = `translate(${tx},${ty}) rotate(${getBearing()}) scale(${scale}) ` +
      `translate(${-(getX() - mapDrawing.offset.x)},${-(getY() - mapDrawing.offset.y)})`;

    const obstacleLineScale = 2

    // Empty placeholder to restrict number of tooltip divs created in d3.

    function mousemove(event: any, d: Obstacle) {
        d3.select('body').selectAll('.tooltip-area').transition()
          .duration(100)
          .style("opacity", 1);
        d3.select('body').selectAll('.tooltip-area').html(d.comment)
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY) + "px");
    }

    function mouseover(event: any, d: Obstacle) {
        d3.select('body').selectAll('.tooltip-area').transition()
          .duration(100)
          .style("opacity", 1);
    }

    function mouseout(event: any, d: Obstacle) {
        d3.select('body').selectAll('.tooltip-area').transition()
          .duration(100)
          .style("opacity", 0);
    }

    React.useEffect(() => {
        const tooltipDiv: number[] = [1]

        if (obstacleGroupRef.current !== null) {
            d3.select('body').selectAll('.tooltip-area')
              .data(tooltipDiv)
              .enter()
              .append("div")
              .attr("class", "tooltip-area")
              .style("opacity", 0);

            let d3_obstacle_lines = d3.select(obstacleGroupRef.current)
              .selectAll("line")
              .data(Object.values(mapDrawing.obstacles.lines))
              .style("stroke-width", (l: LineObstacle) => l.weight / obstacleLineScale)
              .style("stroke", (l: LineObstacle) => getColor(l.color))
              .attr("x1", (l: LineObstacle) => l.start_x - mapDrawing.offset.x)
              .attr("y1", (l: LineObstacle) => l.start_y - mapDrawing.offset.y)
              .attr("x2", (l: LineObstacle) => l.end_x - mapDrawing.offset.x)
              .attr("y2", (l: LineObstacle) => l.end_y - mapDrawing.offset.y)
              .on("mousemove", mousemove)
              .on("mouseout", mouseout)
              .on("mouseover", mouseover);
            d3_obstacle_lines.enter()
              .append("line")
              .style("stroke-width", (l: LineObstacle) => l.weight / obstacleLineScale)
              .style("stroke", (l: LineObstacle) => getColor(l.color))
              .attr("x1", (l: LineObstacle) => l.start_x - mapDrawing.offset.x)
              .attr("y1", (l: LineObstacle) => l.start_y - mapDrawing.offset.y)
              .attr("x2", (l: LineObstacle) => l.end_x - mapDrawing.offset.x)
              .attr("y2", (l: LineObstacle) => l.end_y - mapDrawing.offset.y)
              .on("mousemove", mousemove)
              .on("mouseout", mouseout)
              .on("mouseover", mouseover);

            d3_obstacle_lines.exit().remove();

            let d3_obstacle_circles = d3.select(obstacleGroupRef.current)
              .selectAll("circle")
              .data(Object.values(mapDrawing.obstacles.circles))
              .style("stroke-width", (c: CircleObstacle) => c.weight / obstacleLineScale)
              .style("stroke", (c: CircleObstacle) => getColor(c.color))
              .attr("class", "kreg-drawing-map-obstacle")
              .attr("cx", (c: CircleObstacle) => c.center_x - mapDrawing.offset.x)
              .attr("cy", (c: CircleObstacle) => c.center_y - mapDrawing.offset.y)
              .attr("r", (c: CircleObstacle) => c.radius)
              .on("mousemove", mousemove)
              .on("mouseout", mouseout)
              .on("mouseover", mouseover);
            d3_obstacle_circles.enter()
              .append("circle")
              .style("stroke-width", (c: CircleObstacle) => c.weight / obstacleLineScale)
              .style("stroke", (c: CircleObstacle) => getColor(c.color))
              .attr("class", "kreg-drawing-map-obstacle")
              .attr("cx", (c: CircleObstacle) => c.center_x - mapDrawing.offset.x)
              .attr("cy", (c: CircleObstacle) => c.center_y - mapDrawing.offset.y)
              .attr("r", (c: CircleObstacle) => c.radius)
              .on("mousemove", mousemove)
              .on("mouseout", mouseout)
              .on("mouseover", mouseover);

            d3_obstacle_circles.exit().remove();
        }

        if (circleGroupRef.current !== null) {
            let d3_circles = d3.select(circleGroupRef.current)
              .selectAll("circle")
              .data(Object.values(mapDrawing.columns))
              .attr("class", (d: DrainSpecWithStatus) => {
                  if (selectedColumn && (d.name === selectedColumn.name)) {
                      return "kserv-drawing-column-selected";
                  }
                  switch (d.status) {
                      case ColumnStateEnum.columnStateUnprocessed:
                          return "kserv-drawing-column-available";
                      case ColumnStateEnum.columnStateCompleted:
                          return "kserv-drawing-column-completed";
                      case ColumnStateEnum.columnStateAborted:
                          return "kserv-drawing-column-aborted";
                      case ColumnStateEnum.columnStateFailed:
                          return "kserv-drawing-column-failed";
                  }
              })
              .attr("cx", (d: DrainSpecWithStatus) => d.location[0] - mapDrawing.offset.x)
              .attr("cy", (d: DrainSpecWithStatus) => d.location[1] - mapDrawing.offset.y)
              .attr("r", (_) => drainDiameter / 2.0)

            d3_circles.enter()
              .append("circle")
              // Select column by clicking on it.
              .on("click", (event: any, c: DrainSpecWithStatus) => {
                  selectColumn(c.name)
              })
              .attr("class", (d: DrainSpecWithStatus) => {
                  if (selectedColumn && (d.name === selectedColumn.name)) {
                      return "kserv-drawing-column-selected";
                  }
                  switch (d.status) {
                      case ColumnStateEnum.columnStateUnprocessed:
                          return "kserv-drawing-column-available";
                      case ColumnStateEnum.columnStateCompleted:
                          return "kserv-drawing-column-completed";
                      case ColumnStateEnum.columnStateAborted:
                          return "kserv-drawing-column-aborted";
                      case ColumnStateEnum.columnStateFailed:
                          return "kserv-drawing-column-failed";
                  }
              })
              .attr("cx", (d: DrainSpecWithStatus) => d.location[0] - mapDrawing.offset.x)
              .attr("cy", (d: DrainSpecWithStatus) => d.location[1] - mapDrawing.offset.y)
              .attr("r", (d: DrainSpecWithStatus) => drainDiameter / 2.0)

            d3_circles.exit().remove();
        }
    }, [selectColumn, selectedColumn, mapDrawing.columns, mapDrawing.offset, mapDrawing.obstacles, dispatch, drainDiameter]);

    React.useEffect(() => {
        if (panningRef.current !== null) {

            const updatePanningPosDrag = (dx: number, dy: number) => {
                const [diffX, diffY] = Screen2Map(dx, dy)
                dispatch(kDrainProjectDetailsActions.setMapPos({x: diffX, y: diffY}))
            }

            let temp = d3.select(panningRef.current)
              .selectAll("rect")
              .attr('class', 'mouse-capture')
              .data([{x: 0, y: 0, width: 2 * tx, height: 2 * ty}])
              .attr("x", (d) => d.x)
              .attr("y", (d) => d.y)
              .attr("width", (d) => d.width)
              .attr("height", (d) => d.height)
              .style("fill", "rgba(0, 0, 0, 0.0)")

            temp.enter()
              .append("rect")
              .attr("x", (d) => d.x)
              .attr("y", (d) => d.y)
              .attr("width", (d) => d.width)
              .attr("height", (d) => d.height)

            let startX = 0
            let startY = 0
            const handleDrag = d3.zoom()
              .scaleExtent([1, 1]) // To prevent zooming.
              .on('start', function (event) {
                  if (event !== null) {
                      if (event.sourceEvent === null) return
                      event.sourceEvent.preventDefault();
                      event.sourceEvent.stopPropagation();
                      // Save start position of zooming
                      startX = event.transform.x
                      startY = event.transform.y
                  }
              })
              .on('zoom', function (event) {
                  const dx = event.transform.x - startX
                  const dy = event.transform.y - startY
                  const transform =
                    `translate(${dx},${dy}) `
                    + `translate(${tx},${ty})`
                    + `rotate(${getBearing()})`
                    + `scale(${scale}) `
                    + `translate(${-(getX() - mapDrawing.offset.x)},${-(getY() - mapDrawing.offset.y)})`
                  d3.select(circleGroupRef.current).attr("transform", transform)
                  d3.select(obstacleGroupRef.current).attr("transform", transform)
                  d3.select(labelGroupRef.current).attr("transform", `translate(${dx},${dy}) `)
                  return true
              })
              .on('end', function (event) {
                  const dx = event.transform.x - startX
                  const dy = event.transform.y - startY
                  d3.select(labelGroupRef.current).attr("transform", `translate(0,0) `)
                  updatePanningPosDrag(tx - dx, ty - dy)
              })
            ;
            handleDrag(d3.select(panningRef.current).selectAll("rect"));
        }
    }, [Screen2Map, getBearing, getY, getX, mapDrawing.offset, scale, tx, ty, dispatch]);

    // We are handling column label rendering separately to avoid rotating the text.
    let labels: any[] = [];
    if (scale >= SCALE_LIMIT) {
        // Transform the view rectangle to map coordinates.
        const [x1, y1] = Screen2Map(-size.width, -size.height);
        const [x2, y2] = Screen2Map(2 * size.width, -size.height);
        const [x3, y3] = Screen2Map(2 * size.width, 2 * size.height);
        const [x4, y4] = Screen2Map(-size.width, 2 * size.height);

        // Sort the coordinates to find the bounding box of the rotated viewport
        // expressed in map coordinates.
        //
        //  +---------------------+
        //  |      ____---*       |
        //  |  *---        \      |
        //  |   \           \     |
        //  |    \           \    |
        //  |     \           \   |
        //  |      \    ____---*  |
        //  |       *---          |
        //  +---------------------+
        //
        const boundX = [x1, x2, x3, x4].sort((n1, n2) => n1 - n2);
        const boundY = [y1, y2, y3, y4].sort((n1, n2) => n1 - n2);

        // Now we have a rectangle for querying.
        const queryRect = {
            x: boundX[0],
            y: boundY[0],
            w: boundX[3] - boundX[0],
            h: boundY[3] - boundY[0]
        };

        // Find out which column labels to render.
        const visible = mapDrawing.rtree.search(queryRect as any);
        labels = (visible.map((i: number) => {
            const [lx, ly] = Map2Screen(mapDrawing.columns[i].location[0], mapDrawing.columns[i].location[1]);
            return (<text key={`l${i}`} x={lx} y={ly}
                          style={{
                              transformOrigin: `${lx}px ${ly}px`,
                          }}>
                {mapDrawing.columns[i].name}
            </text>)
        }))
    }

    return (
      <div className="kserv-drawing-map">
          <div className="kserv-drawing-plot-header">
              <div className="kserv-drawing-plot-header-buttons">
                  <Button title={t("common.refresh")} onClick={() => onRefresh()}>
                      <FontAwesomeIcon icon={faSyncAlt} size="3x"/>
                  </Button>
                  <Button title={t("common.legend")}
                          onClick={() => dispatch(updateShowMapObstacles(!showMapObstacles))}>
                      <FontAwesomeIcon icon={showMapObstacles ? faEye : faEyeSlash} size="3x"/>
                  </Button>
                  <Button title={t("map.deSelect")} className={`${selectedColumn ? "" : "kserv-hidden"}`}
                          onClick={() => selectColumn("")}>
                      <FontAwesomeIcon icon={faTimes} size="3x"/>
                  </Button>
                  <Button title={t("map.positionSelected")} className={selectedColumn ? "" : "kserv-hidden"}
                          onClick={() => {
                              const pos = selectedColumn ? {
                                  x: selectedColumn.location[0],
                                  y: selectedColumn.location[1]
                              } : {x: x, y: y}
                              dispatch(kDrainProjectDetailsActions.setMapPos(pos))
                          }}
                  >
                      <FontAwesomeIcon icon={faCrosshairs} size="3x"/>
                  </Button>
                  <Button title={`${enableDrag ? t("map.pan") : t("map.select")}`}
                          className={enableDrag ? "" : "kserv-invert"}
                          onClick={() => setEnableDrag(!enableDrag)}>
                      <FontAwesomeIcon icon={faHandPointer} size="3x"/>
                  </Button>
                  <Button title={t("map.zoomIn")}
                          onClick={() => dispatch(kDrainProjectDetailsActions.setMapScale(scale * 12 / 10))}>
                      <FontAwesomeIcon icon={faSearchPlus} size="3x"/>
                  </Button>
                  <Button title={t("map.zoomOut")}
                          onClick={() => dispatch(kDrainProjectDetailsActions.setMapScale(scale * 10 / 12))}>
                      <FontAwesomeIcon icon={faSearchMinus} size="3x"/>
                  </Button>
                  <Button onClick={() => dispatch(kDrainProjectDetailsActions.applyMapStandardScale())}>
                      <FontAwesomeIcon icon={faCompress} size="3x"/>
                  </Button>

                  <Button onClick={() => dispatch(kDrainProjectDetailsActions.applyMapOverviewScale())}>
                      <FontAwesomeIcon icon={faExpand} size="3x"/>
                  </Button>
              </div>
          </div>
          <div className={`kserv-drawing-map-div ${enableDrag ? "kserv-map-drag" : "kserv-map-select"}`}>
              <svg ref={svgRef} className="kserv-drawing-map-svg noselect">
                  <g className="kserv-drawing-map-columns" transform={transform} ref={circleGroupRef}/>
                  <g className={`kserv-drawing-map-obstacles ${showMapObstacles ? "" : "kserv-hidden"}`}
                     transform={transform} ref={obstacleGroupRef}/>
                  {/* Remove column labels when they become too small. */}
                  <g className={`kserv-drawing-map-columns ${scale < SCALE_LIMIT ? "kserv-hidden" : ""}`}
                     ref={labelGroupRef}>
                      {labels}
                  </g>
                  <g className={`kserv-drawing-map-columns ${enableDrag ? "" : "kserv-hidden"}`} ref={panningRef}/>
              </svg>
          </div>
      </div>
    );
};