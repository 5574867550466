import React, {useMemo, useState} from "react";
import "./style.scss"
import {useTranslation} from "react-i18next";
import {DateRangePicker, DateRangePickerChangeEvent} from "@progress/kendo-react-dateinputs";
import {Button} from "@progress/kendo-react-buttons";

export type DateRange = {
    start: Date | null
    end: Date | null
}

interface KservDateRangerPickerProps {
    onUpdate: (dateRange: DateRange) => void
    minDate: Date
    maxDate: Date
}

export const KservDateRangePicker: React.FunctionComponent<KservDateRangerPickerProps> =
    ({onUpdate, minDate, maxDate}) => {

        const {t} = useTranslation();

        const defaultValue = useMemo(() => ({
            start: minDate,
            end: maxDate
        }), [minDate, maxDate])

        const [dateRange, setDateRange] = useState<DateRange>(defaultValue)

        React.useEffect(() => {
            setDateRange(defaultValue)
            onUpdate(defaultValue)
        }, [defaultValue, onUpdate])

        const onChange = (e: DateRangePickerChangeEvent) => {
            setDateRange(e.value)
            if (e.value.end) {
                onUpdate({start: e.value.start, end: e.value.end})
            }
        }

        const viewAll = () => {
            setDateRange({start: minDate, end: maxDate})
            onUpdate({start: minDate, end: maxDate})
        }

        return (
            <>
                <div className="kserv-date-range-picker">
                    <DateRangePicker
                        value={dateRange}
                        onChange={onChange}
                        format="dd-MMM-yyyy"
                        max={maxDate}
                        min={minDate}
                        id={'startDate'}
                        disabled={false}
                    />
                    <Button className="kserv-date-range-picker-button"
                            onClick={viewAll}>
                        {t('common.viewAll')}
                    </Button>
                </div>
            </>
        )
    }
