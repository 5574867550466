import React, {useEffect} from "react";
import {RouteComponentProps, useHistory} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {machineActions, machineSelectors} from "../../store/machineSlice";
import {Machine, MachineId} from "../../backend/model"
import {useTranslation} from "react-i18next";
import {AppDispatch} from "../../store";
import {EditButton, MachineEditDialog, machineTypeText, PageTitle} from "../../components";
import {Input, TextArea} from "@progress/kendo-react-inputs";
import {Label} from "@progress/kendo-react-labels";

interface RouteParams {
    id: string;
}

export const MachinePage = ({match}: RouteComponentProps<RouteParams>) => {
    const id = parseInt(match.params.id) as MachineId;
    const machine = useSelector(machineSelectors.selectById(id));
    const dispatch: AppDispatch = useDispatch();
    const {t} = useTranslation();

    const history = useHistory();

    const [itemInEdit, setItemInEdit] = React.useState<Machine | undefined>(undefined);

    useEffect(() => {
        dispatch(machineActions.fetchById(id));
    }, [dispatch, id]);

    const editItem = (machine: Machine) => {
        // TODO: Might need a selectAllRequestPending to check before allowing edit
        setItemInEdit({...machine});
    };

    const doDelete = () => {
        if (itemInEdit) {
            dispatch(machineActions.deleteOne(itemInEdit.id))
                .then((_) => setItemInEdit(undefined))
                .then(() => history.push('/machine'))
                .catch((_) => {
                })
        }
    };

    const doRefresh = () => {
        dispatch(machineActions.fetchById(id));
    };

    const doEdit = () => {
        if (itemInEdit) {
            dispatch(machineActions.updateOne(itemInEdit, itemInEdit.id))
                .finally(() => doRefresh())
        }
    };


    const content = machine ? (
        <div>
            <div className="kserv-machine-details-item">
                <Label>{t("machine.type")}</Label>
                <Input
                    value={machine ? machineTypeText(machine.machine_type) : ''}
                />
            </div>
            <div className="kserv-machine-details-item">
                <Label>{t("machine.name")}</Label>
                <Input
                    value={machine ? machine.name : ''}
                />
            </div>
            <div className="kserv-machine-details-item">
                <Label>{t("machine.key")}</Label>
                <TextArea value={machine ? machine.pubkey : ''} rows={5}/>
            </div>
            {itemInEdit && <MachineEditDialog itemInEdit={itemInEdit}
                                              setItemInEdit={setItemInEdit}
                                              onDelete={doDelete}
                                              onEdit={doEdit}/>}
        </div>
    ) : (
        <p>
            {t("common.unknown")}
        </p>
    );

    return (
        <div>
            <PageTitle title={t("machine.machineDetails")}>
                <EditButton<Machine> callback={editItem} dataItem={machine}/>
            </PageTitle>
            <div className="kserv-content">
                {content}
            </div>
        </div>
    );
};
