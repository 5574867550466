import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppThunk, RootState } from ".";
import {ErrorBody} from "../backend/error";

export interface NotificationInterface {
  error: ErrorBody | undefined;
  info: string | undefined;
}

const initialState: NotificationInterface = {
  error: undefined,
  info: undefined,
};

const slice =
  createSlice({
    name: "notification",
    initialState,
    reducers: {
      setErrorNotification: (state, action: PayloadAction<ErrorBody|undefined>) => {
        state.error = action.payload;
      },
      setInfoNotification: (state, action: PayloadAction<string|undefined>) => {
        state.info = action.payload;
      }
    },
  });

const { setErrorNotification, setInfoNotification } = slice.actions;

export const updateErrorNotification = (state: ErrorBody): AppThunk => (dispatch, getState, _) => {
  dispatch(setErrorNotification(state));
}

export const clearErrorNotification = (): AppThunk => (dispatch, getState, _) => {
  dispatch(setErrorNotification(undefined));
}

export const updateInfoNotification = (state: string): AppThunk => (dispatch, getState, _) => {
  dispatch(setInfoNotification(state));
}

export const clearSuccessNotification = (): AppThunk => (dispatch, getState, _) => {
  dispatch(setInfoNotification(undefined));
}


export const selectErrorNotification = (state: RootState) => state.notification.error;
export const selectInfoNotification = (state: RootState) => state.notification.info;

export default slice.reducer;


