import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { customerActions, customerSelectors } from "../../store/customerSlice";
import { AppDispatch } from "../../store";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { Customer } from "../../backend/model";
import { CustomerGrid } from "./customer-grid";
import {PageTitle} from "../../components";

export const CustomersPage = () => {
  const customers = useSelector(customerSelectors.selectAll());
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(customerActions.fetchAll());
  }, [dispatch]);

  const doDetails = (dataItem: Customer) => {
    history.push('/customer/' +  dataItem.id)
  };

  const doDelete = (dataItem: Customer) => {
    dispatch(customerActions.deleteOne(dataItem.id))
      .then((_) => dispatch(customerActions.fetchAll()))
      .catch((_) => {})
  };

  const doRefresh = () => {
    dispatch(customerActions.fetchAll());
  };

  const doEdit = (dataItem: Customer) => {
    dispatch(customerActions.updateOne(dataItem, dataItem.id))
      .finally(() => doRefresh())
  };

  const doPost = (dataItem: Customer) => {
    dispatch(customerActions.postOne(dataItem))
      .then(id => dispatch(customerActions.fetchById(id)))
      .catch((_) => {})
  };

  return (
    <div>
      <PageTitle title={t("customer.title")} />
      <div className="kserv-content">
        <CustomerGrid value={customers} onAdd={doPost} onEdit={doEdit} onRefresh={doRefresh} onDelete={doDelete} onDetails={doDetails}/>
      </div>
    </div>
  );
};
