import React, {useEffect} from "react";
import {Grid, GridToolbar} from "@progress/kendo-react-grid";

import {DateCell, LoadingPanel, RefreshButton, ToggleButton} from "../../../components";

import {faFilter} from "@fortawesome/free-solid-svg-icons";
import {GridColumn as Column} from "@progress/kendo-react-grid/dist/npm/GridColumn";
import {useTranslation} from "react-i18next";
import {UnitCell} from "../../../components/custom-cell/unit-cell";
import {DownloadCell} from "../../../components/custom-cell/download-cell";
import {RemoveCell} from "../../../components/custom-cell/remove-cell";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch} from "../../../store";
import {exportActions, exportSelectors} from "../../../store/exportSlice";
import {ProjectId, ProjectRole} from "../../../backend/model";
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {kRegProjectDetailsActions} from "../../../store/kRegProjectDetailsSlice";

interface BatchJobGridProps {
    id: ProjectId
}

export const BatchJobGrid: React.FunctionComponent<BatchJobGridProps> = (props) => {

    const {t} = useTranslation()
    const dispatch: AppDispatch = useDispatch();
    const [showFilters, setShowFilters] = React.useState(false);
    const [userIsAdmin, setUserIsAdmin] = React.useState(false)

    const initialSort: SortDescriptor[] = [{field: 'start_time', dir: 'desc'}];

    const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
    const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);

    const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
    const batchJobs = useSelector(exportSelectors.selectProjectColBatchJob(props.id))
    const dataLoading = useSelector(exportSelectors.selectByIdRequestPending(props.id))

    useEffect(() => {
        dispatch(kRegProjectDetailsActions.getMyProjectRole(props.id))
            .then(role => setUserIsAdmin(role === ProjectRole.admin))
            .catch(() => {
            })
        return (() => {
        })
    }, [dispatch, props.id, setUserIsAdmin]);

    const doRefresh = () => {
        dispatch(exportActions.getColReportBatchStatus(props.id))
            .catch(() => {
            })
    }

    const doDownloadBatch = (pid: number, comment: string) => {
        dispatch(exportActions.getBatchReportUrl(props.id, pid))
            .then(url => window.open(`${url}&file_name=${encodeURIComponent(comment)}.zip`))
            .catch(() => {
            })
    }

    const doStopBatch = (pid: number) => {
        dispatch(exportActions.stopColReportBatch(props.id, pid))
            .then(() => doRefresh())
            .catch(() => {
            })
    }

    const gridData = () => {
        return batchJobs.map((e) => {
            return {
                ...e,
                progress: Math.round(100 * (e.processed_columns / e.nbr_of_columns))
            }
        })
    }

    const gridName = "kserv-batch-job-grid"
    const showGrid = (batchJobs && batchJobs.length > 0)

    return (
        <> {showGrid &&
        <Grid
            className={gridName}
            data={filterBy(orderBy(gridData(), sort), filter)}
            sortable
            sort={sort}
            onSortChange={(e) => setSort(e.sort)}
            filterable={showFilters}
            filter={filter}
            resizable
            onFilterChange={(e) => setFilter(e.filter)}
        >
            <GridToolbar>
                <h3>{t("project.details.batchJobs")}</h3>
                <div className="kserv-grid-toolbar">
                    <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
                    <RefreshButton callback={doRefresh}/>
                </div>
            </GridToolbar>
            <Column field="start_time" title={t("project.details.startTime")} filterable={false} format="{0:d}"
                    cell={(p) => <DateCell {...p} time={p.dataItem.start_time}/>}/>
            <Column field="comment" title={t("project.details.comment")} filter={'numeric'}/>
            <Column field="job_type" title={t("export.formatText")} filter={'text'}/>
            <Column field="nbr_of_columns" title={t("project.details.nbrOfColumns")} filter={'numeric'}/>
            <Column field="processed_columns" title={t("project.details.processColumns")} filter={'numeric'}/>
            <Column field="progress" title={" "} filterable={false} width="80px"
                    cell={(p) => <UnitCell {...p} unit={" %"}/>}/>
            <Column field="status" title={t("project.details.state")} filter={'text'}/>
            <Column field="error" title={t("error.title")} filter={'text'}/>
            <Column field="download_cell" title={t("common.download")} filterable={false} sortable={false} width="80px"
                    cell={(p) => <DownloadCell {...p} callback={doDownloadBatch}/>}/>
            <Column field="delete_cell" title={t("common.delete")} filterable={false} sortable={false} width="80px"
                    cell={(p) => <RemoveCell {...p} disabled={!userIsAdmin} callback={doStopBatch}/>}/>

        </Grid>}
            {dataLoading && <LoadingPanel name={gridName}/>}
        </>
    )
}