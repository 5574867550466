//Simple "newtype" pattern solution, to distinguish different Id's by type.
// https://github.com/Microsoft/TypeScript/issues/4895#issuecomment-401058832
import {ColumnStateEnum} from "./projectStatus";
import {KRegProjectPreferences} from "./preferences"

declare class IdTag<S extends string> {
    private tag: S;
}

export type Id<S extends string> = number & IdTag<S>;

export enum MachineType {
    KReg = "kreg",
    KDrain = "kdrain"
}

export type MachineId = Id<"Machine">;

export interface Machine {
    id: MachineId;
    name: string;
    pubkey: string;
    machine_type: MachineType
}

export type CustomerId = Id<"Customer">;

export interface Customer {
    id: CustomerId;
    name: string;
}

export type FabricTypeId = Id<"FabricType">;

export interface FabricType {
    id: FabricTypeId;
    name: string;
}

export type ProjectId = Id<"Project">;

export interface ProjectBase {
    id: ProjectId;
    customer_id: CustomerId;
    user_id: UserId;
    name: string;
    keller_id: string;
    location: string;
    active: boolean;
    machine_type: MachineType
}

export interface KRegProjectSettings {
    lower_density_tolerance: number;
    upper_density_tolerance: number;
    column_top_tolerance: number;
    transition_length: number;
    target_lift_coefficient: number;
    target_rpm: number;
}

export interface KRegProject extends ProjectBase {
    glide: number;
    dpi_preview: number;
    dpi_export: number;
    settings: KRegProjectSettings;
    preferences: KRegProjectPreferences
}

export interface KDrainProjectSettings {
    fabric_types: string[];
    depth_tolerance: number;
    drain_diameter: number;
}

export interface KDrainProject extends ProjectBase {
    settings: KDrainProjectSettings
}


export enum ProjectRole {
    admin = "admin",
    user = "user",
    guest = "guest",
    none = "none"
}

export type ProjectRoles = Record<number, ProjectRole | undefined>

export enum UserRoles {
    admin = "admin",
    user = "user",
    guest = "guest"
}

export type UserId = Id<"User">;

export interface User {
    id: UserId;
    name: string;
    email: string;
    role: UserRoles;
    is_active: boolean;
    total_dashboard: boolean;
}

export type MachineAdmissionId = Id<"MachineAdmission">;

export interface MachineAdmission {
    id: MachineAdmissionId;
    machine_name: string;
    machine_type: MachineType;
    pubkey: string;
    time: Date;
}

export const missingCustomerId = -1 as CustomerId;
export const missingMachineId = -1 as MachineId;
export const missingProjectId = -1 as ProjectId;
export const missingMachineAdmissionId = -1 as MachineAdmissionId;
export const missingUserId = -1 as UserId;
export const missingFabricTypeId = -1 as FabricTypeId

export interface EditColumnLog {
    "hash": string
    "project_id": number
    "old_column_name": string
    "new_column_name": string
    "new_state": ColumnStateEnum
    "annotation": {
        depth: number,
        operator: string
        note: string
    }
}

export interface EditDrainLog {
    "hash": string
    "project_id": number
    "old_drain_name": string
    "new_drain_name": string
    "new_state": ColumnStateEnum
    "new_fail_reason": string
    "new_water_used": boolean
    "new_fabric": string
    "annotation": {
        depth: number,
        operator: string
        note: string
    }
}