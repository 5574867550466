import {MachineType} from "../backend/model";

export * from "./add-btn";
export * from "./add-dialog";
export * from "./history-grid";
export * from "./custom-cell"
export * from "./custom-grid-filter";
export * from "./customer-edit-dialog";
export * from "./delete-dialog"
export * from "./date-range-picker"
export * from "./edit-btn"
export * from "./edit-dialog"
export * from "./kserv-notification";
export * from "./chip-list";
export * from "./loading-panel";
export * from './keller-logo';
export * from "./machine-edit-dialog";
export * from "./navigation-bar";
export * from "./project-edit-dialog";
export * from "./refresh-btn";
export * from "./toggle-btn";
export * from "./user-edit-dialog";
export * from "./page-title"

export const machineTypeText = (m: MachineType) : string => {
    switch (m) {
        case MachineType.KReg:
            return "KReg"
        case MachineType.KDrain:
            return "KDrain"
    }
}