import React from "react";
import {Button} from "@progress/kendo-react-buttons";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {useSelector} from "react-redux";
import {selectIsUserAdmin} from "../../store/loginSlice";

interface EditProps<R> {
  callback: (p: R) => void
  dataItem: R | undefined
}

export const EditButton: <T>(p: EditProps<T>) => React.ReactElement<EditProps<T>> = (p) => {

  const {t} = useTranslation()
  const isAdmin = useSelector(selectIsUserAdmin);

  return (
    <Button
      disabled={!isAdmin}
      title={t("common.edit")}
      className="k-button kserv-button"
      onClick={() => p.dataItem ? p.callback(p.dataItem) : ""}>
      <FontAwesomeIcon icon={faEdit}/>
    </Button>
  )
}
