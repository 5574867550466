import {AppThunk, RootState} from ".";
import {createRequestStateAdapter, fetchAllRequestId} from "./util/requestStateAdapter";
import {missingProjectId, ProjectId} from "../backend/model";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {updateErrorNotification} from "./notificationSlice";
import {ColumnFilter, KDrainColumnLogs} from "../backend/column_log";

const requestStateAdapter = createRequestStateAdapter();

type State = {
  id: ProjectId;
  filters: ColumnFilter[];
  logs: KDrainColumnLogs;
  postResult?: ProjectId;
} & typeof requestStateAdapter.initialState;


const initialState: State = {
  id: missingProjectId,
  logs: {},
  filters: [],
  ...requestStateAdapter.initialState,
};

const slice =
  createSlice({
    name: "column",
    initialState,
    reducers: {
      setColumnLogData: (state, action: PayloadAction<{ id: ProjectId, data: KDrainColumnLogs }>) => {
        if (action.payload.id !== state.id) {
          state.logs = {}
          state.id = action.payload.id
        }

        if (Object.keys(action.payload.data).length === 0) {
          state.logs = {}
        } else {
          for (const hash of Object.keys(action.payload.data)) {
            state.logs[hash] = action.payload.data[hash]
          }
        }
      },
      clearColumnData: (state) => {
        state.logs = {}
      },
      setColumnFilters: (state, action: PayloadAction<{ id: ProjectId, filters: ColumnFilter[] }>) => {
        if (action.payload.id !== state.id) {
          state.filters = []
          state.id = action.payload.id
        }

        if (!action.payload.filters || action.payload.filters.length === 0) {
          state.filters = []
        } else {
          state.filters = [...action.payload.filters]
        }
      },
      setPending: requestStateAdapter.setPending,
      setError: requestStateAdapter.setError,
      setPostResult: (state, action: PayloadAction<ProjectId>) => {
        state.postResult = action.payload;
      },
    },
  });


const {setPending, setColumnLogData, clearColumnData, setColumnFilters} = slice.actions;

const fetchColumnRawData = (id: ProjectId, column_id: string): AppThunk<Promise<KDrainColumnLogs>> => (dispatch, getState, {api}) => {
  dispatch(setPending(id, true));

  const fetchPromise = api.kdrain_column.getColumnRawData(id, column_id);
  fetchPromise
    .then((logs) => {
      dispatch(setColumnLogData({id: id, data: logs}))
    })
    .catch(err => dispatch(updateErrorNotification(err)))
    .finally(() => dispatch(setPending(id, false)));
  return fetchPromise;
};


const clearColumnRawData = (): AppThunk => (dispatch, getState, {api}) => {
  dispatch(clearColumnData())
  return;
};

const fetchColumnFilters = (id: ProjectId, column_id: string): AppThunk<Promise<ColumnFilter[]>> => (dispatch, getState, {api}) => {
  dispatch(setPending(id, true));

  const fetchPromise = api.kdrain_column.getColumnFilters(id, column_id);
  fetchPromise
    .then((filters) => {
      dispatch(setColumnFilters({id: id, filters: filters}))
    })
    .catch(err => dispatch(updateErrorNotification(err)))
    .finally(() => dispatch(setPending(id, false)));
  return fetchPromise;
};

const addColumnFilters = (id: ProjectId, column_id: string, filter: ColumnFilter): AppThunk<Promise<void>> => (dispatch, getState, {api}) => {
  dispatch(setPending(id, true));

  const fetchPromise = api.kdrain_column.postColumnFilter(id, column_id, filter);
  fetchPromise
    .catch(err => dispatch(updateErrorNotification(err)))
    .finally(() => dispatch(setPending(id, false)));
  return fetchPromise;
};

export const kDrainColumnActions = {
  fetchColumnRawData,
  clearColumnRawData,
  fetchColumnFilters,
  addColumnFilters
};


export const kDrainColumnSelectors = {
  selectPostResult: () => (state: RootState) => state.kdrain_column.postResult,
  selectByIdRequestPending: (id: ProjectId) => (state: RootState) => state.kdrain_column.pending[id],
  selectByIdRequestError: (id: ProjectId) => (state: RootState) => state.kdrain_column.error[id],
  selectAllRequestPending: () => (state: RootState) => state.kdrain_column.pending[fetchAllRequestId],
  selectAllRequestError: () => (state: RootState) => state.kdrain_column.error[fetchAllRequestId],
  selectColumnFilters: (id: ProjectId) => (state: RootState) => {
    if (id !== state.kdrain_column.id) {
      return []
    }
    return state.kdrain_column.filters
  },
  selectColumnLogs: (id: ProjectId) => (state: RootState) => {
    if (id !== state.kdrain_column.id) {
      return {}
    }
    return state.kdrain_column.logs
  },
}
export default slice.reducer;
