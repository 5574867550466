import {
    Customer,
    CustomerId,
    KDrainProject,
    KRegProject,
    Machine,
    MachineAdmission,
    MachineAdmissionId,
    MachineId,
    MachineType,
    ProjectBase,
    ProjectId,
    ProjectRole,
    ProjectRoles,
    User,
    UserId,
    FabricType,
    FabricTypeId, EditDrainLog, EditColumnLog
} from "./model";
import {checkStatus} from "./error";
import {Authenticator, TokenReceiver} from "./authenticator";
import {
    DrawingHistory,
    DrawingUploadResponse,
    KDrainDrawing,
    KRegDrawing,
    LogUploadResponse,
    Obstacles
} from "./drawing";
import {ProjectStatusUpdate} from "./projectStatus";
import {
    ColumnFilter,
    KRegColumnLogs,
    ColumnUpdate,
    DrainHistory,
    DrainReportSummary,
    ColumnHistoryEntry,
    KRegColumnReportSummary, KDrainColumnLogs
} from "./column_log";
import {
    DetailedKDrainProjectSummary,
    DetailedKRegProjectSummary,
    GlobalKDrainProjectSummary,
    GlobalKRegProjectSummary,
    KservAPI
} from "./summary";
import {BatchJob} from "./batch_job";

async function handleJSONResponse(response: Response) {
    const json = await response.json()
    return json.payload
}

async function handleBlobResponse(response: Response) {
    return await response.blob()
}

async function get<R>(url: string, bearerToken: string, accept = "application/json", responseHandler = handleJSONResponse): Promise<R> {
    return await fetch(url, {
        method: "GET",
        headers: {
            Accept: `${accept}`,
            "X-Requested-With": "XmlHttpRequest",
            Authorization: `Bearer ${bearerToken}`,
        }
    })
        .then(checkStatus)
        .then((response) => responseHandler(response))
}


async function post<P, R>(url: string, bearerToken: string, payload: P, accept = "application/json", responseHandler = handleJSONResponse): Promise<R> {
    return await fetch(url, {
        method: "POST",
        headers: {
            Accept: `${accept}`,
            "Content-Type": "application/json",
            "X-Requested-With": "XmlHttpRequest",
            Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then((response) => responseHandler(response))

}

async function postFile<R>(url: string, bearerToken: string, payload: any, response = true): Promise<R> {
    return await fetch(url, {
        method: "POST",
        headers: {
            Authorization: `Bearer ${bearerToken}`,
        },
        body: payload,
    })
        .then(checkStatus)
        .then((server_response) => {
            if (response) {
                return handleJSONResponse(server_response)
            }
            return
        })
}

async function put<P, R>(url: string, bearerToken: string, payload: P): Promise<R> {
    return await fetch(url, {
        method: "PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XmlHttpRequest",
            Authorization: `Bearer ${bearerToken}`,
        },
        body: JSON.stringify(payload),
    })
        .then(checkStatus)
        .then((response) => handleJSONResponse(response))
}

async function deletePost(url: string, bearerToken: string): Promise<void> {
    await fetch(url, {
        method: "DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            "X-Requested-With": "XmlHttpRequest",
            Authorization: `Bearer ${bearerToken}`,
        },
    })
        .then(checkStatus)
}

export interface EntityEndpoint<EntityType, IdType> {
    getOne: (id: IdType) => Promise<EntityType>;
    getAll: () => Promise<EntityType[]>;
    postOne: (e: EntityType) => Promise<IdType>;
    putOne: (e: EntityType, id: IdType) => Promise<IdType>;
    deleteOne: (id: IdType) => Promise<void>;
}

const createEntityEndpoint = <EntityType, IdType>(
    prefix: string,
    endpoint: string,
    tokenReceiver: TokenReceiver
): EntityEndpoint<EntityType, IdType> => ({
    getOne: async (id: IdType) => get<EntityType>(`${prefix}${endpoint}/${id}`, await tokenReceiver.getAccessToken()),
    getAll: async () => get<EntityType[]>(`${prefix}${endpoint}`, await tokenReceiver.getAccessToken()),
    postOne: async (entity: EntityType) =>
        post<EntityType, IdType>(`${prefix}${endpoint}`, await tokenReceiver.getAccessToken(), entity),
    putOne: async (entity: EntityType, id: IdType) =>
        put<EntityType, IdType>(`${prefix}${endpoint}/${id}`, await tokenReceiver.getAccessToken(), entity),
    deleteOne: async (id: IdType) => deletePost(`${prefix}${endpoint}/${id}`, await tokenReceiver.getAccessToken()),
});

export interface NewProjectEndpoints {
    postKRegProject: (project: KRegProject) => Promise<ProjectId>;
    postKDrainProject: (project: KDrainProject) => Promise<ProjectId>;
}

const createEndpointNewProject = (
    prefix: string,
    tokenReceiver: TokenReceiver
): NewProjectEndpoints => ({
    postKRegProject: async (project) => post<KRegProject, ProjectId>(`${prefix}project/kreg`, await tokenReceiver.getAccessToken(), project),
    postKDrainProject: async (project) => post<KDrainProject, ProjectId>(`${prefix}project/kdrain`, await tokenReceiver.getAccessToken(), project)
});

export interface EndpointMachineAdmission {
    getAllAdmissions: () => Promise<MachineAdmission[]>;
    rejectAdmission: (id: MachineAdmissionId) => Promise<void>;
}

const createEndpointMachineAdmission = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointMachineAdmission => ({
    getAllAdmissions: async () => get<MachineAdmission[]>(`${prefix}machine_admission`, await tokenReceiver.getAccessToken()),
    rejectAdmission: async (id: MachineAdmissionId) =>
        deletePost(`${prefix}machine_admission/${id}`, await tokenReceiver.getAccessToken()),
});

export interface EndpointKRegProjectDetails {
    getProject: (id: ProjectId) => Promise<KRegProject>;
    putProject: (project: KRegProject) => Promise<ProjectId>;
    getDrawing: (id: ProjectId) => Promise<KRegDrawing>;
    postDrawing: (id: ProjectId, filename: string, file: any, query: string) => Promise<DrawingUploadResponse>;
    getObstacles: (id: ProjectId) => Promise<Obstacles>;
    postObstacles: (id: ProjectId, file: any) => Promise<void>;
    postLog: (id: ProjectId, hash: string, file: any) => Promise<LogUploadResponse>;
    editLog: (editLog: EditColumnLog) => Promise<void>;
    getUpdate: (id: ProjectId, timestamp?: string | undefined) => Promise<ProjectStatusUpdate>;
    getAggregate: (id: ProjectId) => Promise<KRegColumnReportSummary[]>;
    getColumnUpdate: (id: ProjectId) => Promise<ColumnUpdate[]>;
    getDrawingHistory: (id: ProjectId) => Promise<DrawingHistory>;
    getColumnHistory: (id: ProjectId, query: string) => Promise<ColumnHistoryEntry[]>;
    getRoles: (id: ProjectId) => Promise<ProjectRoles>;
    postRoles: (id: ProjectId, roles: ProjectRoles) => Promise<void>;
    getMyRole: (id: ProjectId) => Promise<ProjectRole>;
    startLogReprocess: (id: ProjectId) => Promise<void>;
    getLogReprocess: (id: ProjectId) => Promise<BatchJob[]>;
    deleteLogReprocess: (id: ProjectId, pid: number) => Promise<void>;
}

const createEndpointKRegProjectDetails = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointKRegProjectDetails => ({
    getProject: async (id: ProjectId) =>
        get<KRegProject>(`${prefix}project/kreg/${id}`, await tokenReceiver.getAccessToken()),
    putProject: async (project: KRegProject) =>
        put<KRegProject, ProjectId>(`${prefix}project/kreg/${project.id}`, await tokenReceiver.getAccessToken(), project),
    getDrawing: async (id: ProjectId) =>
        get<KRegDrawing>(`${prefix}project/kreg/${id}/drawing`, await tokenReceiver.getAccessToken()),
    postDrawing: async (id: ProjectId, filename: string, file: any, query: string) =>
        postFile<DrawingUploadResponse>(`${prefix}project/kreg/${id}/drawing/${filename}${query}`, await tokenReceiver.getAccessToken(), file),
    getObstacles: async (id: ProjectId) =>
        get<Obstacles>(`${prefix}project/kreg/${id}/obstacles`, await tokenReceiver.getAccessToken()),
    postObstacles: async (id: ProjectId, file: any) =>
        postFile<void>(`${prefix}project/kreg/${id}/obstacles`, await tokenReceiver.getAccessToken(), file, false),
    postLog: async (id: ProjectId, hash: string, file: any) =>
        postFile<LogUploadResponse>(`${prefix}project/kreg/${id}/logupload/${hash}`, await tokenReceiver.getAccessToken(), file),
    editLog: async (editLog: EditColumnLog) =>
        post<EditColumnLog, void>(`${prefix}project/kreg/${editLog.project_id}/editlog`, await tokenReceiver.getAccessToken(), editLog),
    getUpdate: async (id: ProjectId, query = "") =>
        get<ProjectStatusUpdate>(`${prefix}project/kreg/${id}/status${query}`, await tokenReceiver.getAccessToken()),
    getAggregate: async (id: ProjectId) =>
        get<KRegColumnReportSummary[]>(`${prefix}project/kreg/${id}/aggregate`, await tokenReceiver.getAccessToken()),
    getColumnUpdate: async (id: ProjectId) =>
        get<ColumnUpdate[]>(`${prefix}project/kreg/${id}/column_updates`, await tokenReceiver.getAccessToken()),
    getDrawingHistory: async (id: ProjectId) =>
        get<DrawingHistory>(`${prefix}project/kreg/${id}/drawing_history`, await tokenReceiver.getAccessToken()),
    getColumnHistory: async (id: ProjectId, query: string) =>
        get<ColumnHistoryEntry[]>(`${prefix}project/kreg/${id}/column_history${query}`, await tokenReceiver.getAccessToken()),
    getRoles: async (id) =>
        get<ProjectRoles>(`${prefix}project/kreg/${id}/roles`, await tokenReceiver.getAccessToken()),
    postRoles: async (id, roles) =>
        post<ProjectRoles, void>(`${prefix}project/kreg/${id}/roles`, await tokenReceiver.getAccessToken(), roles),
    getMyRole: async (id) =>
        get<ProjectRole>(`${prefix}project/kreg/${id}/myrole`, await tokenReceiver.getAccessToken()),
    startLogReprocess: async (id) =>
        post<{}, void>(`${prefix}project/kreg/${id}/logreprocess`, await tokenReceiver.getAccessToken(), {}),
    getLogReprocess: async (id) =>
        get<BatchJob[]>(`${prefix}project/kreg/${id}/logreprocess`, await tokenReceiver.getAccessToken()),
    deleteLogReprocess: async (id, pid) =>
        deletePost(`${prefix}project/kreg/${id}/logreprocess/${pid}`, await tokenReceiver.getAccessToken()),
});

export interface EndpointKDrainProjectDetails {
    getProject: (id: ProjectId) => Promise<KDrainProject>;
    putProject: (project: KDrainProject) => Promise<ProjectId>;
    getDrawing: (id: ProjectId) => Promise<KDrainDrawing>;
    postDrawing: (id: ProjectId, filename: string, file: any, query: string) => Promise<DrawingUploadResponse>;
    getObstacles: (id: ProjectId) => Promise<Obstacles>;
    postObstacles: (id: ProjectId, file: any) => Promise<void>;
    postLog: (id: ProjectId, hash: string, file: any) => Promise<LogUploadResponse>;
    editLog: (editLog: EditDrainLog) => Promise<void>;
    getUpdate: (id: ProjectId, timestamp?: string | undefined) => Promise<ProjectStatusUpdate>;
    getAggregate: (id: ProjectId) => Promise<DrainReportSummary[]>;
    getColumnUpdate: (id: ProjectId) => Promise<ColumnUpdate[]>;
    getDrawingHistory: (id: ProjectId) => Promise<DrawingHistory>;
    getDrainHistory: (id: ProjectId, query: string) => Promise<DrainHistory>;
    getRoles: (id: ProjectId) => Promise<ProjectRoles>;
    postRoles: (id: ProjectId, roles: ProjectRoles) => Promise<void>;
    getMyRole: (id: ProjectId) => Promise<ProjectRole>;
    startLogReprocess: (id: ProjectId) => Promise<void>;
    getLogReprocess: (id: ProjectId) => Promise<BatchJob[]>;
    deleteLogReprocess: (id: ProjectId, pid: number) => Promise<void>;
}

const createEndpointKDrainProjectDetails = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointKDrainProjectDetails => ({
    getProject: async (id: ProjectId) =>
        get<KDrainProject>(`${prefix}project/kdrain/${id}`, await tokenReceiver.getAccessToken()),
    putProject: async (project: KDrainProject) =>
        put<KDrainProject, ProjectId>(`${prefix}project/kdrain/${project.id}`, await tokenReceiver.getAccessToken(), project),
    getDrawing: async (id: ProjectId) =>
        get<KDrainDrawing>(`${prefix}project/kdrain/${id}/drawing`, await tokenReceiver.getAccessToken()),
    postDrawing: async (id: ProjectId, filename: string, file: any, query: string) =>
        postFile<DrawingUploadResponse>(`${prefix}project/kdrain/${id}/drawing/${filename}${query}`, await tokenReceiver.getAccessToken(), file),
    getObstacles: async (id: ProjectId) =>
        get<Obstacles>(`${prefix}project/kdrain/${id}/obstacles`, await tokenReceiver.getAccessToken()),
    postObstacles: async (id: ProjectId, file: any) =>
        postFile<void>(`${prefix}project/kdrain/${id}/obstacles`, await tokenReceiver.getAccessToken(), file, false),
    postLog: async (id: ProjectId, hash: string, file: any) =>
        postFile<LogUploadResponse>(`${prefix}project/kdrain/${id}/logupload/${hash}`, await tokenReceiver.getAccessToken(), file),
    editLog: async (editLog: EditDrainLog) =>
        post<EditDrainLog, void>(`${prefix}project/kdrain/${editLog.project_id}/editlog`, await tokenReceiver.getAccessToken(), editLog),
    getUpdate: async (id: ProjectId, query = "") =>
        get<ProjectStatusUpdate>(`${prefix}project/kdrain/${id}/status${query}`, await tokenReceiver.getAccessToken()),
    getAggregate: async (id: ProjectId) =>
        get<DrainReportSummary[]>(`${prefix}project/kdrain/${id}/aggregate`, await tokenReceiver.getAccessToken()),
    getColumnUpdate: async (id: ProjectId) =>
        get<ColumnUpdate[]>(`${prefix}project/kdrain/${id}/column_updates`, await tokenReceiver.getAccessToken()),
    getDrawingHistory: async (id: ProjectId) =>
        get<DrawingHistory>(`${prefix}project/kdrain/${id}/drawing_history`, await tokenReceiver.getAccessToken()),
    getDrainHistory: async (id: ProjectId, query: string) =>
        get<DrainHistory>(`${prefix}project/kdrain/${id}/drain_history${query}`, await tokenReceiver.getAccessToken()),
    getRoles: async (id) =>
        get<ProjectRoles>(`${prefix}project/kdrain/${id}/roles`, await tokenReceiver.getAccessToken()),
    postRoles: async (id, roles) =>
        post<ProjectRoles, void>(`${prefix}project/kdrain/${id}/roles`, await tokenReceiver.getAccessToken(), roles),
    getMyRole: async (id) =>
        get<ProjectRole>(`${prefix}project/kdrain/${id}/myrole`, await tokenReceiver.getAccessToken()),
    startLogReprocess: async (id) =>
        post<{}, void>(`${prefix}project/kdrain/${id}/logreprocess`, await tokenReceiver.getAccessToken(), {}),
    getLogReprocess: async (id) =>
        get<BatchJob[]>(`${prefix}project/kdrain/${id}/logreprocess`, await tokenReceiver.getAccessToken()),
    deleteLogReprocess: async (id, pid) =>
        deletePost(`${prefix}project/kdrain/${id}/logreprocess/${pid}`, await tokenReceiver.getAccessToken()),
});

export interface EndpointSummary {
    getKRegProjectDashboard: (id: ProjectId) => Promise<DetailedKRegProjectSummary>;
    getKRegTotalDashboard: (year: number) => Promise<GlobalKRegProjectSummary>;
    getKDrainProjectDashboard: (id: ProjectId) => Promise<DetailedKDrainProjectSummary>;
    getKDrainTotalDashboard: (year: number) => Promise<GlobalKDrainProjectSummary>;
}

const createEndpointSummary = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointSummary => ({
    getKRegProjectDashboard: async (id: ProjectId) =>
        get<DetailedKRegProjectSummary>(`${prefix}project/kreg/${id}/summary`, await tokenReceiver.getAccessToken()),
    getKRegTotalDashboard: async (year: number) =>
        get<GlobalKRegProjectSummary>(`${prefix}project/kreg/summary/${year}`, await tokenReceiver.getAccessToken()),
    getKDrainProjectDashboard: async (id: ProjectId) =>
        get<DetailedKDrainProjectSummary>(`${prefix}project/kdrain/${id}/summary`, await tokenReceiver.getAccessToken()),
    getKDrainTotalDashboard: async (year: number) =>
        get<GlobalKDrainProjectSummary>(`${prefix}project/kdrain/summary/${year}`, await tokenReceiver.getAccessToken())
});


export interface EndpointKRegExport {
    exportColReport: (id: ProjectId, query: string, payload: ColumnFilter) => Promise<Blob>;
    startBatchReport: (id: ProjectId, query: string, payload: ColumnFilter) => Promise<void>;
    getBatchReportStatus: (id: ProjectId) => Promise<BatchJob[]>;
    getBatchReportUrl: (id: ProjectId, pid: number) => Promise<string>;
    stopBatchReport: (id: ProjectId, pid: number) => Promise<void>;
    exportKRegProductionReport: (id: ProjectId, query: string) => Promise<Blob>;
    exportKDrainProductionReport: (id: ProjectId, query: string) => Promise<Blob>;
}

const createEndpointKRegExport = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointKRegExport => ({
    exportColReport: async (id: ProjectId, query: string, payload: ColumnFilter) =>
        post<ColumnFilter, Blob>(`${prefix}project/kreg/${id}/col_report/export${query}`, await tokenReceiver.getAccessToken(), payload, "application/pdf", handleBlobResponse),
    startBatchReport: async (id: ProjectId, query: string, payload: ColumnFilter) =>
        post<ColumnFilter, void>(`${prefix}project/kreg/${id}/col_batch_report${query}`, await tokenReceiver.getAccessToken(), payload, "application/pdf"),
    getBatchReportStatus: async (id: ProjectId) =>
        get<BatchJob[]>(`${prefix}project/kreg/${id}/col_batch_report`, await tokenReceiver.getAccessToken()),
    getBatchReportUrl: async (id: ProjectId, pid: number) =>
        get<string>(`${prefix}project/kreg/${id}/col_batch_report/${pid}`, await tokenReceiver.getAccessToken(), "application/zip")
            .then(path => `${prefix}${path}`),
    stopBatchReport: async (id: ProjectId, pid: number) =>
        deletePost(`${prefix}project/kreg/${id}/col_batch_report/${pid}`, await tokenReceiver.getAccessToken()),
    exportKRegProductionReport: async (id: ProjectId, query: string) =>
        get<Blob>(`${prefix}project/kreg/${id}/prod_report/export${query}`, await tokenReceiver.getAccessToken(), "application/pdf", handleBlobResponse),
    exportKDrainProductionReport: async (id: ProjectId, query: string) =>
        get<Blob>(`${prefix}project/kdrain/${id}/prod_report/export${query}`, await tokenReceiver.getAccessToken(), "application/pdf", handleBlobResponse),
});

export interface EndpointApplication {
    getAPIVersion: () => Promise<KservAPI>;
    getDocUserManual: () => Promise<Blob>;
}

const createEndpointApplication = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointApplication => ({
    getAPIVersion: async () =>
        get<KservAPI>(`${prefix}version`, await tokenReceiver.getAccessToken()),
    getDocUserManual: async () =>
        get<Blob>(`${prefix}doc/user-manual`, await tokenReceiver.getAccessToken(), "application/pdf", handleBlobResponse),

});

export interface EndpointColumn<LogsType> {
    getColumnRawData: (id: ProjectId, column_id: string) => Promise<LogsType>
    getColumnFilters: (id: ProjectId, column_id: string) => Promise<ColumnFilter[]>
    postColumnFilter: (id: ProjectId, column_id: string, filters: ColumnFilter) => Promise<void>
}

function createEndpointColumn<LogsType>(
    prefix: string,
    tokenReceiver: TokenReceiver,
    machineType: MachineType
): EndpointColumn<LogsType> {
    return ({
        getColumnRawData: async (id: ProjectId, column_id: string) =>
            get<LogsType>(`${prefix}project/${machineType}/${id}/rawdata?id=${column_id}`, await tokenReceiver.getAccessToken()),
        getColumnFilters: async (id: ProjectId, column_id: string) =>
            get<ColumnFilter[]>(`${prefix}project/${machineType}/${id}/filter?id=${column_id}`, await tokenReceiver.getAccessToken()),
        postColumnFilter: async (id: ProjectId, column_id: string, filters: ColumnFilter) =>
            post<ColumnFilter, void>(`${prefix}project/${machineType}/${id}/filter?id=${column_id}`, await tokenReceiver.getAccessToken(), filters),
    })
}

export interface EndpointLogin {
    getUser: () => Promise<User>;
}

const createEndpointLogin = (
    prefix: string,
    tokenReceiver: TokenReceiver
): EndpointLogin => ({
    getUser: async () =>
        get<User>(`${prefix}login`, await tokenReceiver.getAccessToken()),
});

export const createRestAPI = (prefix: string, authenticator: Authenticator) => ({
    machine: createEntityEndpoint<Machine, MachineId>(prefix, "machine", authenticator),
    customer: createEntityEndpoint<Customer, CustomerId>(prefix, "customer", authenticator),
    project: createEntityEndpoint<ProjectBase, ProjectId>(prefix, "project", authenticator),
    fabric_type: createEntityEndpoint<FabricType, FabricTypeId>(prefix, "fabric_type", authenticator),
    new_project: createEndpointNewProject(prefix, authenticator),
    kreg_project_details: createEndpointKRegProjectDetails(prefix, authenticator),
    kdrain_project_details: createEndpointKDrainProjectDetails(prefix, authenticator),
    user: createEntityEndpoint<User, UserId>(prefix, "user", authenticator),
    machine_admission: createEndpointMachineAdmission(prefix, authenticator),
    export: createEndpointKRegExport(prefix, authenticator),
    summary: createEndpointSummary(prefix, authenticator),
    application: createEndpointApplication(prefix, authenticator),
    kreg_column: createEndpointColumn<KRegColumnLogs>(prefix, authenticator, MachineType.KReg),
    kdrain_column: createEndpointColumn<KDrainColumnLogs>(prefix, authenticator, MachineType.KDrain),
    login: createEndpointLogin(prefix, authenticator)
});

export type API = ReturnType<typeof createRestAPI>;
