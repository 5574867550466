import React, {useEffect} from "react";
import {ProjectId} from "../../../backend/model";
import {Map} from "./map";
import {useDispatch, useSelector} from "react-redux";
import {kDrainProjectDetailsActions, kDrainProjectDetailsSelectors} from "../../../store/kDrainProjectDetailsSlice";
import {AppDispatch} from "../../../store";
import "./style.scss"
import {CompositeFilterDescriptor, filterBy} from "@progress/kendo-data-query";
import {DrainMapDetails} from "./drain-map-details";
import {useTranslation} from "react-i18next";
import {
  Card,
  CardBody,
  CardTitle,
  Splitter
} from "@progress/kendo-react-layout";
import {DrainHistoryGrid} from "../../../components";
import {SplitterPaneProps} from "@progress/kendo-react-layout/dist/npm/splitter/SplitterPane";
import {useHistory} from "react-router-dom";
import {Button} from "@progress/kendo-react-buttons";
import {selectShowMapObstacles} from "../../../store/applicationSlice";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const RTree = require("rtree");

interface MapProps {
  id: ProjectId;
  selectedColumnName: string;
  selectSingleColumnPage: (title: string) => void;
}

export const defaultStateValue = "project.details.selectState"
export const defaultDrillDiameterValue = "project.details.selectDiameter"

export const ProjectMapPage: React.FunctionComponent<MapProps> = ({id, selectSingleColumnPage, selectedColumnName}) => {

  const dispatch: AppDispatch = useDispatch();
  const {t} = useTranslation()
  const history = useHistory();

  const scale = useSelector(kDrainProjectDetailsSelectors.selectMapScale());
  const drainDiameter = useSelector(kDrainProjectDetailsSelectors.selectDrainDiameter())
  const map = useSelector(kDrainProjectDetailsSelectors.selectMapData);
  const x = useSelector(kDrainProjectDetailsSelectors.selectMapX());
  const y = useSelector(kDrainProjectDetailsSelectors.selectMapY());
  const updateRef = useSelector(kDrainProjectDetailsSelectors.selectColumnStatusRef());
  const showMapObstacles = useSelector(selectShowMapObstacles);

  const initPanes = [
    {
      resizable: true
    },
    {
      min: "32%",
      size: "45%",
    },
    {
      size: "38%",
      resizable: true,
    }
  ]

  const [panes, setPanes] = React.useState<SplitterPaneProps[]>(initPanes)
  const [filter, setFilter] = React.useState({
    state: t(`${defaultStateValue}`),
    column: ""
  })

  const doRefresh = React.useCallback(() => {
    dispatch(kDrainProjectDetailsActions.getUpdate(id, updateRef ? updateRef : undefined))
      .catch(() => {
      })
  }, [dispatch, id, updateRef])

  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(kDrainProjectDetailsActions.getUpdate(id, updateRef ? updateRef : undefined))
        .catch(() => {
        })
    }, 30000);
    return () => clearInterval(interval);
  }, [dispatch, id, updateRef]);

  const getFilters = React.useCallback(() => {
    const filters: CompositeFilterDescriptor = {logic: 'and', filters: []}
    filters.filters.push(...filters.filters,
      {
        value: filter.column ? filter.column : "",
        field: 'name',
        operator: 'startswith',
        ignoreCase: true
      })

    if (filter.state !== t(`${defaultStateValue}`)) {
      filters.filters.push(...filters.filters,
        {
          value: filter.state ? filter.state : "",
          field: 'status',
          operator: 'eq',
          ignoreCase: true
        })
    }
    return filters
  }, [filter.column, filter.state, t])


  const getFilteredColumns = React.useMemo(() => {
    return filterBy(map.columns, getFilters())
  }, [getFilters, map.columns])


  const getRTRee = React.useMemo(() => {
    const rtree = RTree(10)
    getFilteredColumns.forEach((c, i) => {
      rtree.insert(
        {
          x: c.location[0] - drainDiameter / 1000 / 2,
          y: c.location[1] - drainDiameter / 1000 / 2,
          w: drainDiameter / 1000,
          h: drainDiameter / 1000
        } as any, i as any)
    })
    return rtree

  }, [drainDiameter, getFilteredColumns])

  const selectColumn = (name: string) => {
    history.push(`/project/kdrain/details/${id}/map?c=${name}`);
  }

  const getColumnHistory = React.useCallback((name: string) => {
    doRefresh()
    dispatch(kDrainProjectDetailsActions.getDrainHistory(id, name))
  }, [dispatch, doRefresh, id])

  const selectedColumn = map.columns ? map.columns.find((e) => e.name === selectedColumnName) : undefined

  useEffect(() => {
    if (selectedColumn) {
      dispatch(kDrainProjectDetailsActions.setMapPos({x: selectedColumn.location[0], y: selectedColumn.location[1]}))
    }
  }, [dispatch, selectedColumn])

  const columnDetailsCard = (
    <Card className="kserv-drawing-map-details">
      <CardBody>
        <CardTitle className="kserv-card-title kserv-column-details-card-header">
          {selectedColumn ? <span/> : <span/>}
          {selectedColumn ? selectedColumn.name : t("project.columnDetails.noDrainSelected")}
          {selectedColumn ?
          <Button
              themeColor="primary"
              onClick={() => selectSingleColumnPage(selectedColumn ? selectedColumn.name.toString() : "")}
          >
              <FontAwesomeIcon icon={faInfo}/>
          </Button> : <span/>}
        </CardTitle>
            <DrainHistoryGrid id={id}
                              selectedColumn={selectedColumn?.name}
                              getDrainHistory={getColumnHistory}
            />
      </CardBody>
    </Card>
  )

  return (
    <Splitter panes={panes}
              orientation="horizontal"
              onChange={(e) => setPanes(e.newState)}
    >
      <DrainMapDetails projectId={id}
                       columns={map.columns}
                       selectedColumn={selectedColumn}
                       selectColumn={selectColumn}
                       setMapFilter={setFilter}
      />
      <Map x={x}
           y={y}
           bearing={0}
           scale={scale}
           drainDiameter={drainDiameter}
           mapDrawing={{...map, rtree: getRTRee, columns: getFilteredColumns}}
           selectedColumn={selectedColumn}
           selectColumn={selectColumn}
           onRefresh={doRefresh}
           showMapObstacles={showMapObstacles}
      />
      <div className="kserv-drawing-map-details-holder">
        {columnDetailsCard}
      </div>
    </Splitter>
  )
}

