import {UserAgentApplication, Configuration as MsalConfig, AuthResponse, AuthError} from "msal";

export interface TokenReceiver {
    getAccessToken: () => Promise<string>
}

export interface AuthReceiver {
    getUserName: () => string | undefined
}

export interface Authenticator extends TokenReceiver, AuthReceiver {
    login: () => Promise<void>;
    silentLogin: () => Promise<string | undefined>;
    logout: () => void;
}

const msalConfig : MsalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MSAL_CLIENT_ID as string,
        authority: process.env.REACT_APP_MSAL_AUTHORITY,
        redirectUri: process.env.REACT_APP_MSAL_REDIRECT,
        navigateToLoginRequestUrl: false,
    },
    cache: {
        cacheLocation: 'localStorage', // This configures where your cache will be stored
        storeAuthStateInCookie: true, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const authRequest = {
    scopes: [process.env.REACT_APP_AUTH_REQUEST as string],
};

export const createAADAuthenticator = (): Authenticator => {
    let userName: string | undefined;

    const msalApp = new UserAgentApplication(msalConfig);

    const handleAuthResponse = (response: AuthResponse | undefined) => {
        if (response) {
            if (response.account) {
                userName = response.account.userName;
            }
        }
    }

    msalApp.handleRedirectCallback((error: AuthError, response: AuthResponse | undefined) => handleAuthResponse(response));

    const getAccessToken = async () => {
        const response = await msalApp.acquireTokenSilent(authRequest).catch((error) => {
            console.warn('silent token acquisition fails. acquiring token using redirect');
            // fallback to interaction when silent call fails
            msalApp.acquireTokenRedirect(authRequest)
        });
        if (response) {
            return response.accessToken
        }
        return ""
    };

    const silentLogin = async () => {
        const response = await msalApp.acquireTokenSilent(authRequest).catch((error) => {
            // Ignore error
            return undefined
        });
        handleAuthResponse(response)
        return userName
    };

    const login = async () => {
         msalApp.loginRedirect(authRequest);
    };

    return ({
        login,
        silentLogin,
        logout: () => {msalApp.logout()},
        getAccessToken,
        getUserName: () => {
            return userName
        }
    })
};





