import React from "react";
import {useTranslation} from "react-i18next";
import {
  Grid,
  GridColumn as Column,
  GridPageChangeEvent,
  GridPagerSettings,
  GridToolbar
} from '@progress/kendo-react-grid';
import {CompositeFilterDescriptor, filterBy, orderBy, SortDescriptor} from "@progress/kendo-data-query";
import {DateCell, RefreshButton, ToggleButton} from "../../../components";
import {ColumnUpdate} from "../../../backend/column_log";
import {faFilter} from "@fortawesome/free-solid-svg-icons";

interface ColumnLogUpdateGridProps {
  data: ColumnUpdate[],
  doRefresh: () => void,
}

export const ColumnUpdateLogGrid: React.FunctionComponent<ColumnLogUpdateGridProps> = (props) => {
  const { t } = useTranslation()

  const initialSort: SortDescriptor[] = [{field: '', dir: 'desc'}];
  const initialFilter: CompositeFilterDescriptor = {logic: "and", filters: []};
  const pageAble: GridPagerSettings = {info: true, type: 'input', pageSizes: [10, 50, 100], previousNext: true};

  const [sort, setSort] = React.useState<SortDescriptor[]>(initialSort);
  const [filter, setFilter] = React.useState<CompositeFilterDescriptor>(initialFilter);
  const [pageState, setPageState] = React.useState({skip: 0, take: 10});
  const [showFilters, setShowFilters] = React.useState(false);

  const onPageChange = (e: GridPageChangeEvent) => {
    setPageState(e.page)
  }

  const gridData = filterBy(orderBy(props.data, sort), filter)
  const gridName = "kserv-log-updates-grid"

  return (
      <Grid
        className={gridName}
        data={gridData.slice(pageState.skip, pageState.skip + pageState.take)}
        sortable
        sort={sort}
        onSortChange={(e) => setSort(e.sort)}
        filterable={showFilters}
        filter={filter}
        resizable
        onFilterChange={(e) => setFilter(e.filter)}
        skip={pageState.skip}
        take={pageState.take}
        total={gridData.length}
        pageable={pageAble}
        onPageChange={onPageChange}>
        <GridToolbar>
          <div className="kserv-grid-toolbar">
            <ToggleButton value={showFilters} callback={setShowFilters} icon={faFilter}/>
            <RefreshButton  callback={props.doRefresh}/>
          </div>
        </GridToolbar>
        <Column field="time" title={t("project.details.timestamp")} filterable={false} cell={(p) => <DateCell {...p} time={p.dataItem.time}/>} width={"130px"}/>
        <Column field="old_column_name" title={t("project.details.oldColumnName")} filterable={true}/>
        <Column field="new_column_name" title={t("project.details.newColumnName")} filterable={true} />
      </Grid>
    )
}